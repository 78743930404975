import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import { InputAdornment } from '@material-ui/core';
import Icon from '../../../components/Icon';

const SearchTextField = (props) => {
    return (
        <div className="search_field">
            <TextField
                id="search"
                inputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon className="search" icon="search"/>
                        </InputAdornment>
                    ),
                }}
                name="search"
                placeholder="Search"
                value={props.value}
                onChange={props.onChange}/>
        </div>
    );
};

SearchTextField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.reports.filters.searchFilter.value,
    };
};

const actionsToProps = {};

export default connect(stateToProps, actionsToProps)(SearchTextField);
