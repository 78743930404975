import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import { ReactComponent as ReportsIcon } from '../../assets/leftNav/reports.svg';
import { ReactComponent as AssetsIcon } from '../../assets/leftNav/assets.svg';
import { ReactComponent as AgreementsIcon } from '../../assets/leftNav/agreements.svg';
import { ReactComponent as PartnersIcon } from '../../assets/leftNav/partners.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/leftNav/platforms.svg';
import { ReactComponent as RightsIcon } from '../../assets/leftNav/rights.svg';
// import { ReactComponent as ProjectsIcon } from '../../assets/leftNav/projects.svg';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { hideSnackbar } from '../../actions/snackbar';
import { connect } from 'react-redux';
import { setNavTab, sidebarDrawer } from '../../actions/navbar';
import './index.css';
import logo from '../../assets/logo.svg';
import miniLogo from '../../assets/mini-logo.svg';
import { withRouter } from 'react-router';
import { ReactComponent as MenuClose } from '../../assets/menu-close.svg';
import { ReactComponent as MenuOpen } from '../../assets/menu-open.svg';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import {
    fetchAllAgreements,
    fetchAllAssets,
    fetchAllPartners,
    fetchAllPlatforms,
    fetchAllProjects,
    fetchAllRights,
    setTabValue,
} from '../../actions/allTabs';
import {
    fetchAllAgreementsStats,
    fetchAllAssetsStats,
    fetchAllPartnersStats,
    fetchAllPlatformsStats,
    fetchAllProjectsStats,
    fetchAllRightsStats,
} from '../../actions/allTabs/stats';

class LeftNav extends React.Component {
    constructor (props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.handleStats = this.handleStats.bind(this);
    }

    componentDidMount () {
        const path = this.props.location.pathname && this.props.location.pathname.split('/')[1];
        this.props.setTabValue(path);
        if ((path === 'assets') && this.props.allAssets && !this.props.allAssets.length &&
            !this.props.allAssetsInProgress) {
            this.props.fetchAllAssets(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }
        if ((path === 'agreements') && this.props.allAgreements && !this.props.allAgreements.length &&
            !this.props.allAgreementsInProgress) {
            this.props.fetchAllAgreements(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }
        if ((path === 'partners') && this.props.allPartners && !this.props.allPartners.length &&
            !this.props.allPartnersInProgress) {
            this.props.fetchAllPartners(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }
        if ((path === 'platforms') && this.props.allPlatforms && !this.props.allPlatforms.length &&
            !this.props.allPlatformsInProgress) {
            this.props.fetchAllPlatforms(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }
        // if ((path === 'projects') && this.props.allProjects && !this.props.allProjects.length &&
        //     !this.props.allProjectsInProgress) {
        //     this.props.fetchAllProjects(DEFAULT_SKIP, 10, 'created_at', 'desc');
        // }
        if ((path === 'rights') && this.props.allRights && !this.props.allRights.length &&
            !this.props.allRightsInProgress) {
            this.props.fetchAllRights(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }

        // Stats apis
        if (path === 'assets' && this.props.statsAssets && (Object.keys(this.props.statsAssets).length === 0) &&
            !this.props.statsAssetsInProgress) {
            this.props.fetchAllAssetsStats();
        }
        if (path === 'agreements' && this.props.statsAgreements && (Object.keys(this.props.statsAgreements).length === 0) &&
            !this.props.statsAgreementsInProgress) {
            this.props.fetchAllAgreementsStats();
        }
        if (path === 'partners' && this.props.statsPartners && (Object.keys(this.props.statsPartners).length === 0) &&
            !this.props.statsPartnersInProgress) {
            this.props.fetchAllPartnersStats();
        }
        if (path === 'platforms' && this.props.statsPlatforms && (Object.keys(this.props.statsPlatforms).length === 0) &&
            !this.props.statsPlatformsInProgress) {
            this.props.fetchAllPlatformsStats();
        }
        if (path === 'rights' && this.props.statsRights && (Object.keys(this.props.statsRights).length === 0) &&
            !this.props.statsRightsInProgress) {
            this.props.fetchAllRightsStats();
        }
        // if (path === 'projects' && this.props.statsProjects && (Object.keys(this.props.statsProjects).length === 0) &&
        //     !this.props.statsProjectsInProgress) {
        //     this.props.fetchAllProjectsStats();
        // }
    }

    componentDidUpdate (pp, ps, ss) {
        const path = this.props.location.pathname && this.props.location.pathname.split('/')[1];
        if ((path === 'assets') && this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchAllAssets(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }
        if ((path === 'agreements') && this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchAllAgreements(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }
        if ((path === 'partners') && this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchAllPartners(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }
        if ((path === 'platforms') && this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchAllPlatforms(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }
        // if ((path === 'projects') && this.props.auth && (pp.auth !== this.props.auth)) {
        //     setTimeout(() => {
        //         this.props.fetchAllProjects(DEFAULT_SKIP, 10, 'created_at', 'desc');
        //     }, 400);
        // }
        if ((path === 'rights') && this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchAllRights(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }

        // Stats apis
        if (path === 'assets' && this.props.statsAssets && (Object.keys(this.props.statsAssets).length === 0) &&
            !this.props.statsAssetsInProgress) {
            this.props.fetchAllAssetsStats();
        }
        if (path === 'agreements' && this.props.statsAgreements && (Object.keys(this.props.statsAgreements).length === 0) &&
            !this.props.statsAgreementsInProgress) {
            this.props.fetchAllAgreementsStats();
        }
        if (path === 'partners' && this.props.statsPartners && (Object.keys(this.props.statsPartners).length === 0) &&
            !this.props.statsPartnersInProgress) {
            this.props.fetchAllPartnersStats();
        }
        if (path === 'platforms' && this.props.statsPlatforms && (Object.keys(this.props.statsPlatforms).length === 0) &&
            !this.props.statsPlatformsInProgress) {
            this.props.fetchAllPlatformsStats();
        }
        if (path === 'rights' && this.props.statsRights && (Object.keys(this.props.statsRights).length === 0) &&
            !this.props.statsRightsInProgress) {
            this.props.fetchAllRightsStats();
        }
        // if (path === 'projects' && this.props.statsProjects && (Object.keys(this.props.statsProjects).length === 0) &&
        //     !this.props.statsProjectsInProgress) {
        //     this.props.fetchAllProjectsStats();
        // }
    }

    handleStats (value) {
        if (value === 'assets' && this.props.statsAssets && (Object.keys(this.props.statsAssets).length === 0) &&
            !this.props.statsAssetsInProgress) {
            this.props.fetchAllAssetsStats();
        }
        if (value === 'agreements' && this.props.statsAgreements && (Object.keys(this.props.statsAgreements).length === 0) &&
            !this.props.statsAgreementsInProgress) {
            this.props.fetchAllAgreementsStats();
        }
        if (value === 'partners' && this.props.statsPartners && (Object.keys(this.props.statsPartners).length === 0) &&
            !this.props.statsPartnersInProgress) {
            this.props.fetchAllPartnersStats();
        }
        if (value === 'platforms' && this.props.statsPlatforms && (Object.keys(this.props.statsPlatforms).length === 0) &&
            !this.props.statsPlatformsInProgress) {
            this.props.fetchAllPlatformsStats();
        }
        if (value === 'rights' && this.props.statsRights && (Object.keys(this.props.statsRights).length === 0) &&
            !this.props.statsRightsInProgress) {
            this.props.fetchAllRightsStats();
        }
        // if (value === 'projects' && this.props.statsProjects && (Object.keys(this.props.statsProjects).length === 0) &&
        //     !this.props.statsProjectsInProgress) {
        //     this.props.fetchAllProjectsStats();
        // }
    }

    onClick (value) {
        this.props.setTabValue(value);
        this.props.history.push('/' + value);

        if (value === 'reports') {
            return;
        }

        this.handleStats(value);

        const agreementsObj = { ...this.props.agreementsFilter };
        let agreements = new Set();
        if (agreementsObj[value]) {
            agreements = new Set(agreementsObj[value]);
        }

        const assetsObj = { ...this.props.assetsFilter };
        let assets = new Set();
        if (assetsObj[value]) {
            assets = new Set(assetsObj[value]);
        }

        const partnersObj = { ...this.props.partnersFilter };
        let partners = new Set();
        if (partnersObj[value]) {
            partners = new Set(partnersObj[value]);
        }

        const rightsObj = { ...this.props.rightsFilter };
        let rights = new Set();
        if (rightsObj[value]) {
            rights = new Set(rightsObj[value]);
        }

        const qcStatusObj = { ...this.props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[value]) {
            qcStatus = new Set(qcStatusObj[value]);
        }

        const priorityObj = { ...this.props.priorityFilter };
        let priority = new Set();
        if (priorityObj[value]) {
            priority = new Set(priorityObj[value]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[value];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[value];

        if (value === 'agreements' && this.props.allAgreements && !this.props.allAgreements.length &&
            !this.props.allAgreementsInProgress) {
            if ((assets && assets.size) || (partners && partners.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.agreementsSearchKey !== '') ||
                (startDate && startDate !== '') || (endDate && endDate !== '')) {
                return;
            }

            this.props.fetchAllAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder);
        }
        if (value === 'partners' && this.props.allPartners && !this.props.allPartners.length &&
            !this.props.allPartnersInProgress) {
            if ((assets && assets.size) || (agreements && agreements.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.partnersSearchKey !== '')) {
                return;
            }

            this.props.fetchAllPartners(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.partnersSortBy,
                this.props.partnersOrder);
        }
        if (value === 'platforms' && this.props.allPlatforms && !this.props.allPlatforms.length &&
            !this.props.allPlatformsInProgress) {
            if ((qcStatus && qcStatus.size) || (this.props.platformsSearchKey !== '')) {
                return;
            }
            this.props.fetchAllPlatforms(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.platformsSortBy,
                this.props.platformsOrder, this.props.platformsSearchKey);
        }
        if (value === 'projects' && this.props.allProjects && !this.props.allProjects.length &&
            !this.props.allProjectsInProgress) {
            if ((qcStatus && qcStatus.size) || (priority && priority.size) ||
                (this.props.projectsSearchKey !== '')) {
                return;
            }
            this.props.fetchAllProjects(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.projectsSortBy,
                this.props.projectsOrder, this.props.projectsSearchKey);
        }
        if (value === 'assets' && this.props.allAssets && !this.props.allAssets.length &&
            !this.props.allAssetsInProgress) {
            if ((agreements && agreements.size) || (partners && partners.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.assetsSearchKey !== '')) {
                return;
            }

            this.props.fetchAllAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy, this.props.assetsOrder);
        }
        if (value === 'rights' && this.props.allRights && !this.props.allRights.length &&
            !this.props.allRightsInProgress) {
            if ((assets && assets.size) || (agreements && agreements.size) || (partners && partners.size) ||
                (qcStatus && qcStatus.size) || (this.props.rightsSearchKey !== '')) {
                return;
            }
            this.props.fetchAllRights(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.rightsSortBy, this.props.rightsOrder);
        }
    }

    render () {
        const path = this.props.location.pathname && this.props.location.pathname.split('/')[1];

        return (
            <div className={this.props.drawer ? 'left_nav_show left_nav' : 'left_nav_hide left_nav'}>
                <div className="nav_logo">
                    {this.props.drawer
                        ? <img alt="logo" src={logo}/>
                        : <img alt="logo" src={miniLogo}/>}
                    <img alt="logo" className="responsive_logo" src={miniLogo}/>
                    {this.props.drawer
                        ? <IconButton onClick={this.props.sidebarDrawer}>
                            <MenuClose/>
                        </IconButton>
                        : <IconButton onClick={this.props.sidebarDrawer}>
                            <MenuOpen/>
                        </IconButton>}
                </div>
                <div className={this.props.drawer ? 'drawer_show items' : 'drawer_closed items'}>
                    <Button
                        className={(path === 'reports')
                            ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('reports')}>
                        <ReportsIcon/>
                        <p>{variables[this.props.lang].reports}</p>
                    </Button>
                    <Button
                        className={(path === 'assets') ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('assets')}>
                        <AssetsIcon/>
                        <p>{variables[this.props.lang].assets}</p>
                    </Button>
                    <Button
                        className={(path === 'agreements') ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('agreements')}>
                        <AgreementsIcon/>
                        <p>{variables[this.props.lang].agreements}</p>
                    </Button>
                    <Button
                        className={(path === 'partners') ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('partners')}>
                        <PartnersIcon/>
                        <p>{variables[this.props.lang].partners}</p>
                    </Button>

                    <Button
                        className={(path === 'platforms') ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('platforms')}>
                        <PlatformsIcon/>
                        <p>{variables[this.props.lang].platforms}</p>
                    </Button>

                    <Button
                        className={(path === 'rights') ? 'active_nav' : 'default_nav'}
                        onClick={() => this.onClick('rights')}>
                        <RightsIcon/>
                        <p>{variables[this.props.lang].rights}</p>
                    </Button>
                    {/* <Button */}
                    {/*    className={(path === 'projects') ? 'active_nav' : 'default_nav'} */}
                    {/*    onClick={() => this.onClick('projects')}> */}
                    {/*    <ProjectsIcon/> */}
                    {/*    <p>{variables[this.props.lang].projects}</p> */}
                    {/* </Button> */}
                </div>
            </div>
        );
    }
}

LeftNav.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,

    allAgreements: PropTypes.array.isRequired,
    allAgreementsInProgress: PropTypes.bool.isRequired,
    allAssets: PropTypes.array.isRequired,
    allAssetsInProgress: PropTypes.bool.isRequired,
    allPartners: PropTypes.array.isRequired,
    allPartnersInProgress: PropTypes.bool.isRequired,
    allPlatforms: PropTypes.array.isRequired,
    allPlatformsInProgress: PropTypes.bool.isRequired,
    allProjects: PropTypes.array.isRequired,
    allProjectsInProgress: PropTypes.bool.isRequired,
    allRights: PropTypes.array.isRequired,
    allRightsInProgress: PropTypes.bool.isRequired,

    assetsFilter: PropTypes.object.isRequired,
    drawer: PropTypes.bool.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchAllAgreements: PropTypes.func.isRequired,
    fetchAllAgreementsStats: PropTypes.func.isRequired,
    fetchAllAssets: PropTypes.func.isRequired,
    fetchAllAssetsStats: PropTypes.func.isRequired,
    fetchAllPartners: PropTypes.func.isRequired,
    fetchAllPartnersStats: PropTypes.func.isRequired,
    fetchAllPlatforms: PropTypes.func.isRequired,
    fetchAllPlatformsStats: PropTypes.func.isRequired,
    fetchAllProjects: PropTypes.func.isRequired,
    fetchAllProjectsStats: PropTypes.func.isRequired,
    fetchAllRights: PropTypes.func.isRequired,
    fetchAllRightsStats: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    navTab: PropTypes.string.isRequired,

    partnersFilter: PropTypes.object.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,

    rightsFilter: PropTypes.object.isRequired,
    setNavTab: PropTypes.func.isRequired,
    setTabValue: PropTypes.func.isRequired,
    sidebarDrawer: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    tabValue: PropTypes.string.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    auth: PropTypes.string,
    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    platformsOrder: PropTypes.string,
    platformsSearchKey: PropTypes.string,
    platformsSortBy: PropTypes.string,
    platformsTotal: PropTypes.number,

    projectsOrder: PropTypes.string,
    projectsSearchKey: PropTypes.string,
    projectsSortBy: PropTypes.string,
    projectsTotal: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,

    statsAgreements: PropTypes.object,
    statsAgreementsInProgress: PropTypes.bool,
    statsAssets: PropTypes.object,
    statsAssetsInProgress: PropTypes.bool,
    statsPartners: PropTypes.object,
    statsPartnersInProgress: PropTypes.bool,
    statsPlatforms: PropTypes.object,
    statsPlatformsInProgress: PropTypes.bool,
    statsProjects: PropTypes.object,
    statsProjectsInProgress: PropTypes.bool,
    statsRights: PropTypes.object,
    statsRightsInProgress: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        navTab: state.navbar.navTabValue.value,
        drawer: state.navbar.drawer,

        auth: state.accessToken.value,
        tabValue: state.allTabs.tabValue.value,

        assetsFilter: state.allTabs.assetsFilter.value,
        partnersFilter: state.allTabs.partnersFilter.value,
        agreementsFilter: state.allTabs.agreementsFilter.value,
        platformsFilter: state.allTabs.platformsFilter.value,
        rightsFilter: state.allTabs.rightsFilter.value,
        projectsFilter: state.allTabs.projectsFilter.value,
        qcStatusFilter: state.allTabs.qcStatusFilter.value,
        priorityFilter: state.allTabs.priorityFilter.value,
        startDateFilter: state.allTabs.startDateFilter.value,
        endDateFilter: state.allTabs.endDateFilter.value,

        allAgreements: state.allTabs.allAgreements.result,
        allAgreementsInProgress: state.allTabs.allAgreements.inProgress,
        allPlatforms: state.allTabs.allPlatforms.result,
        allPlatformsInProgress: state.allTabs.allPlatforms.inProgress,
        allPartners: state.allTabs.allPartners.result,
        allPartnersInProgress: state.allTabs.allPartners.inProgress,
        allProjects: state.allTabs.allProjects.result,
        allProjectsInProgress: state.allTabs.allProjects.inProgress,
        allAssets: state.allTabs.allAssets.result,
        allAssetsInProgress: state.allTabs.allAssets.inProgress,
        allRights: state.allTabs.allRights.result,
        allRightsInProgress: state.allTabs.allRights.inProgress,

        agreementsOrder: state.allTabs.allAgreements.order,
        agreementsSearchKey: state.allTabs.allAgreements.searchKey,
        agreementsSortBy: state.allTabs.allAgreements.sortBy,
        agreementsTotal: state.allTabs.allAgreements.total,

        assetsOrder: state.allTabs.allAssets.order,
        assetsSearchKey: state.allTabs.allAssets.searchKey,
        assetsSortBy: state.allTabs.allAssets.sortBy,
        assetsTotal: state.allTabs.allAssets.total,

        partnersOrder: state.allTabs.allPartners.order,
        partnersSearchKey: state.allTabs.allPartners.searchKey,
        partnersSortBy: state.allTabs.allPartners.sortBy,
        partnersTotal: state.allTabs.allPartners.total,

        rightsOrder: state.allTabs.allRights.order,
        rightsSearchKey: state.allTabs.allRights.searchKey,
        rightsSortBy: state.allTabs.allRights.sortBy,
        rightsTotal: state.allTabs.allRights.total,

        platformsOrder: state.allTabs.allPlatforms.order,
        platformsSearchKey: state.allTabs.allPlatforms.searchKey,
        platformsSortBy: state.allTabs.allPlatforms.sortBy,
        platformsTotal: state.allTabs.allPlatforms.total,

        projectsOrder: state.allTabs.allProjects.order,
        projectsSearchKey: state.allTabs.allProjects.searchKey,
        projectsSortBy: state.allTabs.allProjects.sortBy,
        projectsTotal: state.allTabs.allProjects.total,

        statsAssets: state.allTabs.stats.allAssetsStats.value,
        statsAssetsInProgress: state.allTabs.stats.allAssetsStats.inProgress,
        statsAgreements: state.allTabs.stats.allAgreementsStats.value,
        statsAgreementsInProgress: state.allTabs.stats.allAgreementsStats.inProgress,
        statsPartners: state.allTabs.stats.allPartnersStats.value,
        statsPartnersInProgress: state.allTabs.stats.allPartnersStats.inProgress,
        statsPlatforms: state.allTabs.stats.allPlatformsStats.value,
        statsPlatformsInProgress: state.allTabs.stats.allPlatformsStats.inProgress,
        statsRights: state.allTabs.stats.allRightsStats.value,
        statsRightsInProgress: state.allTabs.stats.allRightsStats.inProgress,
        statsProjects: state.allTabs.stats.allProjectsStats.value,
        statsProjectsInProgress: state.allTabs.stats.allProjectsStats.inProgress,
    };
};

const actionsToProps = {
    onClose: hideSnackbar,
    setNavTab,
    sidebarDrawer,

    setTabValue,
    fetchAllAgreements,
    fetchAllPartners,
    fetchAllPlatforms,
    fetchAllProjects,
    fetchAllAssets,
    fetchAllRights,

    fetchAllAssetsStats,
    fetchAllAgreementsStats,
    fetchAllPartnersStats,
    fetchAllPlatformsStats,
    fetchAllRightsStats,
    fetchAllProjectsStats,
};

export default withRouter(connect(stateToProps, actionsToProps)(LeftNav));
