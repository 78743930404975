import React from 'react';
import { Button } from '@material-ui/core';
import { ReactComponent as AssetsIcon } from '../../assets/reports/assets.svg';
import { ReactComponent as PartnersIcon } from '../../assets/reports/partners.svg';
import { ReactComponent as AgreementsIcon } from '../../assets/reports/agreements.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/reports/platforms.svg';
// import { ReactComponent as ProjectsIcon } from '../../assets/reports/projects.svg';
import { ReactComponent as RightsIcon } from '../../assets/reports/rights.svg';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportPlatforms,
    fetchReportProjects,
    fetchReportRights,
    setTabValue,
} from '../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import {
    fetchAgreementsReportStats,
    fetchAssetsReportStats,
    fetchPartnersReportStats,
    fetchPlatformsReportStats,
    fetchProjectsReportStats,
    fetchRightsReportStats,
} from '../../actions/reports/stats';

class Tabs extends React.Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleStats = this.handleStats.bind(this);
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchReportAssets(DEFAULT_SKIP, 10, 'created_at', 'desc');
            }, 400);
        }
        // if (this.props.auth && (pp.auth !== this.props.auth)) {
        //     this.props.fetchAssetsReportStats();
        // }
    }

    componentDidMount () {
        if (this.props.reportAssets && !this.props.reportAssets.length &&
            !this.props.reportAssetsInProgress) {
            this.props.fetchReportAssets(DEFAULT_SKIP, 10, 'created_at', 'desc');
        }
        // if (this.props.statsAssets && (Object.keys(this.props.statsAssets).length === 0) &&
        //     !this.props.statsAssetsInProgress) {
        //     this.props.fetchAssetsReportStats();
        // }
    }

    handleStats (value) {
        if (value === 'assets' && this.props.statsAssets && (Object.keys(this.props.statsAssets).length === 0) &&
            !this.props.statsAssetsInProgress) {
            this.props.fetchAssetsReportStats();
        }
        if (value === 'agreements' && this.props.statsAgreements && (Object.keys(this.props.statsAgreements).length === 0) &&
            !this.props.statsAgreementsInProgress) {
            this.props.fetchAgreementsReportStats();
        }
        if (value === 'partners' && this.props.statsPartners && (Object.keys(this.props.statsPartners).length === 0) &&
            !this.props.statsPartnersInProgress) {
            this.props.fetchPartnersReportStats();
        }
        if (value === 'platforms' && this.props.statsPlatforms && (Object.keys(this.props.statsPlatforms).length === 0) &&
            !this.props.statsPlatformsInProgress) {
            this.props.fetchPlatformsReportStats();
        }
        if (value === 'rights' && this.props.statsRights && (Object.keys(this.props.statsRights).length === 0) &&
            !this.props.statsRightsInProgress) {
            this.props.fetchRightsReportStats();
        }
        if (value === 'projects' && this.props.statsProjects && (Object.keys(this.props.statsProjects).length === 0) &&
            !this.props.statsProjectsInProgress) {
            this.props.fetchProjectsReportStats();
        }
    }

    handleClick (value) {
        this.props.setTabValue(value);
        // this.handleStats(value);

        const agreementsObj = { ...this.props.agreementsFilter };
        let agreements = new Set();
        if (agreementsObj[value]) {
            agreements = new Set(agreementsObj[value]);
        }

        const assetsObj = { ...this.props.assetsFilter };
        let assets = new Set();
        if (assetsObj[value]) {
            assets = new Set(assetsObj[value]);
        }

        const partnersObj = { ...this.props.partnersFilter };
        let partners = new Set();
        if (partnersObj[value]) {
            partners = new Set(partnersObj[value]);
        }

        const rightsObj = { ...this.props.rightsFilter };
        let rights = new Set();
        if (rightsObj[value]) {
            rights = new Set(rightsObj[value]);
        }

        const qcStatusObj = { ...this.props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[value]) {
            qcStatus = new Set(qcStatusObj[value]);
        }

        const priorityObj = { ...this.props.priorityFilter };
        let priority = new Set();
        if (priorityObj[value]) {
            priority = new Set(priorityObj[value]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[value];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[value];

        if (value === 'agreements' && this.props.reportAgreements && !this.props.reportAgreements.length &&
            !this.props.reportAgreementsInProgress) {
            if ((assets && assets.size) || (partners && partners.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.agreementsSearchKey !== '') ||
                (startDate && startDate !== '') || (endDate && endDate !== '') || (this.props.statusFilter !== '')) {
                return;
            }

            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder);
        }
        if (value === 'partners' && this.props.reportPartners && !this.props.reportPartners.length &&
            !this.props.reportPartnersInProgress) {
            if ((assets && assets.size) || (agreements && agreements.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.partnersSearchKey !== '')) {
                return;
            }

            this.props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.partnersSortBy,
                this.props.partnersOrder);
        }
        if (value === 'platforms' && this.props.reportPlatforms && !this.props.reportPlatforms.length &&
            !this.props.reportPlatformsInProgress) {
            if ((qcStatus && qcStatus.size) || (this.props.platformsSearchKey !== '')) {
                return;
            }

            this.props.fetchReportPlatforms(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.platformsSortBy,
                this.props.platformsOrder, this.props.platformsSearchKey);
        }
        if (value === 'projects' && this.props.reportProjects && !this.props.reportProjects.length &&
            !this.props.reportProjectsInProgress) {
            if ((qcStatus && qcStatus.size) || (priority && priority.size) ||
                (this.props.projectsSearchKey !== '')) {
                return;
            }

            this.props.fetchReportProjects(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.projectsSortBy,
                this.props.projectsOrder, this.props.projectsSearchKey);
        }
        if (value === 'assets' && this.props.reportAssets && !this.props.reportAssets.length &&
            !this.props.reportAssetsInProgress) {
            if ((agreements && agreements.size) || (partners && partners.size) || (rights && rights.size) ||
                (qcStatus && qcStatus.size) || (this.props.assetsSearchKey !== '')) {
                return;
            }

            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy, this.props.assetsOrder);
        }
        if (value === 'rights' && this.props.reportRights && !this.props.reportRights.length &&
            !this.props.reportRightsInProgress) {
            if ((assets && assets.size) || (agreements && agreements.size) || (partners && partners.size) ||
                (qcStatus && qcStatus.size) || (this.props.rightsSearchKey !== '')) {
                return;
            }

            this.props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.rightsSortBy, this.props.rightsOrder);
        }
    }

    render () {
        return (
            <div className="tabs">
                <Button
                    className={this.props.tabValue === 'assets' ? 'active_tab' : ''}
                    onClick={() => this.handleClick('assets')}>
                    <AssetsIcon/>
                    {variables[this.props.lang].assets}
                </Button>
                <Button
                    className={this.props.tabValue === 'partners' ? 'active_tab' : ''}
                    onClick={() => this.handleClick('partners')}>
                    <PartnersIcon/>
                    {variables[this.props.lang].partners}
                </Button>
                <Button
                    className={this.props.tabValue === 'agreements' ? 'active_tab' : ''}
                    onClick={() => this.handleClick('agreements')}>
                    <AgreementsIcon/>
                    {variables[this.props.lang].agreements}
                </Button>
                <Button
                    className={this.props.tabValue === 'platforms' ? 'active_tab' : ''}
                    onClick={() => this.handleClick('platforms')}>
                    <PlatformsIcon/>
                    {variables[this.props.lang].platforms}
                </Button>
                <Button
                    className={this.props.tabValue === 'rights' ? 'active_tab' : ''}
                    onClick={() => this.handleClick('rights')}>
                    <RightsIcon/>
                    {variables[this.props.lang].rights}
                </Button>
                {/* <Button */}
                {/*    className={this.props.tabValue === 'projects' ? 'active_tab' : ''} */}
                {/*    onClick={() => this.handleClick('projects')}> */}
                {/*    <ProjectsIcon/> */}
                {/*    {variables[this.props.lang].projects} */}
                {/* </Button> */}
            </div>
        );
    }
}

Tabs.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchAgreementsReportStats: PropTypes.func.isRequired,
    fetchAssetsReportStats: PropTypes.func.isRequired,
    fetchPartnersReportStats: PropTypes.func.isRequired,
    fetchPlatformsReportStats: PropTypes.func.isRequired,
    fetchProjectsReportStats: PropTypes.func.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartners: PropTypes.func.isRequired,
    fetchReportPlatforms: PropTypes.func.isRequired,
    fetchReportProjects: PropTypes.func.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    fetchRightsReportStats: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    reportAgreements: PropTypes.array.isRequired,
    reportAgreementsInProgress: PropTypes.bool.isRequired,
    reportAssets: PropTypes.array.isRequired,
    reportAssetsInProgress: PropTypes.bool.isRequired,
    reportPartners: PropTypes.array.isRequired,
    reportPartnersInProgress: PropTypes.bool.isRequired,
    reportPlatforms: PropTypes.array.isRequired,
    reportPlatformsInProgress: PropTypes.bool.isRequired,
    reportProjects: PropTypes.array.isRequired,
    reportProjectsInProgress: PropTypes.bool.isRequired,
    reportRights: PropTypes.array.isRequired,
    reportRightsInProgress: PropTypes.bool.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    setTabValue: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    auth: PropTypes.string,
    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    platformsOrder: PropTypes.string,
    platformsSearchKey: PropTypes.string,
    platformsSortBy: PropTypes.string,
    platformsTotal: PropTypes.number,

    projectsOrder: PropTypes.string,
    projectsSearchKey: PropTypes.string,
    projectsSortBy: PropTypes.string,
    projectsTotal: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,

    statsAgreements: PropTypes.object,
    statsAgreementsInProgress: PropTypes.bool,
    statsAssets: PropTypes.object,
    statsAssetsInProgress: PropTypes.bool,
    statsPartners: PropTypes.object,
    statsPartnersInProgress: PropTypes.bool,
    statsPlatforms: PropTypes.object,
    statsPlatformsInProgress: PropTypes.bool,
    statsProjects: PropTypes.object,
    statsProjectsInProgress: PropTypes.bool,
    statsRights: PropTypes.object,
    statsRightsInProgress: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        tabValue: state.reports.tabValue.value,

        assetsFilter: state.reports.assetsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        agreementsFilter: state.reports.agreementsFilter.value,
        platformsFilter: state.reports.platformsFilter.value,
        rightsFilter: state.reports.rightsFilter.value,
        projectsFilter: state.reports.projectsFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
        priorityFilter: state.reports.priorityFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        statusFilter: state.reports.statusFilter.value,

        reportAgreements: state.reports.reportAgreements.result,
        reportAgreementsInProgress: state.reports.reportAgreements.inProgress,
        reportPlatforms: state.reports.reportPlatforms.result,
        reportPlatformsInProgress: state.reports.reportPlatforms.inProgress,
        reportPartners: state.reports.reportPartners.result,
        reportPartnersInProgress: state.reports.reportPartners.inProgress,
        reportProjects: state.reports.reportProjects.result,
        reportProjectsInProgress: state.reports.reportProjects.inProgress,
        reportAssets: state.reports.reportAssets.result,
        reportAssetsInProgress: state.reports.reportAssets.inProgress,
        reportRights: state.reports.reportRights.result,
        reportRightsInProgress: state.reports.reportRights.inProgress,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        partnersOrder: state.reports.reportPartners.order,
        partnersSearchKey: state.reports.reportPartners.searchKey,
        partnersSortBy: state.reports.reportPartners.sortBy,
        partnersTotal: state.reports.reportPartners.total,

        rightsOrder: state.reports.reportRights.order,
        rightsSearchKey: state.reports.reportRights.searchKey,
        rightsSortBy: state.reports.reportRights.sortBy,
        rightsTotal: state.reports.reportRights.total,

        platformsOrder: state.reports.reportPlatforms.order,
        platformsSearchKey: state.reports.reportPlatforms.searchKey,
        platformsSortBy: state.reports.reportPlatforms.sortBy,
        platformsTotal: state.reports.reportPlatforms.total,

        projectsOrder: state.reports.reportProjects.order,
        projectsSearchKey: state.reports.reportProjects.searchKey,
        projectsSortBy: state.reports.reportProjects.sortBy,
        projectsTotal: state.reports.reportProjects.total,

        statsAssets: state.reports.stats.assetsReportStats.value,
        statsAssetsInProgress: state.reports.stats.assetsReportStats.inProgress,
        statsAgreements: state.reports.stats.agreementsReportStats.value,
        statsAgreementsInProgress: state.reports.stats.agreementsReportStats.inProgress,
        statsPartners: state.reports.stats.partnersReportStats.value,
        statsPartnersInProgress: state.reports.stats.partnersReportStats.inProgress,
        statsPlatforms: state.reports.stats.platformsReportStats.value,
        statsPlatformsInProgress: state.reports.stats.platformsReportStats.inProgress,
        statsRights: state.reports.stats.rightsReportStats.value,
        statsRightsInProgress: state.reports.stats.rightsReportStats.inProgress,
        statsProjects: state.reports.stats.projectsReportStats.value,
        statsProjectsInProgress: state.reports.stats.projectsReportStats.inProgress,
    };
};

const actionsToProps = {
    setTabValue,
    fetchReportAgreements,
    fetchReportPartners,
    fetchReportPlatforms,
    fetchReportProjects,
    fetchReportAssets,
    fetchReportRights,

    fetchAssetsReportStats,
    fetchAgreementsReportStats,
    fetchPartnersReportStats,
    fetchPlatformsReportStats,
    fetchRightsReportStats,
    fetchProjectsReportStats,
};

export default connect(stateToProps, actionsToProps)(Tabs);
