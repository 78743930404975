import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import Section from './Section';
import variables from '../../../../../utils/variables';

const Rights = (props) => {
    const rightsIn = props.partnerDetails && props.partnerDetails.rights_in;
    const rightsInCount = props.partnerDetails && props.partnerDetails.rights_in &&
        props.partnerDetails.rights_in.length;
    const rightsOut = props.partnerDetails && props.partnerDetails.rights_out;
    const rightsOutCount = props.partnerDetails && props.partnerDetails.rights_out &&
        props.partnerDetails.rights_out.length;
    const remainingRights = props.partnerDetails && props.partnerDetails.rights_remaining;
    const remainingRightsCount = props.partnerDetails && props.partnerDetails.rights_remaining &&
        props.partnerDetails.rights_remaining.length;
    const futureRights = props.partnerDetails && props.partnerDetails.rights_not_bought;
    const futureRightsCount = props.partnerDetails && props.partnerDetails.rights_not_bought &&
        props.partnerDetails.rights_not_bought.length;

    return (
        <div className="assets_right">
            <Section
                count={rightsInCount}
                data={rightsIn}
                header={variables[props.lang].rights_in}/>
            <Section
                count={rightsOutCount}
                data={rightsOut}
                header={variables[props.lang].rights_out}/>
            <Section
                count={remainingRightsCount}
                data={remainingRights}
                header={variables[props.lang].remaining_rights}/>
            <Section
                count={futureRightsCount}
                data={futureRights}
                header={variables[props.lang].future_rights}/>
        </div>
    );
};

Rights.propTypes = {
    lang: PropTypes.string.isRequired,
    partnerDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        partnerDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Rights);
