import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import variables from '../../../../utils/variables';
import AgreementInfo from './AgreementInfo';
import Files from './Files';
import Assets from './Assets';
import Partners from './Partners';
import Rights from './Rights';
import Notes from './Notes';

const Tabs = (props) => {
    const [value, setValue] = useState('agreement_info');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const assets = props.agreementDetails && props.agreementDetails.assets &&
        props.agreementDetails.assets.length;
    const partners = props.agreementDetails && props.agreementDetails.partners &&
        props.agreementDetails.partners.length;
    const rights = props.agreementDetails && props.agreementDetails.rights &&
        props.agreementDetails.rights.length;
    const files = props.agreementDetails && props.agreementDetails.agreement &&
        props.agreementDetails.agreement.files && props.agreementDetails.agreement.files.length;

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext className="tab_context" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="tab_list" onChange={handleChange}>
                        <Tab
                            className={value === 'agreement_info' ? 'active_list' : ''}
                            label={variables[props.lang]['agreement_info']}
                            value="agreement_info" />
                        <Tab
                            className={value === 'assets' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].assets}
                                {assets ? ' (' + assets + ')' : null}
                            </>}
                            value="assets" />
                        <Tab
                            className={value === 'partners' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].partners}
                                {partners ? ' (' + partners + ')' : null}
                            </>}
                            value="partners" />
                        <Tab
                            className={value === 'rights' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].rights}
                                {rights ? ' (' + rights + ')' : null}
                            </>}
                            value="rights" />
                        <Tab
                            className={value === 'files' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].files}
                                {files ? ' (' + files + ')' : null}
                            </>}
                            value="files" />
                        <Tab
                            className={value === 'notes' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].notes}
                            </>}
                            value="notes" />
                    </TabList>
                </Box>
                <TabPanel className="assets_info" value="agreement_info">
                    <AgreementInfo />
                </TabPanel>
                <TabPanel className="assets_info" value="assets">
                    <Assets />
                </TabPanel>
                <TabPanel className="assets_info" value="partners">
                    <Partners />
                </TabPanel>
                <TabPanel className="assets_info" value="rights">
                    <Rights />
                </TabPanel>
                <TabPanel className="assets_info" value="files">
                    <Files />
                </TabPanel>
                <TabPanel className="assets_info" value="notes">
                    <Notes />
                </TabPanel>
                <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
        </Box>
    );
};

Tabs.propTypes = {
    agreementDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        agreementDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Tabs);
