import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import NoData from '../../../../components/NoData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

const Partners = (props) => {
    const partnersData = props.rightDetails && props.rightDetails.partners;
    const fields = [
        variables[props.lang].partner_name,
        variables[props.lang].partner_id,
        variables[props.lang].partner_type,
        variables[props.lang].platform,
        variables[props.lang].payment_method,
        variables[props.lang].bank_name,
        variables[props.lang].bank_account_number,
        variables[props.lang].bank_branch,
        variables[props.lang].bank_ifsc,
        variables[props.lang].bank_swift_code,
        variables[props.lang].paypal_id,
        variables[props.lang].registered_company_name,
        variables[props.lang].signing_authority,
        variables[props.lang].pan_number,
        variables[props.lang].gstin_number,
        variables[props.lang].tin_number,
    ];

    return (
        <div className="assets_data">
            {partnersData && partnersData.length > 0
                ? partnersData.map((item, index) => {
                    const data = [
                        [item.official_name],
                        [item.custom_Id],
                        [item.type],
                        [item.platform && item.platform.name],
                        [item.payment_method],
                        [item.bank_name],
                        [item.bank_account_number],
                        [item.branch_name],
                        [item.IFSC_code],
                        [item.swift_code],
                        [item.PayPal_ID],
                        [item.registered_company_name],
                        [item.signing_authority],
                        [item.PAN_number],
                        [item.GSTIN_number],
                        [item.TIN_number],
                    ];
                    return (
                        <div key={index} className="info_section">
                            <Accordion className="accordion">
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    className="accordion_summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header">
                                    <h2>{item.official_name}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data && data.map((item1, index1) => {
                                        return (
                                            <div key={index1} className="section1">
                                                <span>{fields[index1]}</span>
                                                <p>{item1}</p>
                                                {item1 &&
                                                    <CopyToClipboard
                                                        text={item1}>
                                                        <div className="copy_icon">
                                                            <Icon className="copy" icon="copy" />
                                                            {variables[props.lang].copy}
                                                        </div>
                                                    </CopyToClipboard>}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })
                : <NoData />}
        </div>
    );
};

Partners.propTypes = {
    lang: PropTypes.string.isRequired,
    rightDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        rightDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Partners);
