import React, { useState } from 'react';
import './index.css';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import Icon from '../../../components/Icon';
import AssetsPopover from './AssetsPopover';
import PartnersPopover from './PartnersPopover';
import AgreementsPopover from './AgreementsPopover';
import PlatformsPopover from './PlatformsPopover';
import RightsPopover from './RightsPopover';
import ProjectsPopover from './ProjectsPopover';
import QCStatusPopover from './QCStatusPopover';
import StartDatePopover from './StartDatePopover';
import EndDatePopover from './EndDatePopover';
import PriorityPopover from './PriorityPopover';
import ActiveInactivePopover from './StatusPopover';
import AgreementTypesPopover from './AgreementTypesPopover';
import TenurePopover from './TenurePopover';
import RightGroupsPopover from './RightGroupsPopover';
import GradesPopover from './GradesPopover';
// import ProductionTypePopover from './ProductionTypePopover';

const FiltersList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [name, setName] = useState(null);

    const handlePopover = (event, name) => {
        setName(name);
        setAnchorEl(event.currentTarget);
    };

    const hidePopover = () => {
        setName(null);
        setAnchorEl(null);
    };

    return (
        <div className="filters_list">
            <div className="section1">
                {(props.from === 'assets' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'assets_filter')}>
                            {variables[props.lang].assets}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <AssetsPopover
                            anchorEl={name && name === 'assets_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {(props.from === 'agreements' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'agreements_filter')}>
                            {variables[props.lang].agreement_id}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <AgreementsPopover
                            anchorEl={name && name === 'agreements_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {(props.from === 'partners' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'partners_filter')}>
                            {variables[props.lang].partners}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <PartnersPopover
                            anchorEl={name && name === 'partners_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {(props.from === 'agreements') || (props.from === 'partners') || (props.from === 'assets') ||
                (props.from === 'rights' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'platforms_filter')}>
                            {variables[props.lang].platforms}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <PlatformsPopover
                            anchorEl={name && name === 'platforms_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {(props.from === 'rights' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'rights_filter')}>
                            {variables[props.lang].rights}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <RightsPopover
                            anchorEl={name && name === 'rights_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {(props.from === 'agreements') || (props.from === 'partners') || (props.from === 'assets') ||
                (props.from === 'rights' || props.from === 'platforms' || props.from === 'projects')
                    ? null
                    : <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'projects_filter')}>
                            {variables[props.lang].projects}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <ProjectsPopover
                            anchorEl={name && name === 'projects_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                <Button
                    aria-haspopup="true"
                    className="filter_type"
                    variant="contained"
                    onClick={(event) => handlePopover(event, 'qc_status_filter')}>
                    {variables[props.lang].qc_status}
                    <Icon className="down-arrow" icon="down-arrow"/>
                </Button>
                <QCStatusPopover
                    anchorEl={name && name === 'qc_status_filter' && anchorEl}
                    hidePopover={hidePopover}/>
                {props.from === 'projects' &&
                    <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'priority_filter')}>
                            {variables[props.lang].priority}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <PriorityPopover
                            anchorEl={name && name === 'priority_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {props.from === 'agreements' &&
                    <>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'status_filter')}>
                            {variables[props.lang].status}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <ActiveInactivePopover
                            anchorEl={name && name === 'status_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'types_filter')}>
                            {variables[props.lang]['agreement_types']}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <AgreementTypesPopover
                            anchorEl={name && name === 'types_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'tenure_filter')}>
                            {variables[props.lang].tenure}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <TenurePopover
                            anchorEl={name && name === 'tenure_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            onClick={(event) => handlePopover(event, 'right_groups_filter')}>
                            {variables[props.lang]['right_groups']}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                        <RightGroupsPopover
                            anchorEl={name && name === 'right_groups_filter' && anchorEl}
                            hidePopover={hidePopover}/>
                    </>}
                {props.from === 'assets' && <>
                    <Button
                        aria-haspopup="true"
                        className="filter_type"
                        variant="contained"
                        onClick={(event) => handlePopover(event, 'grades_filter')}>
                        {variables[props.lang].grades}
                        <Icon className="down-arrow" icon="down-arrow"/>
                    </Button>
                    <GradesPopover
                        anchorEl={name && name === 'grades_filter' && anchorEl}
                        hidePopover={hidePopover}/>
                    {/* <Button */}
                    {/*    aria-haspopup="true" */}
                    {/*    className="filter_type" */}
                    {/*    variant="contained" */}
                    {/*    onClick={(event) => handlePopover(event, 'production_type_filter')}> */}
                    {/*    {variables[props.lang]['production_type']} */}
                    {/*    <Icon className="down-arrow" icon="down-arrow"/> */}
                    {/* </Button> */}
                    {/* <ProductionTypePopover */}
                    {/*    anchorEl={name && name === 'production_type_filter' && anchorEl} */}
                    {/*    hidePopover={hidePopover}/> */}
                </>}
                {props.from === 'agreements'
                    ? <>
                        <StartDatePopover/>
                        <EndDatePopover/>
                    </> : null}
            </div>
        </div>
    );
};

FiltersList.propTypes = {
    from: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(FiltersList);
