export const ALL_ASSETS_LIST_FETCH_IN_PROGRESS = 'ALL_ASSETS_LIST_FETCH_IN_PROGRESS';
export const ALL_ASSETS_LIST_FETCH_SUCCESS = 'ALL_ASSETS_LIST_FETCH_SUCCESS';
export const ALL_ASSETS_LIST_FETCH_ERROR = 'ALL_ASSETS_LIST_FETCH_ERROR';

export const ALL_PARTNERS_LIST_FETCH_IN_PROGRESS = 'ALL_PARTNERS_LIST_FETCH_IN_PROGRESS';
export const ALL_PARTNERS_LIST_FETCH_SUCCESS = 'ALL_PARTNERS_LIST_FETCH_SUCCESS';
export const ALL_PARTNERS_LIST_FETCH_ERROR = 'ALL_PARTNERS_LIST_FETCH_ERROR';

export const ALL_RIGHTS_LIST_FETCH_IN_PROGRESS = 'ALL_RIGHTS_LIST_FETCH_IN_PROGRESS';
export const ALL_RIGHTS_LIST_FETCH_SUCCESS = 'ALL_RIGHTS_LIST_FETCH_SUCCESS';
export const ALL_RIGHTS_LIST_FETCH_ERROR = 'ALL_RIGHTS_LIST_FETCH_ERROR';

export const ALL_AGREEMENTS_LIST_FETCH_IN_PROGRESS = 'ALL_AGREEMENTS_LIST_FETCH_IN_PROGRESS';
export const ALL_AGREEMENTS_LIST_FETCH_SUCCESS = 'ALL_AGREEMENTS_LIST_FETCH_SUCCESS';
export const ALL_AGREEMENTS_LIST_FETCH_ERROR = 'ALL_AGREEMENTS_LIST_FETCH_ERROR';
