import React, { useState } from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import { withRouter } from 'react-router';
import { Button, LinearProgress } from '@mui/material';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { fetchAllRights } from '../../../actions/allTabs';
import { fetchQuickViewDetails, showQuickView } from '../../../actions/allTabs/quickview';
import Icon from '../../../components/Icon';

const RightsTable = (props) => {
    const [time, setTime] = useState(null);
    const handleFetch = (skip, limit, sortBy, order, searchText) => {
        const assetsObj = { ...props.assetsFilter };
        let assets = new Set();
        if (assetsObj[props.tabValue]) {
            assets = new Set(assetsObj[props.tabValue]);
        }

        const agreementsObj = { ...props.agreementsFilter };
        let agreements = new Set();
        if (agreementsObj[props.tabValue]) {
            agreements = new Set(agreementsObj[props.tabValue]);
        }

        const partnersObj = { ...props.partnersFilter };
        let partners = new Set();
        if (partnersObj[props.tabValue]) {
            partners = new Set(partnersObj[props.tabValue]);
        }

        const qcStatusObj = { ...props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[props.tabValue]) {
            qcStatus = new Set(qcStatusObj[props.tabValue]);
        }

        props.fetchAllRights(skip, limit, sortBy, order, searchText, assets, agreements, partners, qcStatus);
    };

    const handleView = (value) => {
        props.showQuickView();
        props.fetchQuickViewDetails('right', value && value._id);
    };

    const handleDelete = (event, value) => {
        event.stopPropagation();
    };

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onRowClick: (rowData, rowState) => {
            const rowIndex = rowState.rowIndex;
            const id = tableData && tableData[rowIndex][4];
            props.showQuickView();
            props.fetchQuickViewDetails('right', (id && id._id));
        },
        onSearchChange: (searchText) => {
            if (time) {
                clearInterval(time);
            }

            const interval = setTimeout(() => {
                handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, props.sortBy, props.order, searchText);
            }, 1000);

            setTime(interval);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: true,
        viewColumns: true,
        page: props.skip / 10,
    };

    const columns = [{
        name: 'id',
        label: 'Right ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Right Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'percentage_of_completion',
        label: '% of completion',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.toFixed(2)}</div>
                );
            },
        },
    }, {
        name: 'quality_check.status',
        label: 'Quality Check',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="clm_actions">
                        <Button onClick={() => handleView(value)}>
                            <Icon className="eye" icon="eye"/>
                        </Button>
                        <Button
                            className="edit_button"
                            onClick={(event) => handleDelete(event, value)}>
                            <Icon className="edit" icon="edit"/>
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.id,
                item.name,
                item.percentage_of_completion,
                item.quality_check
                    ? item.quality_check.status === 'PENDING'
                        ? 'Pending'
                        : item.quality_check.status === 'REJECTED'
                            ? 'Rejected'
                            : item.quality_check.status === 'APPROVED'
                                ? 'Approved'
                                : item.quality_check.status === 'IN_PROGRESS'
                                    ? 'In Progress'
                                    : item.quality_check.status === 'CREATED'
                                        ? 'Created'
                                        : item.quality_check.status
                    : '', item]) : [];

    return (
        <>
            {props.inProgress && <LinearProgress className="linear_progress"/>}
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

RightsTable.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    fetchAllRights: PropTypes.func.isRequired,
    fetchQuickViewDetails: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    showQuickView: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.allTabs.allRights.result,
        inProgress: state.allTabs.allRights.inProgress,
        skip: state.allTabs.allRights.skip,
        limit: state.allTabs.allRights.limit,
        sortBy: state.allTabs.allRights.sortBy,
        total: state.allTabs.allRights.total,
        order: state.allTabs.allRights.order,
        searchKey: state.allTabs.allRights.searchKey,

        tabValue: state.allTabs.tabValue.value,
        assetsFilter: state.allTabs.assetsFilter.value,
        agreementsFilter: state.allTabs.agreementsFilter.value,
        partnersFilter: state.allTabs.partnersFilter.value,
        qcStatusFilter: state.allTabs.qcStatusFilter.value,
    };
};

const actionToProps = {
    fetchAllRights,
    showQuickView,
    fetchQuickViewDetails,
};

export default withRouter(connect(stateToProps, actionToProps)(RightsTable));
