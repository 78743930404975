import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import Table from './Table';
import './index.css';

const Index = () => {
    return (
        <div className="files">
            <div className="files_table">
                <Table />
            </div>
        </div>
    );
};

Index.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Index);
