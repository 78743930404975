import { urlDetailsExport } from '../../constants/allTabs/url';
import Axios from 'axios';
import {
    FETCH_EXPORT_ASSET_FILE_ERROR,
    FETCH_EXPORT_ASSET_FILE_IN_PROGRESS,
    FETCH_EXPORT_ASSET_FILE_SUCCESS,
} from '../../constants/allTabs/files';

const fetchExportAssetFileInProgress = () => {
    return {
        type: FETCH_EXPORT_ASSET_FILE_IN_PROGRESS,
    };
};

const fetchExportAssetFileSuccess = (value) => {
    return {
        type: FETCH_EXPORT_ASSET_FILE_SUCCESS,
        value,
    };
};

const fetchExportAssetFileError = (message) => {
    return {
        type: FETCH_EXPORT_ASSET_FILE_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchExportAssetFile = (from, id, cb) => (dispatch) => {
    dispatch(fetchExportAssetFileInProgress());

    const url = urlDetailsExport(from, id);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchExportAssetFileSuccess(res.data));
            if (cb) {
                cb(res.data);
            }
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(fetchExportAssetFileError(errorMessage.message));
            if (cb) {
                cb(null);
            }
        });
};
