import React from 'react';
import TopNav from './TopNav';
import './index.css';

const NavBar = () => {
    return (
        <TopNav/>
    );
};

export default NavBar;
