import {
    FETCH_AGREEMENTS_REPORT_STATS_ERROR,
    FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS,
    FETCH_AGREEMENTS_REPORT_STATS_SUCCESS,
    FETCH_ASSETS_REPORT_STATS_ERROR,
    FETCH_ASSETS_REPORT_STATS_IN_PROGRESS,
    FETCH_ASSETS_REPORT_STATS_SUCCESS,
    FETCH_PARTNERS_REPORT_STATS_ERROR,
    FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS,
    FETCH_PARTNERS_REPORT_STATS_SUCCESS,
    FETCH_PLATFORMS_REPORT_STATS_ERROR,
    FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS,
    FETCH_PLATFORMS_REPORT_STATS_SUCCESS,
    FETCH_PROJECTS_REPORT_STATS_ERROR,
    FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS,
    FETCH_PROJECTS_REPORT_STATS_SUCCESS,
    FETCH_RIGHTS_REPORT_STATS_ERROR,
    FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS,
    FETCH_RIGHTS_REPORT_STATS_SUCCESS,
} from '../../constants/reports/stats';
import { urlReportStats } from '../../constants/url';
import Axios from 'axios';

const fetchAssetsReportStatsInProgress = () => {
    return {
        type: FETCH_ASSETS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchAssetsReportStatsSuccess = (value) => {
    return {
        type: FETCH_ASSETS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchAssetsReportStatsError = (message) => {
    return {
        type: FETCH_ASSETS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAssetsReportStats = () => (dispatch) => {
    dispatch(fetchAssetsReportStatsInProgress());

    const url = urlReportStats('all-assets');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAssetsReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAssetsReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAgreementsReportStatsInProgress = () => {
    return {
        type: FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchAgreementsReportStatsSuccess = (value) => {
    return {
        type: FETCH_AGREEMENTS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchAgreementsReportStatsError = (message) => {
    return {
        type: FETCH_AGREEMENTS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAgreementsReportStats = () => (dispatch) => {
    dispatch(fetchAgreementsReportStatsInProgress());

    const url = urlReportStats('all-agreements');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAgreementsReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAgreementsReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchPartnersReportStatsInProgress = () => {
    return {
        type: FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchPartnersReportStatsSuccess = (value) => {
    return {
        type: FETCH_PARTNERS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchPartnersReportStatsError = (message) => {
    return {
        type: FETCH_PARTNERS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchPartnersReportStats = () => (dispatch) => {
    dispatch(fetchPartnersReportStatsInProgress());

    const url = urlReportStats('all-partners');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchPartnersReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchPartnersReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchPlatformsReportStatsInProgress = () => {
    return {
        type: FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchPlatformsReportStatsSuccess = (value) => {
    return {
        type: FETCH_PLATFORMS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchPlatformsReportStatsError = (message) => {
    return {
        type: FETCH_PLATFORMS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchPlatformsReportStats = () => (dispatch) => {
    dispatch(fetchPlatformsReportStatsInProgress());

    const url = urlReportStats('all-platforms');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchPlatformsReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchPlatformsReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchRightsReportStatsInProgress = () => {
    return {
        type: FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchRightsReportStatsSuccess = (value) => {
    return {
        type: FETCH_RIGHTS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchRightsReportStatsError = (message) => {
    return {
        type: FETCH_RIGHTS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchRightsReportStats = () => (dispatch) => {
    dispatch(fetchRightsReportStatsInProgress());

    const url = urlReportStats('all-rights');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchRightsReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchRightsReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchProjectsReportStatsInProgress = () => {
    return {
        type: FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS,
    };
};

const fetchProjectsReportStatsSuccess = (value) => {
    return {
        type: FETCH_PROJECTS_REPORT_STATS_SUCCESS,
        value,
    };
};

const fetchProjectsReportStatsError = (message) => {
    return {
        type: FETCH_PROJECTS_REPORT_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProjectsReportStats = () => (dispatch) => {
    dispatch(fetchProjectsReportStatsInProgress());

    const url = urlReportStats('all-projects');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchProjectsReportStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchProjectsReportStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
