import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import variables from '../../../../utils/variables';
import NoData from '../../../../components/NoData';
import { Tooltip } from '@mui/material';

const Rights = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors] = useState([
        '#EE7D2B', '#127681', '#E24759', '#3246FF',
        '#FF0000', '#9500AD', '#FF5C00', '#4343A4',
    ]);
    // eslint-disable-next-line no-unused-vars
    const [seletedColors, setseletedColors] = useState({});

    const rightsData = props.agreementDetails && props.agreementDetails.rights;
    const agreementData = props.agreementDetails && props.agreementDetails.agreement;
    const dub = agreementData && agreementData.tags &&
        agreementData.tags.length > 0 && agreementData.tags.filter((tag) => tag.name === 'Dub');
    const remake = agreementData && agreementData.tags &&
        agreementData.tags.length > 0 && agreementData.tags.filter((tag) => tag.name === 'Remake');

    let remakeRights = [];
    rightsData && rightsData.map((right, index) => {
        const arr = right.tags.filter((tag) => tag.name === 'Remake');
        if (arr && arr.length > 0 && arr[0]) {
            remakeRights = [right, ...remakeRights];
        }

        return null;
    });
    let dubRights = [];
    rightsData && rightsData.map((right, index) => {
        const arr = right.tags.filter((tag) => tag.name === 'Dub');
        if (arr && arr.length > 0 && arr[0]) {
            dubRights = [right, ...dubRights];
        }

        return null;
    });
    const reText = 'Yes  ( ' + (remakeRights && remakeRights.length) + ' )';
    const duText = 'Yes  ( ' + (dubRights && dubRights.length) + ' )';

    return (
        <div className="info_section">
            <div>
                <div className="section1">
                    <span>{variables[props.lang].remake}</span>
                    <p>{remake && remake[0] && remake.length > 0 ? reText : 'No'}</p>
                </div>
                <div className="section1">
                    <span>{variables[props.lang].dubbed}</span>
                    <p> {dub && dub[0] && dub.length > 0 ? duText : 'No'}</p>
                </div>
            </div>
            <h2>{variables[props.lang].rights_details}</h2>
            <div className="rights_list">
                {rightsData && rightsData.length > 0
                    ? rightsData.map((item, index) => {
                        return (
                            <div key={index} className="right_card">
                                <div className="right_name"> {item.name}</div>
                                <div className="right_chips">
                                    {item.tags && item.tags.length > 0 &&
                                        item.tags.map((chip, chipIndex) => {
                                            let randomColor = colors[Math.floor(
                                                Math.random() * colors.length)];

                                            if (seletedColors && Object.keys(seletedColors).length &&
                                                seletedColors[chip + item._id]) {
                                                randomColor = seletedColors[chip + item._id];
                                            }

                                            if (chipIndex === 0 || chipIndex === 1) {
                                                return (
                                                    <div
                                                        key={chipIndex}
                                                        className="chip_back_qv"
                                                        style={{ background: randomColor }}
                                                        title={chip.name}>
                                                        {chip.name}
                                                    </div>
                                                );
                                            }
                                        })}
                                    {item.tags && item.tags.length > 2
                                        ? <Tooltip title={
                                            item.tags.map((chip, chipIndex) => {
                                                if ((chipIndex !== 0) && (chipIndex !== 1)) {
                                                    return <>
                                                        {chip.name}
                                                        {chipIndex < item.tags.length - 1 ? ',\u00A0 ' : ''}
                                                    </>;
                                                }

                                                return null;
                                            })
                                        }>
                                            <div className="chip_back_number">
                                                {item.tags.length - 2}+
                                                more
                                            </div>
                                        </Tooltip>
                                        : ''}
                                </div>
                            </div>
                        );
                    }) : <NoData />}
            </div>
        </div>
    );
};

Rights.propTypes = {
    agreementDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        agreementDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Rights);
