import variables from '../../../../utils/variables';
import { Button } from '@mui/material';
import exportIcon from '../../../../assets/export.svg';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchDetailsExport } from '../../../../actions/allTabs/quickview';
import fileDownload from 'js-file-download';
import { showMessage } from '../../../../actions/snackbar';
import CircularProgress from '../../../../components/CircularProgress';

const Header = (props) => {
    const handleExport = (partnerData) => {
        props.fetchDetailsExport('right', (partnerData && partnerData._id), (cb) => {
            if (cb) {
                const filename = (partnerData && partnerData.name) + '.pdf';
                fileDownload(cb, filename);
            // } else {
            //     props.showMessage(variables[props.lang]['something_went_wrong'], 'error');
            }
        });
    };

    const partnerData = props.rightDetails && props.rightDetails.right;
    const updated = partnerData && partnerData.change_log && partnerData.change_log[0] &&
        partnerData.change_log[0].modified_at;

    return (
        <div className="header">
            <div className="section_details">
                <span>{variables[props.lang]['right_id']}:</span>
                <p>{partnerData && partnerData.id}</p>
            </div>
            <div className="header_right">
                <div className="section_details">
                    <span>{variables[props.lang]['last_updated_on']}:</span>
                    <p>{moment(updated).format('DD MMM, YYYY')}</p>
                </div>
                <p className="divider" />
                <div className="section_details">
                    <span>{variables[props.lang]['asset_completion']}:</span>
                    <p>  {partnerData && partnerData.percentage_of_completion
                        ? partnerData.percentage_of_completion.toFixed(2) + '%'
                        : ''}{' '}</p>
                </div>
                <Button onClick={() => handleExport(partnerData)}>
                    {props.exportInProgress ? <CircularProgress /> : null}
                    <img alt="export" src={exportIcon} />
                    {variables[props.lang].export}
                </Button>
            </div>
        </div>
    );
};

Header.propTypes = {
    exportInProgress: PropTypes.bool.isRequired,
    fetchDetailsExport: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    rightDetails: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        rightDetails: state.allTabs.quickview.quickViewDetails.value,
        exportInProgress: state.allTabs.quickview.exportDetails.inProgress,
    };
};

const actionToProps = {
    fetchDetailsExport,
    showMessage,
};

export default connect(stateToProps, actionToProps)(Header);
