import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
// import Icon from '../../components/Icon';
// import variables from '../../utils/variables';
// import { Button } from '@mui/material';
// import Filters from './Filters';
// import ExportPopover from './ExportPopover';
// import Calendar from './Calendar';

const UpdatedContent = (props) => {
    // const [anchorEl, setAnchorEl] = useState(null);
    //
    // const handlePopover = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    //
    // const hidePopover = () => {
    //     setAnchorEl(null);
    // };

    let assetList;
    if (typeof props.assetsFilter === 'object' && props.assetsFilter !== null) {
        const value = props.tabValue && props.assetsFilter && props.assetsFilter[props.tabValue] &&
            new Set(props.assetsFilter[props.tabValue]);
        if (value) {
            assetList = [...value];
        }
    }
    let partnerList;
    if (typeof props.partnersFilter === 'object' && props.partnersFilter !== null) {
        const value = props.tabValue && props.partnersFilter && props.partnersFilter[props.tabValue] &&
            new Set(props.partnersFilter[props.tabValue]);
        if (value) {
            partnerList = [...value];
        }
    }
    let agreementList;
    if (typeof props.agreementsFilter === 'object' && props.agreementsFilter !== null) {
        const value = props.tabValue && props.agreementsFilter && props.agreementsFilter[props.tabValue] &&
            new Set(props.agreementsFilter[props.tabValue]);
        if (value) {
            agreementList = [...value];
        }
    }
    let platformList;
    if (typeof props.platformsFilter === 'object' && props.platformsFilter !== null) {
        const value = props.tabValue && props.platformsFilter && props.platformsFilter[props.tabValue] &&
            new Set(props.platformsFilter[props.tabValue]);
        if (value) {
            platformList = [...value];
        }
    }
    let rightsList;
    if (typeof props.rightsFilter === 'object' && props.rightsFilter !== null) {
        const value = props.tabValue && props.rightsFilter && props.rightsFilter[props.tabValue] &&
            new Set(props.rightsFilter[props.tabValue]);
        if (value) {
            rightsList = [...value];
        }
    }
    let projectList;
    if (typeof props.projectsFilter === 'object' && props.projectsFilter !== null) {
        const value = props.tabValue && props.projectsFilter && props.projectsFilter[props.tabValue] &&
            new Set(props.projectsFilter[props.tabValue]);
        if (value) {
            projectList = [...value];
        }
    }
    let qcStatusList;
    if (typeof props.qcStatusFilter === 'object' && props.qcStatusFilter !== null) {
        const value = props.tabValue && props.qcStatusFilter && props.qcStatusFilter[props.tabValue] &&
            new Set(props.qcStatusFilter[props.tabValue]);
        if (value) {
            qcStatusList = [...value];
        }
    }

    // eslint-disable-next-line no-unused-vars
    const value = ((assetList && assetList.length > 0 ? assetList.length : 0) +
        (partnerList && partnerList.length > 0 ? partnerList.length : 0) +
        (agreementList && agreementList.length > 0 ? agreementList.length : 0) +
        (platformList && platformList.length > 0 ? platformList.length : 0) +
        (rightsList && rightsList.length > 0 ? rightsList.length : 0) +
        (projectList && projectList.length > 0 ? projectList.length : 0) +
        (qcStatusList && qcStatusList.length > 0 ? qcStatusList.length : 0));

    return (
        <div className="updated_assets overview">
            <div className="header">
                <div className="left_section">
                    <h2>{props.head}</h2>
                </div>
                <div className="right_section">
                    {/* <Calendar/> */}
                    {/* {props.tabValue === 'agreements' && */}
                    {/* <Button */}
                    {/*     className="export" */}
                    {/*     onClick={handlePopover}> */}
                    {/*     {variables[props.lang].export} */}
                    {/*     <Icon className="down-arrow" icon="down-arrow"/> */}
                    {/* </Button>} */}
                    {/* <ExportPopover anchorEl={anchorEl} hidePopover={hidePopover}/> */}
                    {/* <Button */}
                    {/*    className={props.filterValue && props.filterValue.has(props.tabValue) */}
                    {/*        ? 'active_filter' : 'default_filter'} */}
                    {/*    onClick={props.handleFilter}> */}
                    {/*    <Icon className="filters" icon="filters"/> */}
                    {/*    {variables[props.lang].filters}{' '} */}
                    {/*    {value */}
                    {/*        ? <>({value})</> */}
                    {/*        : null} */}
                    {/* </Button> */}
                </div>
            </div>
            {/* {props.filterValue && props.filterValue.has(props.tabValue) && */}
            {/*    <Filters/>} */}
        </div>
    );
};

UpdatedContent.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    filterValue: PropTypes.object.isRequired,
    handleFilter: PropTypes.func.isRequired,
    head: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,

    rightsFilter: PropTypes.object.isRequired,
    tabValue: PropTypes.string.isRequired,
    handlePopover: PropTypes.func,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        filterValue: state.allTabs.filterValue.value,
        tabValue: state.allTabs.tabValue.value,
        assetsFilter: state.allTabs.assetsFilter.value,
        partnersFilter: state.allTabs.partnersFilter.value,
        agreementsFilter: state.allTabs.agreementsFilter.value,
        platformsFilter: state.allTabs.platformsFilter.value,
        rightsFilter: state.allTabs.rightsFilter.value,
        projectsFilter: state.allTabs.projectsFilter.value,
        qcStatusFilter: state.allTabs.qcStatusFilter.value,
    };
};

export default connect(stateToProps)(UpdatedContent);
