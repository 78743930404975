import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import List from './List';
import { Button } from '@mui/material';
import { ReactComponent as AddCircleIcon } from '../../../assets/close-circle.svg';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportPlatforms,
    fetchReportProjects,
    fetchReportRights,
    setAgreementFilters, setAgreementTypesFilters,
    setAppliedAgreementsFilter, setAppliedAgreementTypesFilter,
    setAppliedAssetsFilter, setAppliedGradesFilter,
    setAppliedPartnersFilter, setAppliedPriorityFilter, setAppliedProductionTypeFilter,
    setAppliedQCStatusFilter, setAppliedRightGroupsFilter, setAppliedRightsFilter, setAppliedTenureFilter,
    setAssetsFilter,
    setEndDateFilters, setGradesFilters,
    setPartnerFilters,
    setPlatformFilters,
    setPriorityFilters, setProductionTypeFilters,
    setProjectsFilters,
    setQCStatusFilters, setRightGroupsFilters,
    setRightsFilters,
    setStartDateFilters,
    setStatusFilters, setTenureFilters, setToggleFilters,
} from '../../../actions/reports';
import moment from 'moment';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const Filters = (props) => {
    const clearFilter = (filter) => {
        const agreementsObj = { ...props.agreementsFilter };
        let agreements = new Set();
        if (agreementsObj[props.tabValue]) {
            agreements = new Set(agreementsObj[props.tabValue]);
        }

        const assetsObj = { ...props.assetsFilter };
        let assets = new Set();
        if (assetsObj[props.tabValue]) {
            assets = new Set(assetsObj[props.tabValue]);
        }

        const partnersObj = { ...props.partnersFilter };
        let partners = new Set();
        if (partnersObj[props.tabValue]) {
            partners = new Set(partnersObj[props.tabValue]);
        }

        const rightsObj = { ...props.rightsFilter };
        let rights = new Set();
        if (rightsObj[props.tabValue]) {
            rights = new Set(rightsObj[props.tabValue]);
        }

        const qcStatusObj = { ...props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[props.tabValue]) {
            qcStatus = new Set(qcStatusObj[props.tabValue]);
        }

        const priorityObj = { ...props.priorityFilter };
        let priority = new Set();
        if (priorityObj[props.tabValue]) {
            priority = new Set(priorityObj[props.tabValue]);
        }

        const agreementTypesObj = { ...props.appliedAgreementTypes };
        let agreementTypes = new Set();
        if (agreementTypesObj[props.tabValue]) {
            agreementTypes = new Set(agreementTypesObj[props.tabValue]);
        }

        const tenureObj = { ...props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[props.tabValue]) {
            tenure = new Set(tenureObj[props.tabValue]);
        }

        const rightGroupsObj = { ...props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[props.tabValue]);
        }

        const productionTypeObj = { ...props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[props.tabValue]) {
            productionType = new Set(productionTypeObj[props.tabValue]);
        }

        const gradesObj = { ...props.appliedGrades };
        let grades = new Set();
        if (gradesObj[props.tabValue]) {
            grades = new Set(gradesObj[props.tabValue]);
        }

        const startDateObj = { ...props.startDateFilter };
        const startDate = startDateObj[props.tabValue];

        const endDateObj = { ...props.endDateFilter };
        const endDate = endDateObj[props.tabValue];
        const toggleValue = props.toggleFilter && props.toggleFilter[props.tabValue] &&
            props.toggleFilter[props.tabValue].has('AND');

        // assets
        if (filter === 'assets') {
            const obj = { ...props.assetsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedAssets };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setAssetsFilter(obj);
            props.setAppliedAssetsFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, null, partners, rights, qcStatus, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
            if (props.tabValue === 'partners') {
                props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, props.partnersSortBy,
                    props.partnersOrder, props.partnersSearchKey, null, agreements, rights, qcStatus, toggleValue);
            }
            if (props.tabValue === 'rights') {
                props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, props.rightsSortBy, props.rightsOrder,
                    props.rightsSearchKey, null, agreements, partners, qcStatus, toggleValue);
            }
        }

        // partners
        if (filter === 'partners') {
            const obj = { ...props.partnersFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedPartners };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setPartnerFilters(obj);
            props.setAppliedPartnersFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, null, rights, qcStatus, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, agreements, null, rights, qcStatus, toggleValue, grades, productionType);
            }
            if (props.tabValue === 'rights') {
                props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, props.rightsSortBy, props.rightsOrder,
                    props.rightsSearchKey, assets, agreements, null, qcStatus, toggleValue);
            }
        }

        // agreements
        if (filter === 'agreements') {
            const obj = { ...props.agreementsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedAgreements };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setAgreementFilters(obj);
            props.setAppliedAgreementsFilter(objApp);

            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, null, partners, rights, qcStatus, toggleValue, grades, productionType);
            }
            if (props.tabValue === 'partners') {
                props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, props.partnersSortBy,
                    props.partnersOrder, props.partnersSearchKey, assets, null, rights, qcStatus, toggleValue);
            }
            if (props.tabValue === 'rights') {
                props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, props.rightsSortBy, props.rightsOrder,
                    props.rightsSearchKey, assets, null, partners, qcStatus, toggleValue);
            }
        }

        // platforms
        if (filter === 'platforms') {
            const obj = { ...props.platformsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            props.setPlatformFilters(obj);
        }

        // rights
        if (filter === 'rights') {
            const obj = { ...props.rightsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedRights };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setRightsFilters(obj);
            props.setAppliedRightsFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, null, qcStatus, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, agreements, partners, null, qcStatus, toggleValue, grades, productionType);
            }
            if (props.tabValue === 'partners') {
                props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, props.partnersSortBy,
                    props.partnersOrder, props.partnersSearchKey, assets, agreements, null, qcStatus, toggleValue);
            }
        }

        // projects
        if (filter === 'projects') {
            const obj = { ...props.projectsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            props.setProjectsFilters(obj);
        }

        // QC status
        if (filter === 'qc_status') {
            const obj = { ...props.qcStatusFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedQcStatus };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setQCStatusFilters(obj);
            props.setAppliedQCStatusFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, null, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, agreements, partners, rights, null, toggleValue, grades, productionType);
            }
            if (props.tabValue === 'partners') {
                props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, props.partnersSortBy,
                    props.partnersOrder, props.partnersSearchKey, assets, agreements, rights, null, toggleValue);
            }
            if (props.tabValue === 'rights') {
                props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, props.rightsSortBy, props.rightsOrder,
                    props.rightsSearchKey, assets, agreements, partners, null, toggleValue);
            }
            if (props.tabValue === 'projects') {
                props.fetchReportProjects(DEFAULT_SKIP, DEFAULT_LIMIT, props.projectsSortBy,
                    props.projectsOrder, props.projectsSearchKey, null, priority);
            }
            if (props.tabValue === 'platforms') {
                props.fetchReportPlatforms(DEFAULT_SKIP, DEFAULT_LIMIT, props.platformsSortBy,
                    props.platformsOrder, props.platformsSearchKey, null);
            }
        }

        // Start Date
        if (filter === 'start_date') {
            const obj = { ...props.startDateFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            props.setStartDateFilters(obj);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, null,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
        }

        // End Date
        if (filter === 'end_date') {
            const obj = { ...props.endDateFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            props.setEndDateFilters(obj);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate, null,
                    props.statusFilter, toggleValue, agreementTypes, tenure, rightGroups);
            }
        }

        // priority
        if (filter === 'priority') {
            const obj = { ...props.priorityFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedPriority };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setPriorityFilters(obj);
            props.setAppliedPriorityFilter(objApp);

            if (props.tabValue === 'projects') {
                props.fetchReportProjects(DEFAULT_SKIP, DEFAULT_LIMIT, props.projectsSortBy,
                    props.projectsOrder, props.projectsSearchKey, qcStatus, null);
            }
        }

        // Status
        if (filter === 'status') {
            props.setStatusFilters('');

            props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                endDate, '', toggleValue, agreementTypes, tenure, rightGroups);
        }

        if (filter === 'agreement_types') {
            const obj = { ...props.agreementTypesFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedAgreementTypes };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setAgreementTypesFilters(obj);
            props.setAppliedAgreementTypesFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus,
                    startDate, endDate, props.statusFilter, toggleValue, null, tenure, rightGroups);
            }
        }
        if (filter === 'tenure') {
            const obj = { ...props.tenureFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedTenure };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setTenureFilters(obj);
            props.setAppliedTenureFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, null, rightGroups);
            }
        }
        if (filter === 'right_groups') {
            const obj = { ...props.rightGroupsFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedRightGroups };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setRightGroupsFilters(obj);
            props.setAppliedRightGroupsFilter(objApp);

            if (props.tabValue === 'agreements') {
                props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                    props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                    endDate, props.statusFilter, toggleValue, agreementTypes, tenure, null);
            }
        }
        if (filter === 'grades') {
            const obj = { ...props.gradesFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedGrades };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setGradesFilters(obj);
            props.setAppliedGradesFilter(objApp);

            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, agreements, partners, rights, qcStatus, toggleValue, null, productionType);
            }
        }
        if (filter === 'production_type') {
            const obj = { ...props.productionTypeFilter };
            if (obj[props.tabValue]) {
                delete obj[props.tabValue];
            }

            const objApp = { ...props.appliedProductionType };
            if (objApp[props.tabValue]) {
                delete objApp[props.tabValue];
            }

            props.setProductionTypeFilters(obj);
            props.setAppliedProductionTypeFilter(objApp);

            if (props.tabValue === 'assets') {
                props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, props.assetsSortBy,
                    props.assetsOrder, props.assetsSearchKey, agreements, partners, rights, qcStatus, toggleValue, grades, null);
            }
        }
    };

    let assetList;
    if (typeof props.assetsFilter === 'object' && props.assetsFilter !== null) {
        const value = props.tabValue && props.assetsFilter && props.assetsFilter[props.tabValue] &&
            new Set(props.assetsFilter[props.tabValue]);
        if (value) {
            assetList = [...value];
        }
    }
    let partnerList;
    if (typeof props.partnersFilter === 'object' && props.partnersFilter !== null) {
        const value = props.tabValue && props.partnersFilter && props.partnersFilter[props.tabValue] &&
            new Set(props.partnersFilter[props.tabValue]);
        if (value) {
            partnerList = [...value];
        }
    }
    let agreementList;
    if (typeof props.agreementsFilter === 'object' && props.agreementsFilter !== null) {
        const value = props.tabValue && props.agreementsFilter && props.agreementsFilter[props.tabValue] &&
            new Set(props.agreementsFilter[props.tabValue]);
        if (value) {
            agreementList = [...value];
        }
    }
    let platformList;
    if (typeof props.platformsFilter === 'object' && props.platformsFilter !== null) {
        const value = props.tabValue && props.platformsFilter && props.platformsFilter[props.tabValue] &&
            new Set(props.platformsFilter[props.tabValue]);
        if (value) {
            platformList = [...value];
        }
    }
    let rightsList;
    if (typeof props.rightsFilter === 'object' && props.rightsFilter !== null) {
        const value = props.tabValue && props.rightsFilter && props.rightsFilter[props.tabValue] &&
            new Set(props.rightsFilter[props.tabValue]);
        if (value) {
            rightsList = [...value];
        }
    }
    let projectList;
    if (typeof props.projectsFilter === 'object' && props.projectsFilter !== null) {
        const value = props.tabValue && props.projectsFilter && props.projectsFilter[props.tabValue] &&
            new Set(props.projectsFilter[props.tabValue]);
        if (value) {
            projectList = [...value];
        }
    }
    let qcStatusList;
    if (typeof props.qcStatusFilter === 'object' && props.qcStatusFilter !== null) {
        const value = props.tabValue && props.qcStatusFilter && props.qcStatusFilter[props.tabValue] &&
            new Set(props.qcStatusFilter[props.tabValue]);
        if (value) {
            qcStatusList = [...value];
        }
    }
    let startDateList;
    if (typeof props.startDateFilter === 'object' && props.startDateFilter !== null) {
        startDateList = props.tabValue && props.startDateFilter && props.startDateFilter[props.tabValue];
    }

    let endDateList;
    if (typeof props.endDateFilter === 'object' && props.endDateFilter !== null) {
        endDateList = props.tabValue && props.endDateFilter && props.endDateFilter[props.tabValue];
    }

    let priorityList;
    if (typeof props.priorityFilter === 'object' && props.priorityFilter !== null) {
        const value = props.tabValue && props.priorityFilter && props.priorityFilter[props.tabValue] &&
            new Set(props.priorityFilter[props.tabValue]);
        if (value) {
            priorityList = [...value];
        }
    }
    let agreementTypesList;
    if (typeof props.appliedAgreementTypes === 'object' && props.appliedAgreementTypes !== null) {
        const value = props.tabValue && props.appliedAgreementTypes && props.appliedAgreementTypes[props.tabValue] &&
            new Set(props.appliedAgreementTypes[props.tabValue]);
        if (value) {
            agreementTypesList = [...value];
        }
    }
    let tenureList;
    if (typeof props.appliedTenure === 'object' && props.appliedTenure !== null) {
        const value = props.tabValue && props.appliedTenure && props.appliedTenure[props.tabValue] &&
            new Set(props.appliedTenure[props.tabValue]);
        if (value) {
            tenureList = [...value];
        }
    }
    let rightGroupsList;
    if (typeof props.appliedRightGroups === 'object' && props.appliedRightGroups !== null) {
        const value = props.tabValue && props.appliedRightGroups && props.appliedRightGroups[props.tabValue] &&
            new Set(props.appliedRightGroups[props.tabValue]);
        if (value) {
            rightGroupsList = [...value];
        }
    }
    let gradesList;
    if (typeof props.appliedGrades === 'object' && props.appliedGrades !== null) {
        const value = props.tabValue && props.appliedGrades && props.appliedGrades[props.tabValue] &&
            new Set(props.appliedGrades[props.tabValue]);
        if (value) {
            gradesList = [...value];
        }
    }
    // let productionTypesList;
    // if (typeof props.appliedProductionType === 'object' && props.appliedProductionType !== null) {
    //     const value = props.tabValue && props.appliedProductionType && props.appliedProductionType[props.tabValue] &&
    //         new Set(props.appliedProductionType[props.tabValue]);
    //     if (value) {
    //         productionTypesList = [...value];
    //     }
    // }

    return (
        <div className="filters">
            {props.filterValue && props.filterValue.has(props.tabValue) &&
                <List from={props.tabValue}/>}
            <div className="selected_filters">
                {props.filterValue && props.filterValue.has(props.tabValue) && props.assetsFilter &&
                    assetList && assetList.length &&
                    <div className="section">
                        <span>Assets{':  '}{' '} </span>
                        {assetList && assetList.length > 0 &&
                            <>
                                {assetList.map((item, index) => {
                                    if (index > 4) {
                                        return null;
                                    }

                                    const value = props.assetsFullList && props.assetsFullList.length &&
                                        props.assetsFullList.find((val) => val._id === item);

                                    return <span
                                        key={index}>{(index ? ', ' : '') + ((value && value.name) || item)}</span>;
                                })}
                                {assetList.length && assetList.length > 5 && <span>, +{assetList.length - 5}</span>}
                            </>}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('assets')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.agreementsFilter &&
                    agreementList && agreementList.length > 0 &&
                    <div className="section">
                        <span>Agreement IDs{':  '}{' '} </span>
                        {agreementList && agreementList.length > 0 &&
                            <>
                                {agreementList.map((item, index) => {
                                    if (index > 4) {
                                        return null;
                                    }

                                    const value = props.agreementsFullList && props.agreementsFullList.length &&
                                        props.agreementsFullList.find((val) => val._id === item);

                                    return <span
                                        key={index}>{(index ? ', ' : '') + ((value && value.agreement_id) ||
                                        (value && value.agreement_name) || item)}</span>;
                                })}
                                {agreementList.length && agreementList.length > 5 &&
                                    <span>, +{agreementList.length - 5}</span>}
                            </>}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('agreements')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.partnersFilter &&
                    partnerList && partnerList.length > 0 &&
                    <div className="section">
                        <span>Partners{':  '}{' '} </span>
                        {partnerList && partnerList.length > 0 &&
                            <>
                                {partnerList.map((item, index) => {
                                    if (index > 4) {
                                        return null;
                                    }

                                    const value = props.partnersFullList && props.partnersFullList.length &&
                                        props.partnersFullList.find((val) => val._id === item);

                                    return <span
                                        key={index}>{(index ? ', ' : '') + ((value && value.official_name) ||
                                        (value && value.custom_Id) || item)}</span>;
                                })}
                                {partnerList.length && partnerList.length > 5 &&
                                    <span>, +{partnerList.length - 5}</span>}
                            </>}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('partners')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.platformsFilter &&
                    platformList && platformList.length > 0 &&
                    <div className="section">
                        <span>Platforms{':  '}{' '} </span>
                        {platformList && platformList.length > 0 &&
                            platformList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('platforms')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.rightsFilter &&
                    rightsList && rightsList.length > 0 &&
                    <div className="section">
                        <span>Rights{':  '}{' '} </span>
                        {rightsList && rightsList.length > 0 &&
                            <>
                                {rightsList.map((item, index) => {
                                    if (index > 4) {
                                        return null;
                                    }

                                    const value = props.rightsFullList && props.rightsFullList.length &&
                                        props.rightsFullList.find((val) => val._id === item);

                                    return <span
                                        key={index}>{(index ? ', ' : '') + ((value && value.name) || item)}</span>;
                                })}
                                {rightsList.length && rightsList.length > 5 && <span>, +{rightsList.length - 5}</span>}
                            </>}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('rights')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.projectsFilter &&
                    projectList && projectList.length > 0 &&
                    <div className="section">
                        <span>Projects{':  '}{' '} </span>
                        {projectList && projectList.length > 0 &&
                            projectList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('projects')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.qcStatusFilter &&
                    qcStatusList && qcStatusList.length > 0 &&
                    <div className="section">
                        <span>QC Status{':  '}{' '} </span>
                        {qcStatusList && qcStatusList.length > 0 &&
                            qcStatusList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('qc_status')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.startDateFilter && startDateList &&
                    <div className="section">
                        <span>Start Date{':  '}{' '} </span>
                        <span>{moment(startDateList).format('MMMM Do YYYY, h:mm a')}</span>
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('start_date')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.endDateFilter && endDateList &&
                    <div className="section">
                        <span>End Date{':  '}{' '} </span>
                        <span>{moment(endDateList).format('MMMM Do YYYY, h:mm a')}</span>
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('end_date')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) && props.priorityFilter &&
                    priorityList && priorityList.length > 0 &&
                    <div className="section">
                        <span>Priority{':  '}{' '} </span>
                        {priorityList && priorityList.length > 0 &&
                            priorityList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('priority')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) &&
                    props.tabValue === 'agreements' && props.statusFilter &&
                    <div className="section">
                        <span>Status{':  '}{' '} </span>
                        <span>{props.statusFilter}</span>
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('status')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) &&
                    props.tabValue === 'agreements' && props.appliedAgreementTypes &&
                    agreementTypesList && agreementTypesList.length > 0 &&
                    <div className="section">
                        <span>Agreement Types {':  '}{' '} </span>
                        {agreementTypesList && agreementTypesList.length > 0 &&
                            agreementTypesList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('agreement_types')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) &&
                    props.tabValue === 'agreements' && props.appliedTenure &&
                    tenureList && tenureList.length > 0 &&
                    <div className="section">
                        <span>Tenure {':  '}{' '} </span>
                        {tenureList && tenureList.length > 0 &&
                            tenureList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('tenure')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) &&
                    props.tabValue === 'agreements' && props.appliedRightGroups &&
                    rightGroupsList && rightGroupsList.length > 0 &&
                    <div className="section">
                        <span>Right Groups {':  '}{' '} </span>
                        {rightGroupsList && rightGroupsList.length > 0 &&
                            rightGroupsList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('right_groups')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {props.filterValue && props.filterValue.has(props.tabValue) &&
                    props.tabValue === 'assets' && props.appliedGrades &&
                    gradesList && gradesList.length > 0 &&
                    <div className="section">
                        <span>Grades {':  '}{' '} </span>
                        {gradesList && gradesList.length > 0 &&
                            gradesList.map((item, index) => {
                                return <span key={index}>{(index ? ', ' : '') + item}</span>;
                            })}
                        <Button
                            className="close_button"
                            onClick={() => clearFilter('grades')}>
                            <AddCircleIcon/>
                        </Button>
                    </div>}
                {/* {props.filterValue && props.filterValue.has(props.tabValue) && */}
                {/*    props.tabValue === 'assets' && props.appliedProductionType && */}
                {/*    productionTypesList && productionTypesList.length > 0 && */}
                {/*    <div className="section"> */}
                {/*        <span>Production Type {':  '}{' '} </span> */}
                {/*        {productionTypesList && productionTypesList.length > 0 && */}
                {/*            productionTypesList.map((item, index) => { */}
                {/*                return <span key={index}>{(index ? ', ' : '') + item}</span>; */}
                {/*            })} */}
                {/*        <Button */}
                {/*            className="close_button" */}
                {/*            onClick={() => clearFilter('production_type')}> */}
                {/*            <AddCircleIcon/> */}
                {/*        </Button> */}
                {/*    </div>} */}
            </div>
        </div>
    );
};

Filters.propTypes = {
    agreementTypesFilter: PropTypes.object.isRequired,
    agreementsFilter: PropTypes.object.isRequired,
    agreementsFullList: PropTypes.array.isRequired,

    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedPriority: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,

    assetsFilter: PropTypes.object.isRequired,
    assetsFullList: PropTypes.array.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartners: PropTypes.func.isRequired,
    fetchReportPlatforms: PropTypes.func.isRequired,
    fetchReportProjects: PropTypes.func.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    filterValue: PropTypes.object.isRequired,

    gradesFilter: PropTypes.object.isRequired,

    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    partnersFullList: PropTypes.array.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    productionTypeFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    rightGroupsFilter: PropTypes.object.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    rightsFullList: PropTypes.array.isRequired,
    setAgreementFilters: PropTypes.func.isRequired,
    setAgreementTypesFilters: PropTypes.func.isRequired,
    setAppliedAgreementTypesFilter: PropTypes.func.isRequired,
    setAppliedAgreementsFilter: PropTypes.func.isRequired,
    setAppliedAssetsFilter: PropTypes.func.isRequired,
    setAppliedGradesFilter: PropTypes.func.isRequired,
    setAppliedPartnersFilter: PropTypes.func.isRequired,
    setAppliedPriorityFilter: PropTypes.func.isRequired,
    setAppliedProductionTypeFilter: PropTypes.func.isRequired,
    setAppliedQCStatusFilter: PropTypes.func.isRequired,
    setAppliedRightGroupsFilter: PropTypes.func.isRequired,
    setAppliedRightsFilter: PropTypes.func.isRequired,
    setAppliedTenureFilter: PropTypes.func.isRequired,
    setAssetsFilter: PropTypes.func.isRequired,
    setEndDateFilters: PropTypes.func.isRequired,
    setGradesFilters: PropTypes.func.isRequired,
    setPartnerFilters: PropTypes.func.isRequired,
    setPlatformFilters: PropTypes.func.isRequired,
    setPriorityFilters: PropTypes.func.isRequired,
    setProductionTypeFilters: PropTypes.func.isRequired,
    setProjectsFilters: PropTypes.func.isRequired,
    setQCStatusFilters: PropTypes.func.isRequired,
    setRightGroupsFilters: PropTypes.func.isRequired,
    setRightsFilters: PropTypes.func.isRequired,
    setStartDateFilters: PropTypes.func.isRequired,
    setStatusFilters: PropTypes.func.isRequired,
    setTenureFilters: PropTypes.func.isRequired,
    setToggleFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    tenureFilter: PropTypes.object.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    platformsOrder: PropTypes.string,
    platformsSearchKey: PropTypes.string,
    platformsSortBy: PropTypes.string,
    platformsTotal: PropTypes.number,

    projectsOrder: PropTypes.string,
    projectsSearchKey: PropTypes.string,
    projectsSortBy: PropTypes.string,
    projectsTotal: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        filterValue: state.reports.filterValue.value,
        tabValue: state.reports.tabValue.value,
        assetsFilter: state.reports.reportsAppliedFilters.assets,
        partnersFilter: state.reports.reportsAppliedFilters.partners,
        agreementsFilter: state.reports.reportsAppliedFilters.agreements,
        platformsFilter: state.reports.platformsFilter.value,
        rightsFilter: state.reports.reportsAppliedFilters.rights,
        projectsFilter: state.reports.projectsFilter.value,
        qcStatusFilter: state.reports.reportsAppliedFilters.qcStatus,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        priorityFilter: state.reports.reportsAppliedFilters.priority,
        statusFilter: state.reports.statusFilter.value,
        agreementTypesFilter: state.reports.agreementTypesFilter.value,
        toggleFilter: state.reports.toggleFilter,
        tenureFilter: state.reports.tenureFilter.value,
        rightGroupsFilter: state.reports.rightGroupsFilter.value,
        productionTypeFilter: state.reports.productionTypeFilter.value,
        gradesFilter: state.reports.gradesFilter.value,

        agreementsFullList: state.reports.filters.agreements.fullList,
        assetsFullList: state.reports.filters.assets.fullList,
        partnersFullList: state.reports.filters.partners.fullList,
        rightsFullList: state.reports.filters.rights.fullList,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        partnersOrder: state.reports.reportPartners.order,
        partnersSearchKey: state.reports.reportPartners.searchKey,
        partnersSortBy: state.reports.reportPartners.sortBy,
        partnersTotal: state.reports.reportPartners.total,

        rightsOrder: state.reports.reportRights.order,
        rightsSearchKey: state.reports.reportRights.searchKey,
        rightsSortBy: state.reports.reportRights.sortBy,
        rightsTotal: state.reports.reportRights.total,

        platformsOrder: state.reports.reportPlatforms.order,
        platformsSearchKey: state.reports.reportPlatforms.searchKey,
        platformsSortBy: state.reports.reportPlatforms.sortBy,
        platformsTotal: state.reports.reportPlatforms.total,

        projectsOrder: state.reports.reportProjects.order,
        projectsSearchKey: state.reports.reportProjects.searchKey,
        projectsSortBy: state.reports.reportProjects.sortBy,
        projectsTotal: state.reports.reportProjects.total,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedPriority: state.reports.reportsAppliedFilters.priority,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportRights,
    fetchReportPlatforms,
    fetchReportProjects,

    setAssetsFilter,
    setEndDateFilters,
    setPartnerFilters,
    setAgreementFilters,
    setPlatformFilters,
    setRightsFilters,
    setProjectsFilters,
    setQCStatusFilters,
    setStartDateFilters,
    setPriorityFilters,
    setStatusFilters,
    setToggleFilters,
    setAgreementTypesFilters,
    setRightGroupsFilters,
    setTenureFilters,
    setGradesFilters,
    setProductionTypeFilters,

    setAppliedQCStatusFilter,
    setAppliedAgreementsFilter,
    setAppliedAssetsFilter,
    setAppliedPartnersFilter,
    setAppliedRightsFilter,
    setAppliedPriorityFilter,
    setAppliedAgreementTypesFilter,
    setAppliedRightGroupsFilter,
    setAppliedTenureFilter,
    setAppliedGradesFilter,
    setAppliedProductionTypeFilter,
};

export default connect(stateToProps, actionToProps)(Filters);
