import React from 'react';
import SearchTextField from './SearchTextField';
import { Button } from '@material-ui/core';
import { ReactComponent as Notifications } from '../../assets/notification.svg';
import ProfilePopover from './ProfilePopover';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { fetchProfileDetails, fetchProfileImage } from '../../actions/profile';

class TopNav extends React.Component {
    componentDidMount () {
        if (this.props.profileDetails && !Object.keys(this.props.profileDetails).length &&
            !this.props.profileDetailsInProgress && localStorage.getItem('authorizationToken_DAMReports')) {
            this.props.fetchProfileDetails((result) => {
            });
            this.props.fetchProfileImage();
        }
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            setTimeout(() => {
                this.props.fetchProfileDetails((result) => {
                });
                this.props.fetchProfileImage();
            }, 400);
        }
    }

    render () {
        return (
            <div className="top_nav nav_bar">
                <SearchTextField/>
                <div className="right_profile">
                    <Button>
                        <Notifications/>
                    </Button>
                    <ProfilePopover/>
                </div>
            </div>
        );
    }
}

TopNav.propTypes = {
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileDetailsInProgress: PropTypes.bool.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        auth: state.accessToken.value,

        profileDetails: state.profile.profileDetails.value,
        profileDetailsInProgress: state.profile.profileDetails.inProgress,
    };
};

const actionsToProps = {
    fetchProfileDetails,
    fetchProfileImage,
};

export default connect(stateToProps, actionsToProps)(TopNav);
