export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_TOTAL = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;

export const API_URL = 'https://dam-api-mmm.ctlabs.in';
export const CORE_API = 'https://core-api-mmm.ctlabs.in';
export const MMM_API = 'https://dam-api-mmm.ctlabs.in';
