import React from 'react';
import { setSearchValue } from '../../actions/navbar';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import variables from '../../utils/variables';
import Icon from '../../components/Icon';
import TextField from '../../components/TextField';

const SearchTextField = (props) => {
    return (
        <div className="search">
            <TextField
                id="search"
                inputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon className="search" icon="search"/>
                        </InputAdornment>
                    ),
                }}
                name="search"
                placeholder={variables[props.lang].search}
                value={props.value}
                onChange={props.setSearch}/>
        </div>
    );
};

SearchTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.navbar.searchValue.value,
    };
};

const actionToProps = {
    setSearch: setSearchValue,
};

export default connect(stateToProps, actionToProps)(SearchTextField);
