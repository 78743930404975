import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';

const RightInfo = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors] = useState([
        '#EE7D2B', '#127681', '#E24759', '#3246FF',
        '#FF0000', '#9500AD', '#FF5C00', '#4343A4',
    ]);
    // eslint-disable-next-line no-unused-vars
    const [seletedColors, setseletedColors] = useState({});

    const partnerData = props.rightDetails && props.rightDetails.right;
    const fields = [
        variables[props.lang].right_id,
        variables[props.lang].right_name,
        variables[props.lang].right_group,
        variables[props.lang].delivery_medium,
        variables[props.lang].monetization_model,
        variables[props.lang].right_type,
        variables[props.lang].tags,
    ];

    const data = partnerData && partnerData
        ? [
            [partnerData.id],
            [partnerData.name],
            partnerData.groups && partnerData.groups.length > 0
                ? partnerData.groups.map((items, index) => (
                    <div
                        key={index} className={
                            items.name === 'Linear' ? 'linear_group common_group'
                                : items.name === 'Non-Linear' ? 'non_linear_group common_group'
                                    : items.name === 'Physical' ? 'physical_group common_group'
                                        : items.name === 'Ancillary' ? 'ancillary_group common_group' : 'common_group'}>
                        {items.name}
                    </div>))
                : [],
            partnerData.delivery_mediums &&
            partnerData.delivery_mediums.length > 0
                ? partnerData.delivery_mediums.map((item) => item.name).toString()
                    .replace(/,/g, ', ')
                : [],
            partnerData.monetization_models &&
            partnerData.monetization_models.length > 0
                ? partnerData.monetization_models.map((item) => item.name).toString()
                    .replace(/,/g, ', ')
                : [],
            partnerData.type.name,
            partnerData &&
            partnerData.tags &&
            partnerData.tags.length > 0
                ? partnerData.tags
                    .map((chip, chipIndex) => {
                        let randomColor = colors[Math.floor(
                            Math.random() * colors.length)];

                        if (seletedColors && Object.keys(seletedColors).length &&
                            seletedColors[chip + chip._id]) {
                            randomColor = seletedColors[chip + chip._id];
                        }

                        return (
                            <div
                                key={chipIndex}
                                className={partnerData.tags.length > 3 ? 'chip_back_qv' : 'chip_back_qv chip_back_length'}
                                style={{ background: randomColor }}
                                title={chip.name}>
                                {chip.name}
                            </div>
                        );
                    })
                : [],
        ]
        : null;

    return (
        <div className="info_section">
            {data && data.map((item, index) => {
                return (
                    <div key={index} className="section1">
                        <span>{fields[index]}</span>
                        <p>{item}</p>
                    </div>
                );
            })}
        </div>
    );
};

RightInfo.propTypes = {
    lang: PropTypes.string.isRequired,
    rightDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        rightDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(RightInfo);
