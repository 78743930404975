import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import NoData from '../../../../components/NoData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

function numberToDay (j) {
    return ('0' + j).slice(-2);
}

const AudioTracks = (props) => {
    const titleData = props.assetDetails && props.assetDetails.title;
    const audioTracks = titleData && titleData.audio_tracks;
    const audioTracksHead = [
        variables[props.lang].track_name,
        variables[props.lang].track_type,
        variables[props.lang].duration,
        variables[props.lang].genre,
        variables[props.lang].published_platforms,
        variables[props.lang].file_format,
        variables[props.lang].singers,
        variables[props.lang].lyrics,
    ];

    return (
        <div className="info_section">
            {audioTracks && audioTracks.length > 0
                ? audioTracks.map((audio, audioIndex) => {
                    const audioTracksInfo = [
                        [audio.name],
                        [audio.type],
                        [audio.duration
                            ? Math.floor(
                                (audio.duration - Math.floor(audio.duration / 3600) * 3600) / 60,
                            ) +
                            ':' +
                            numberToDay(
                                Math.floor(
                                    audio.duration -
                                    Math.floor(audio.duration / 3600) * 3600 -
                                    Math.floor(
                                        (audio.duration -
                                            Math.floor(audio.duration / 3600) * 3600) /
                                        60,
                                    ) *
                                    60,
                                ),
                            )
                            : ''],
                        [audio.genres.map((v) => v).toString()
                            .replace(/,/g, ', ')],
                        [audio.published_platforms
                            ? audio.published_platforms
                                .map((g) => g)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [audio.file_format
                            ? audio.file_format
                                .map((g) => g)
                                .toString()
                                .replace(/,/g, ', ')
                            : ''],
                        [audio.singers
                            .map((g) => g.name)
                            .toString()
                            .replace(/,/g, ', ')],
                        [audio.lyrics.map((v) => v.name).toString()
                            .replace(/,/g, ', ')],
                    ];

                    return (
                        <Accordion key={audioIndex} className="accordion">
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                expandIcon={<ExpandMoreIcon />}
                                id="panel1a-header"
                            >
                                <Typography>
                                    {audio.name} {' - '} {titleData && titleData.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {audioTracksInfo && audioTracksInfo.map((item, index) => (
                                    <div key={index} className="section1">
                                        <span>{audioTracksHead[index]}</span>
                                        <p>{item}</p>
                                        {item &&
                                            <CopyToClipboard
                                                text={item}>
                                                <div className="copy_icon">
                                                    <Icon className="copy" icon="copy" />
                                                    {variables[props.lang].copy}
                                                </div>
                                            </CopyToClipboard>}
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    );
                }) : <NoData />}
        </div>
    );
};

AudioTracks.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AudioTracks);
