import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Stack from '@mui/material/Stack';
import { fetchReportAgreements, setEndDateFilters } from '../../../actions/reports';
import { Button } from '@mui/material';
import variables from '../../../utils/variables';
import Icon from '../../../components/Icon';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const EndDateTextField = (props) => {
    const handleChange = (value) => {
        const obj = { ...props.value };
        obj[props.tabValue] = value;

        props.setEndDateFilters(obj);

        if (props.tabValue === 'agreements') {
            const assetsObj = { ...props.assetsFilter };
            let assets = new Set();
            if (assetsObj[props.tabValue]) {
                assets = new Set(assetsObj[props.tabValue]);
            }

            const partnersObj = { ...props.partnersFilter };
            let partners = new Set();
            if (partnersObj[props.tabValue]) {
                partners = new Set(partnersObj[props.tabValue]);
            }

            const rightsObj = { ...props.rightsFilter };
            let rights = new Set();
            if (rightsObj[props.tabValue]) {
                rights = new Set(rightsObj[props.tabValue]);
            }

            const qcStatusObj = { ...props.qcStatusFilter };
            let qcStatus = new Set();
            if (qcStatusObj[props.tabValue]) {
                qcStatus = new Set(qcStatusObj[props.tabValue]);
            }

            const typesObj = { ...props.appliedAgreementTypes };
            let types = new Set();
            if (typesObj[props.tabValue]) {
                types = new Set(typesObj[props.tabValue]);
            }

            const rightGroupsObj = { ...props.appliedRightGroups };
            let rightGroups = new Set();
            if (rightGroupsObj[props.tabValue]) {
                rightGroups = new Set(rightGroupsObj[props.tabValue]);
            }

            const tenureObj = { ...props.appliedTenure };
            let tenure = new Set();
            if (tenureObj[props.tabValue]) {
                tenure = new Set(tenureObj[props.tabValue]);
            }

            const startDateObj = { ...props.startDateFilter };
            const startDate = startDateObj[props.tabValue];
            const toggleValue = props.toggleFilter && props.toggleFilter[props.tabValue] &&
                props.toggleFilter[props.tabValue].has('AND');

            props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, props.agreementsSortBy,
                props.agreementsOrder, props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                value, props.statusFilter, toggleValue, types, tenure, rightGroups);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack className="date_field" spacing={3}>
                <MobileDatePicker
                    renderInput={(params) =>
                        <Button
                            aria-haspopup="true"
                            className="filter_type"
                            variant="contained"
                            {...params}>
                            {variables[props.lang]['end_date']}
                            <Icon className="down-arrow" icon="down-arrow"/>
                        </Button>
                    }
                    value={props.value && props.value[props.tabValue]}
                    onChange={handleChange}/>
            </Stack>
        </LocalizationProvider>
    );
};

EndDateTextField.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    setEndDateFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.endDateFilter.value,
        tabValue: state.reports.tabValue.value,

        agreementsFilter: state.reports.agreementsFilter.value,
        assetsFilter: state.reports.assetsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        rightsFilter: state.reports.rightsFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        statusFilter: state.reports.statusFilter.value,
        toggleFilter: state.reports.toggleFilter,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
    };
};

const actionToProps = {
    fetchReportAgreements,
    setEndDateFilters,
};

export default connect(stateToProps, actionToProps)(EndDateTextField);
