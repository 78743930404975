import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import variables from '../../../../utils/variables';
import RightInfo from './RightInfo';
import Agreements from './Agreements';
import Partners from './Partners';
import Assets from './Assets';

const Tabs = (props) => {
    const [value, setValue] = useState('right_info');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const assets = props.rightDetails && props.rightDetails.assets &&
        props.rightDetails.assets.length;
    const agreements = props.rightDetails && props.rightDetails.agreements &&
        props.rightDetails.agreements.length;
    const partners = props.rightDetails && props.rightDetails.partners &&
        props.rightDetails.partners.length;

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext className="tab_context" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="tab_list" onChange={handleChange}>
                        <Tab
                            className={value === 'right_info' ? 'active_list' : ''}
                            label={variables[props.lang]['right_info']}
                            value="right_info" />
                        <Tab
                            className={value === 'assets' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].assets}
                                {assets ? ' (' + assets + ')' : null}
                            </>}
                            value="assets" />
                        <Tab
                            className={value === 'partners' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].partners}
                                {partners ? ' (' + partners + ')' : null}
                            </>}
                            value="rights" />
                        <Tab
                            className={value === 'agreements' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].agreements}
                                {agreements ? ' (' + agreements + ')' : null}
                            </>}
                            value="agreements" />
                    </TabList>
                </Box>
                <TabPanel className="assets_info" value="right_info">
                    <RightInfo />
                </TabPanel>
                <TabPanel className="assets_info" value="assets">
                    <Assets />
                </TabPanel>
                <TabPanel className="assets_info" value="partners">
                    <Partners />
                </TabPanel>
                <TabPanel className="assets_info" value="agreements">
                    <Agreements />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

Tabs.propTypes = {
    lang: PropTypes.string.isRequired,
    rightDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        rightDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Tabs);
