import {
    FETCH_DETAILS_EXPORT_ERROR,
    FETCH_DETAILS_EXPORT_IN_PROGRESS,
    FETCH_DETAILS_EXPORT_SUCCESS,
    FETCH_DOWNLOAD_FILE_ERROR,
    FETCH_DOWNLOAD_FILE_IN_PROGRESS,
    FETCH_DOWNLOAD_FILE_SUCCESS,
    FETCH_PROJECT_DETAILS_ERROR,
    FETCH_PROJECT_DETAILS_IN_PROGRESS,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_QUICK_VIEW_DETAILS_ERROR,
    FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS,
    FETCH_QUICK_VIEW_DETAILS_SUCCESS,
    QUICK_VIEW_HIDE,
    QUICK_VIEW_SHOW,
} from '../../constants/allTabs/quickview';
import {
    urlAllDetails,
    urlDetailsExport,
    urlDownloadFile, urlQuickViewDetails,
} from '../../constants/allTabs/url';
import Axios from 'axios';

export const showQuickView = (value) => {
    return {
        type: QUICK_VIEW_SHOW,
        value,
    };
};

export const hideQuickView = () => {
    return {
        type: QUICK_VIEW_HIDE,
    };
};

const fetchQuickViewDetailsInProgress = () => {
    return {
        type: FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS,
    };
};

const fetchQuickViewDetailsSuccess = (value) => {
    return {
        type: FETCH_QUICK_VIEW_DETAILS_SUCCESS,
        value,
    };
};

const fetchQuickViewDetailsError = (message) => {
    return {
        type: FETCH_QUICK_VIEW_DETAILS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchQuickViewDetails = (from, id) => (dispatch) => {
    dispatch(fetchQuickViewDetailsInProgress());

    const url = urlQuickViewDetails(from, id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchQuickViewDetailsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchQuickViewDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchDetailsExportInProgress = () => {
    return {
        type: FETCH_DETAILS_EXPORT_IN_PROGRESS,
    };
};

const fetchDetailsExportSuccess = (value) => {
    return {
        type: FETCH_DETAILS_EXPORT_SUCCESS,
        value,
    };
};

const fetchDetailsExportError = (message) => {
    return {
        type: FETCH_DETAILS_EXPORT_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchDetailsExport = (from, id, cb) => (dispatch) => {
    dispatch(fetchDetailsExportInProgress());

    const url = urlDetailsExport(from, id);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchDetailsExportSuccess(res.data));
            if (cb) {
                cb(res.data);
            }
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(fetchDetailsExportError(errorMessage.message));
            if (cb) {
                cb(null);
            }
        });
};

const fetchDownloadFileInProgress = () => {
    return {
        type: FETCH_DOWNLOAD_FILE_IN_PROGRESS,
    };
};

const fetchDownloadFileSuccess = (value) => {
    return {
        type: FETCH_DOWNLOAD_FILE_SUCCESS,
        value,
    };
};

const fetchDownloadFileError = (message) => {
    return {
        type: FETCH_DOWNLOAD_FILE_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchDownloadFile = (from, id, file, fileId, cb) => (dispatch) => {
    dispatch(fetchDownloadFileInProgress());

    const url = urlDownloadFile(from, id, file, fileId);
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchDownloadFileSuccess(res.data));
            if (cb) {
                cb(res.data);
            }
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(fetchDownloadFileError(errorMessage.message));
            if (cb) {
                cb(null);
            }
        });
};

const fetchProjectDetailsInProgress = () => {
    return {
        type: FETCH_PROJECT_DETAILS_IN_PROGRESS,
    };
};

const fetchProjectDetailsSuccess = (value) => {
    return {
        type: FETCH_PROJECT_DETAILS_SUCCESS,
        value,
    };
};

const fetchProjectDetailsError = (message) => {
    return {
        type: FETCH_PROJECT_DETAILS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchProjectDetails = (id) => (dispatch) => {
    dispatch(fetchProjectDetailsInProgress());

    const url = urlAllDetails('project', id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchProjectDetailsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchProjectDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
