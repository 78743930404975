import { combineReducers } from 'redux';
import {
    FETCH_AGREEMENTS_REPORT_STATS_ERROR,
    FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS,
    FETCH_AGREEMENTS_REPORT_STATS_SUCCESS,
    FETCH_ASSETS_REPORT_STATS_ERROR,
    FETCH_ASSETS_REPORT_STATS_IN_PROGRESS,
    FETCH_ASSETS_REPORT_STATS_SUCCESS,
    FETCH_PARTNERS_REPORT_STATS_ERROR,
    FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS,
    FETCH_PARTNERS_REPORT_STATS_SUCCESS,
    FETCH_PLATFORMS_REPORT_STATS_ERROR,
    FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS,
    FETCH_PLATFORMS_REPORT_STATS_SUCCESS,
    FETCH_PROJECTS_REPORT_STATS_ERROR,
    FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS,
    FETCH_PROJECTS_REPORT_STATS_SUCCESS,
    FETCH_RIGHTS_REPORT_STATS_ERROR,
    FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS,
    FETCH_RIGHTS_REPORT_STATS_SUCCESS,
} from '../../constants/reports/stats';
import { DISCONNECT_SET } from '../../constants/profile';

const assetsReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ASSETS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ASSETS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ASSETS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const agreementsReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_AGREEMENTS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_AGREEMENTS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const partnersReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PARTNERS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PARTNERS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const platformsReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PLATFORMS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PLATFORMS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const rightsReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_RIGHTS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_RIGHTS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const projectsReportStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROJECTS_REPORT_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROJECTS_REPORT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    assetsReportStats,
    agreementsReportStats,
    partnersReportStats,
    platformsReportStats,
    rightsReportStats,
    projectsReportStats,
});
