import React from 'react';
import Overview from '../Overview';
import UpdatedAssets from '../UpdatedContent';
import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from './Table';
import { setFilterValue } from '../../../actions/allTabs';
import '../index.css';
import QuickView from './QuickView';

const Partners = (props) => {
    const cards = [{
        head: variables[props.lang]['total_partners'],
        value: props.stats && props.stats.total,
    }, {
        head: variables[props.lang]['partners_without_agreements'],
        value: props.stats && props.stats.withoutAgreements,
    }, {
        head: variables[props.lang]['partners_without_assets'],
        value: props.stats && props.stats.withoutAssets,
    }, {
        head: variables[props.lang]['partners_with_agreements'],
        value: props.stats && props.stats.withAgreements,
    }];

    const handleFilter = () => {
        const filters = new Set(props.filterValue);
        if (filters.has(props.tabValue)) {
            filters.delete(props.tabValue);
        } else {
            filters.add(props.tabValue);
        }

        props.setFilterValue(filters);
    };

    return (
        <div className="all_tabs">
            <Overview
                cards={cards}
                date="26th Sep, 2022 - 05th Oct, 2022"
                head={variables[props.lang]['partners_overview']}
                inProgress={props.statsInProgress}/>
            <UpdatedAssets
                handleFilter={handleFilter}
                head={variables[props.lang].partners}/>
            <div className="table_css">
                <Table/>
            </div>
            <QuickView />
        </div>
    );
};

Partners.propTypes = {
    filterValue: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    setFilterValue: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    statsInProgress: PropTypes.bool.isRequired,
    tabValue: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        filterValue: state.allTabs.filterValue.value,
        tabValue: state.allTabs.tabValue.value,

        stats: state.allTabs.stats.allPartnersStats.value,
        statsInProgress: state.allTabs.stats.allPartnersStats.inProgress,
    };
};

const actionsToProps = {
    setFilterValue,
};

export default connect(stateToProps, actionsToProps)(Partners);
