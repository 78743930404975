import { Button, LinearProgress, Tooltip } from '@mui/material';
import DataTable from '../../../../../components/DataTable';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '../../../../../components/Icon';
// import fileDownload from 'js-file-download';
import moment from 'moment';
import imageIcon from '../../../../../assets/files/image.svg';
import pdfIcon from '../../../../../assets/files/pdf.svg';
// import {hideQuickView} from "../../../../../actions/allTabs/quickview";
import { fetchExportAssetFile } from '../../../../../actions/allTabs/files';
import { showMessage } from '../../../../../actions/snackbar';

const Table = (props) => {
    // const assetData = props.assetDetails && props.assetDetails.title && (props.assetDetails.title);
    const downloadFile = (type, index) => {
        // const titleId = assetData._id;
        // const fileName = data.rowData[6];
        //
        // if (data.rowData[2] !== '-') {
        //     props.showMessage('Please use link to see file', 'error');
        // } else {
        //     this.props.fetchExportAssetFile(titleId, type).then((res) => {
        //         const { fileData } = this.props;
        //         fileDownload(fileData.data, fileName);
        //     });
        // }
    };

    const tableData =
        props.assetDetails && props.assetDetails.title && props.assetDetails.title.files &&
        props.assetDetails.title.files.length > 0
            ? props.assetDetails.title.files.map((value) => [
                value.MIME_type ? (
                    value.MIME_type.includes('image') ? (
                        <p className="file_type">
                            <img alt="" src={imageIcon} />
                        </p>
                    ) : value.MIME_type.includes('application') ? (
                        <p className="file_type">
                            <img alt="" src={pdfIcon} />
                        </p>
                    ) : (
                        'link'
                    )
                ) : (
                    'link'
                ),
                value.name,
                value.link ? (
                    <a href={value.link} rel="noopener noreferrer" target="_blank">
                        {' '}
                        {value.link}{' '}
                    </a>
                ) : (
                    '-'
                ),
                value.link ? '-' : (value.size / (1024 * 1024)).toFixed(2) + 'MB',
                moment(value.created_at).format('DD-MM-YYYY'),
                value._id,
                value.filename ? value.filename : '',
            ])
            : [];

    const options = {
        filterType: 'dropdown',
        responsive: 'scroll',
        search: false,
        filter: false,
        menu: false,
        print: false,
        download: false,
        viewColumns: false,
        expandableRows: false,
        selectableRows: false,
        renderExpandableRow: () => '',

        onRowClick: (rowData, rowState) => {
            // const rowIndex = rowState.rowIndex;
            // const fileId = tableData[rowIndex][5];
            // const titleId = assetData._id;
            if (rowData[2] !== '-') {
                window.open(rowData[2].props.href);
            } else {
                // this.props.getAncillaryFile(titleId, fileId).then((res) => {
                // const { AncillaryData } = this.props;
                // const newBlob = new Blob([AncillaryData.data], {
                //     type: AncillaryData.data.type,
                // });
                // const newWindow = window.open('/apps/pages/loading');
                // newWindow.onload = () => {
                //     newWindow.location = URL.createObjectURL(newBlob);
                // };
                // });
            }
        },
        customToolbar: () => {
            return (
                <div className="edit_title_menu">
                    {/* <Tooltip title="Add file">
                            <button className="addrow_icon_column"><i
                                onClick={() => this.openAncillaryDialog('ancillaryFiles')}
                                class="material-icons">add</i></button>
                        </Tooltip> */}
                    {/* <button className="search_icon_column">
                            <FuseSearch />
                        </button> */}
                </div>
            );
        },
    };

    const columns = [{
        name: 'type',
        label: 'File Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'name',
        label: 'File Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'link',
        label: 'File Link',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'size',
        label: 'File Size',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'date',
        label: 'Uploaded Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'Action',
        label: 'Action',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, index) => {
                return (
                    <div className="files_table_action">
                        <Tooltip title="Download">
                            <Button onClick={() => downloadFile(value, index)}>
                                <Icon className="download" icon="download" />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    }];

    return (
        <>
            {props.inProgress && <LinearProgress className="linear_progress"/>}
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

Table.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    fetchExportAssetFile: PropTypes.func.isRequired,
    fileData: PropTypes.object.isRequired,
    fileInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.allTabs.quickview.quickViewDetails.inProgress,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,

        fileInProgress: state.allTabs.files.exportAssetFile.inProgress,
        fileData: state.allTabs.files.exportAssetFile.value,
    };
};

const actionToProps = {
    fetchExportAssetFile,
    showMessage,
};

export default connect(stateToProps, actionToProps)(Table);
