import { API_URL } from '../../config';

export const urlAllStats = (value) => `${API_URL}/v2/${value}/stats`;
export const urlAssetDetails = (id) => `${API_URL}/v2/asset/${id}`;
export const urlAgreementDetails = (id) => `${API_URL}/v2/agreement/${id}`;
export const urlAllDetails = (value, id) => `${API_URL}/v2/${value}/${id}`;

export const urlQuickViewDetails = (from, id) => `${API_URL}/v2/${from}/${id}`;
export const urlDetailsExport = (from, id) => `${API_URL}/v2/${from}/${id}?exportTo=pdf`;
export const urlDownloadFile = (from, id, file, fileId) => `${API_URL}/v2/${from}/${id}/${file}/${fileId}`;

export const urlDownloadAllFiles = (format, from, assets, agreements, partners, rights, qcStatus, priority, startDate, endDate) => {
    const params = [format];

    if (assets && assets.size) {
        assets.forEach((value) => params.push(`withAsset[]=${value}`));
    }
    if (agreements && agreements.size) {
        agreements.forEach((value) => params.push(`withAgreement[]=${value}`));
    }
    if (partners && partners.size) {
        partners.forEach((value) => params.push(`withPartners[]=${value}`));
    }
    if (rights && rights.size) {
        rights.forEach((value) => params.push(`withRights[]=${value}`));
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }
    if (priority && priority.size) {
        priority.forEach((value) => params.push(`priority[]=${
            value === 'High' ? 0
                : value === 'Medium' ? 1
                    : value === 'Low' ? 2 : 0}`));
    }
    if (startDate) {
        params.push(`startDate=${startDate}`);
    }
    if (endDate) {
        params.push(`endDate=${endDate}`);
    }

    return `${API_URL}/${from}?exportTo=${params.join('&')}`;
};

export const urlAllAgreements = (skip, limit, sortBy, order, searchKey, assets, partners, rights, qcStatus, startDate, endDate) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }
    if (assets && assets.size) {
        assets.forEach((value) => params.push(`withAsset[]=${value}`));
    }
    if (partners && partners.size) {
        partners.forEach((value) => params.push(`withPartners[]=${value}`));
    }
    if (rights && rights.size) {
        rights.forEach((value) => params.push(`withRights[]=${value}`));
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }
    if (startDate) {
        params.push(`startDate=${startDate}`);
    }
    if (endDate) {
        params.push(`endDate=${endDate}`);
    }

    return `${API_URL}/v2/agreements?${params.join('&')}`;
};

export const urlAllPartners = (skip, limit, sortBy, order, searchKey, assets, agreements, rights, qcStatus) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }
    if (assets && assets.size) {
        assets.forEach((value) => params.push(`withAsset[]=${value}`));
    }
    if (agreements && agreements.size) {
        agreements.forEach((value) => params.push(`withAgreement[]=${value}`));
    }
    if (rights && rights.size) {
        rights.forEach((value) => params.push(`withRights[]=${value}`));
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }

    return `${API_URL}/v2/partners?${params.join('&')}`;
};

export const urlAllPlatforms = (skip, limit, sortBy, order, searchKey, qcStatus) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }

    return `${API_URL}/v2/platforms?${params.join('&')}`;
};

export const urlAllProjects = (skip, limit, sortBy, order, searchKey, qcStatus, priority) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }
    if (priority && priority.size) {
        priority.forEach((value) => params.push(`priority[]=${
            value === 'High' ? 0
                : value === 'Medium' ? 1
                    : value === 'Low' ? 2 : 0}`));
    }

    return `${API_URL}/v2/projects?${params.join('&')}`;
};

export const urlAllAssets = (skip, limit, sortBy, order, searchKey, agreements, partners, rights, qcStatus) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }

    if (agreements && agreements.size) {
        agreements.forEach((value) => params.push(`withAgreement[]=${value}`));
    }
    if (partners && partners.size) {
        partners.forEach((value) => params.push(`withPartners[]=${value}`));
    }
    if (rights && rights.size) {
        rights.forEach((value) => params.push(`withRights[]=${value}`));
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }

    return `${API_URL}/v2/assets?${params.join('&')}`;
};

export const urlAllRights = (skip, limit, sortBy, order, searchKey, assets, agreements, partners, qcStatus) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (searchKey) {
        params.push(`search=${searchKey}`);
    }

    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        } else {
            params.push('order=desc');
        }
    }
    if (assets && assets.size) {
        assets.forEach((value) => params.push(`withAsset[]=${value}`));
    }
    if (agreements && agreements.size) {
        agreements.forEach((value) => params.push(`withAgreement[]=${value}`));
    }
    if (partners && partners.size) {
        partners.forEach((value) => params.push(`withPartners[]=${value}`));
    }
    if (qcStatus && qcStatus.size) {
        qcStatus.forEach((value) => params.push(`qcStatus[]=${value}`));
    }

    return `${API_URL}/v2/rights?${params.join('&')}`;
};
