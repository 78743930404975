import { Button, Card } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginUser } from '../../actions/login';
import EmailTextField from './EmailTextField';
import './index.css';
import PasswordTextField from './PasswordTextField';
import CircularProgress from '../../components/CircularProgress';
import { fetchAccessToken } from '../../actions/accessToken';
import logo from '../../assets/logo.svg';
import variables from '../../utils/variables';
import { Checkbox, FormControl, FormControlLabel, Link } from '@mui/material';
import { fetchProfileDetails, fetchProfileImage } from '../../actions/profile';

const Login = (props) => {
    const [remember, setRemember] = useState(false);
    const { history } = props;

    useEffect(() => {
        const token = localStorage.getItem('authorizationToken_DAMReports');

        if (token) {
            history.push('/reports');
        }
    }, [history]);

    const characterCode = (event) => {
        if (event.keyCode === 13) {
            onClick();
        }
    };

    const onClick = () => {
        if (props.inProgress) {
            return;
        }
        props.onClick(props.email, props.password, (cb) => {
            if (cb) {
                return;
            }

            const token = localStorage.getItem('authorizationToken_DAMReports');
            if (token) {
                props.fetchProfileDetails((data) => {
                    if (data) {
                        props.history.push('/reports');
                    }
                    props.fetchProfileImage();
                });
            } else {
                props.history.push('/login');
            }

            if (token) {
                const decoded = jwt.decode(token);
                const difference = moment(new Date((decoded.exp) * 1000)).diff(moment(new Date()));
                const nextCallTime = difference - 300000;
                const refreshToken = localStorage.getItem('refreshToken_DAMReports');
                setTimeout(() => {
                    props.fetchAccessToken(refreshToken, () => {
                    });
                }, nextCallTime);
            }
        });
    };

    const handleCheckbox = (event) => {
        const value = event.target.checked;
        setRemember(value);
        if (remember || value) {
            localStorage.setItem('username_DAMReports', props.email && props.email.value);
            localStorage.setItem('password_DAMReports', props.password);
        } else {
            localStorage.removeItem('username_DAMReports');
            localStorage.removeItem('password_DAMReports');
        }
    };

    const disable = (props.email && props.email.value) === '' || props.password === '' || !props.valid;
    return (
        <div className="login">
            <div className="left_section">
                <img alt="logo" src={logo}/>
                <p>DAM Reports</p>
            </div>
            <div className="card_div">
                <Card className="card_login">
                    <h2> {variables[props.lang]['sign_in_your_account']}</h2>
                    <form onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onClick(ev);
                        }
                    }}>
                        <div className="fields_section">
                            <EmailTextField/>
                            <div className="password_remember">
                                <PasswordTextField/>
                            </div>
                        </div>
                        <div className="remember_me">
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={remember}
                                            className="checkbox_color"
                                            name="remember"
                                            onChange={handleCheckbox}/>}
                                    label="Remember Me"
                                />
                            </FormControl>
                            <Link
                                className="font-medium forgotpassword_font"
                                to="/apps/user/forgot/password">Forgot Password</Link>
                        </div>
                        {props.inProgress && <CircularProgress className="full_screen"/>}
                        <div className="login_action">
                            {props.inProgress
                                ? <Button
                                    className={disable ? 'action_button disabled_button' : 'action_button'}
                                    color="primary"
                                    disabled={disable}
                                    onKeyDown={(event) => characterCode(event)}>
                                    SIGN IN
                                </Button>
                                : <Button
                                    className={disable ? 'action_button disabled_button' : 'action_button'}
                                    color="primary"
                                    disabled={disable}
                                    onClick={onClick}
                                    onKeyDown={(event) => characterCode(event)}>
                                    SIGN IN
                                </Button>}
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
};

Login.propTypes = {
    email: PropTypes.object.isRequired,
    fetchAccessToken: PropTypes.func.isRequired,
    fetchProfileDetails: PropTypes.func.isRequired,
    fetchProfileImage: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.login.email,
        valid: state.login.email.valid,
        password: state.login.password,
        inProgress: state.login.inProgress,
        lang: state.language,
    };
};

const actionsToProps = {
    onClick: loginUser,
    fetchAccessToken,
    fetchProfileDetails,
    fetchProfileImage,
};

export default withRouter(connect(stateToProps, actionsToProps)(Login));
