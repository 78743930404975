import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import navbar from './navbar';
import reports from './reports';
import login from './login';
import accessToken from './accessToken';
import profile from './profile';
import allTabs from './allTabs';

export default combineReducers({
    language,
    snackbar,
    navbar,
    reports,
    login,
    accessToken,
    profile,
    allTabs,
});
