import { Button, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { showMessage } from '../../actions/snackbar';
import CircularProgress from '../../components/CircularProgress';
import profileIcon from '../../assets/dummy/profile.png';
import Icon from '../../components/Icon';
import { setDisconnect } from '../../actions/profile';

const ProfilePopover = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        props.history.push('/login');
        props.setDisconnect();
        localStorage.removeItem('authorizationToken_DAMReports');
        localStorage.removeItem('refreshToken_DAMReports');
        localStorage.removeItem('username_DAMReports');
        localStorage.removeItem('password_DAMReports');
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="profile_details">
            <div onClick={onClick}>

                <p className="role_name">
                    {props.profileDetails && props.profileDetails.name
                        ? props.profileDetails.name : 'Full Name'}
                </p>
                {props.profileImage
                    ? <img alt="profile" className="profile_icon" src={props.profileImage}/>
                    : <img alt="profile" className="profile_icon" src={profileIcon}/>}
                <Icon className="down-arrow" icon="down-arrow"/>
            </div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className="export_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}>
                {props.downloadInProgress
                    ? <div className="export_loader">
                        <CircularProgress/>
                    </div>
                    : <div className="export_formats">
                        <Button onClick={handleLogout}>
                            Logout
                        </Button>
                        {/* <span/> */}
                    </div>}
            </Popover>
        </div>

    );
};

ProfilePopover.propTypes = {
    downloadInProgress: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    lang: PropTypes.string.isRequired,
    profileDetails: PropTypes.object.isRequired,
    profileDetailsInProgress: PropTypes.bool.isRequired,
    profileImage: PropTypes.any.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,

        downloadInProgress: state.reports.downloadExportFiles.inProgress,
        downloadResult: state.reports.downloadExportFiles.value,
        profileDetails: state.profile.profileDetails.value,
        profileDetailsInProgress: state.profile.profileDetails.inProgress,
        profileImage: state.profile.profileImage.value,
    };
};

const actionToProps = {
    showMessage,
    setDisconnect,
};

export default withRouter(connect(stateToProps, actionToProps)(ProfilePopover));
