import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    fetchReportAssets,
    setAppliedGradesFilter, setGradesFilters,
} from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { fetchReportGrades } from '../../../actions/reports/filters';

class GradesPopover extends Component {
    constructor (props) {
        super(props);

        this.handleFilters = this.handleFilters.bind(this);
        this.handleApply = this.handleApply.bind(this);
    }

    componentDidMount () {
        if (this.props.result && !this.props.result.length && !this.props.inProgress) {
            this.props.fetchReportGrades();
        }
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setGradesFilters(obj);
    }

    handleApply () {
        const agreementsObj = { ...this.props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[this.props.tabValue]) {
            agreements = new Set(agreementsObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.appliedRights };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.appliedPartners };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const productionTypeObj = { ...this.props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[this.props.tabValue]) {
            productionType = new Set(productionTypeObj[this.props.tabValue]);
        }

        const gradesObj = { ...this.props.value };
        let grades = new Set();
        if (gradesObj[this.props.tabValue]) {
            grades = new Set(gradesObj[this.props.tabValue]);
        }

        const toggleValue = this.props.toggleFilter;

        if (this.props.tabValue === 'assets') {
            const qcAppliedObj = { ...this.props.appliedGrades };
            qcAppliedObj[this.props.tabValue] = grades;
            this.props.setAppliedGradesFilter(qcAppliedObj);

            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy,
                this.props.assetsOrder, this.props.assetsSearchKey, agreements, partners, rights, qcStatus,
                toggleValue, grades, productionType);
        }

        this.props.hidePopover();
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const qcStatusObj = { ...this.props.value };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.props.hidePopover}>
                <div className="menu_list">
                    <div className="by_status">
                        <h2>{variables[this.props.lang]['by_qc_status']}</h2>
                        <FormGroup className="group">
                            {this.props.result && this.props.result.length > 0
                                ? this.props.result.map((item, index) => {
                                    if (item !== '-' && item !== '') {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                                    this.props.value[this.props.tabValue].has(item)}/>}
                                                label={<p>
                                                    {item}
                                                </p>}
                                                onChange={(event) => this.handleFilters(event, item)}/>
                                        );
                                    }
                                },
                                )
                                : <>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('A')}/>}
                                        label={<p>
                                    A
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'A')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('A (Very Good)')}/>}
                                        label={<p>
                                    A (Very Good)
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'A (Very Good)')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('A(Very good)')}/>}
                                        label={<p>
                                    A(Very good)
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'A(Very good)')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('A+')}/>}
                                        label={<p>
                                    A+
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'A+')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('A+ (Excellent)')}/>}
                                        label={<p>
                                    A+ (Excellent)
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'A+ (Excellent)')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('B')}/>}
                                        label={<p>
                                    B
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'B')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('B (Good)')}/>}
                                        label={<p>
                                    B (Good)
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'B (Good)')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('B+')}/>}
                                        label={<p>
                                    B+
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'B+')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('C')}/>}
                                        label={<p>
                                    C
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'C')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('C (Average)')}/>}
                                        label={<p>
                                    C (Average)
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'C (Average)')}/>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('D')}/>}
                                        label={<p>
                                    D
                                        </p>}
                                        onChange={(event) => this.handleFilters(event, 'D')}/>
                                </>}
                        </FormGroup>
                    </div>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                        {qcStatus && qcStatus.size && qcStatus.size > 0
                            ? ' (' + qcStatus.size + ')'
                            : null}
                    </Button>
                </div>
            </Popover>
        );
    }
}

GradesPopover.propTypes = {
    agreements: PropTypes.object.isRequired,
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedPriority: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportGrades: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    result: PropTypes.array.isRequired,
    rights: PropTypes.object.isRequired,
    setAppliedGradesFilter: PropTypes.func.isRequired,
    setGradesFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,

    statsAgreements: PropTypes.object.isRequired,
    statsAssets: PropTypes.object.isRequired,
    statsPartners: PropTypes.object.isRequired,
    statsPlatforms: PropTypes.object.isRequired,
    statsProjects: PropTypes.object.isRequired,
    statsRights: PropTypes.object.isRequired,

    statusFilter: PropTypes.string.isRequired,

    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    anchorEl: PropTypes.any,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.gradesFilter.value,
        tabValue: state.reports.tabValue.value,

        inProgress: state.reports.filters.reportGrades.inProgress,
        result: state.reports.filters.reportGrades.result,

        agreements: state.reports.agreementsFilter.value,
        assets: state.reports.assetsFilter.value,
        partners: state.reports.partnersFilter.value,
        priorityFilter: state.reports.priorityFilter.value,
        rights: state.reports.rightsFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        statusFilter: state.reports.statusFilter.value,
        toggleFilter: state.reports.toggleFilter,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        statsAgreements: state.reports.stats.agreementsReportStats.value,
        statsAssets: state.reports.stats.assetsReportStats.value,
        statsPartners: state.reports.stats.partnersReportStats.value,
        statsPlatforms: state.reports.stats.platformsReportStats.value,
        statsProjects: state.reports.stats.projectsReportStats.value,
        statsRights: state.reports.stats.rightsReportStats.value,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    fetchReportAssets,
    setGradesFilters,
    setAppliedGradesFilter,
    fetchReportGrades,
};

export default connect(stateToProps, actionToProps)(GradesPopover);
