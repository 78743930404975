export const FETCH_ASSETS_REPORT_STATS_IN_PROGRESS = 'FETCH_ASSETS_REPORT_STATS_IN_PROGRESS';
export const FETCH_ASSETS_REPORT_STATS_SUCCESS = 'FETCH_ASSETS_REPORT_STATS_SUCCESS';
export const FETCH_ASSETS_REPORT_STATS_ERROR = 'FETCH_ASSETS_REPORT_STATS_ERROR';

export const FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS = 'FETCH_AGREEMENTS_REPORT_STATS_IN_PROGRESS';
export const FETCH_AGREEMENTS_REPORT_STATS_SUCCESS = 'FETCH_AGREEMENTS_REPORT_STATS_SUCCESS';
export const FETCH_AGREEMENTS_REPORT_STATS_ERROR = 'FETCH_AGREEMENTS_REPORT_STATS_ERROR';

export const FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS = 'FETCH_PARTNERS_REPORT_STATS_IN_PROGRESS';
export const FETCH_PARTNERS_REPORT_STATS_SUCCESS = 'FETCH_PARTNERS_REPORT_STATS_SUCCESS';
export const FETCH_PARTNERS_REPORT_STATS_ERROR = 'FETCH_PARTNERS_REPORT_STATS_ERROR';

export const FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS = 'FETCH_PLATFORMS_REPORT_STATS_IN_PROGRESS';
export const FETCH_PLATFORMS_REPORT_STATS_SUCCESS = 'FETCH_PLATFORMS_REPORT_STATS_SUCCESS';
export const FETCH_PLATFORMS_REPORT_STATS_ERROR = 'FETCH_PLATFORMS_REPORT_STATS_ERROR';

export const FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS = 'FETCH_RIGHTS_REPORT_STATS_IN_PROGRESS';
export const FETCH_RIGHTS_REPORT_STATS_SUCCESS = 'FETCH_RIGHTS_REPORT_STATS_SUCCESS';
export const FETCH_RIGHTS_REPORT_STATS_ERROR = 'FETCH_RIGHTS_REPORT_STATS_ERROR';

export const FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS = 'FETCH_PROJECTS_REPORT_STATS_IN_PROGRESS';
export const FETCH_PROJECTS_REPORT_STATS_SUCCESS = 'FETCH_PROJECTS_REPORT_STATS_SUCCESS';
export const FETCH_PROJECTS_REPORT_STATS_ERROR = 'FETCH_PROJECTS_REPORT_STATS_ERROR';
