import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportRights, setAppliedAgreementsFilter, setAppliedAssetsFilter, setAppliedPartnersFilter,
    setPartnerFilters,
    setSearchFilter,
} from '../../../actions/reports';
import { fetchReportPartnersList } from '../../../actions/reports/filters';
import { DEFAULT_LAZY_FETCH_HEIGHT, DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import CircularProgress from '../../../components/CircularProgress';
import NoData from '../../../components/NoData';
import DotsLoading from '../../../components/DotsLoading';
import SearchTextField from './SearchTextField';
import variables from '../../../utils/variables';

class PartnersPopover extends Component {
    constructor (props) {
        super(props);

        this.state = {
            time: null,
        };

        this.handleFilters = this.handleFilters.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.hidePopover = this.hidePopover.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentDidMount () {
        if (this.props.partners && !this.props.partners.length && !this.props.inProgress &&
            !this.props.reportPartnersInProgress) {
            this.props.fetchReportPartnersList(DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setPartnerFilters(obj);
    }

    handleSelectAll (bool) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (bool) {
            if (this.props.total && this.props.total > DEFAULT_LIMIT) {
                this.props.fetchReportPartnersList(null, this.props.total, this.props.search, (res) => {
                    if (res) {
                        res && res.length > 0 &&
                        res.map((value, index) => {
                            if (!list.has(value && value._id)) {
                                list.add(value && value._id);
                            }

                            return null;
                        });

                        obj[this.props.tabValue] = list;
                        this.props.setPartnerFilters(obj);
                    }
                });
            } else {
                this.props.partners && this.props.partners.length &&
                this.props.partners.map((value) => {
                    if (!list.has(value && value._id)) {
                        list.add(value && value._id);
                    }

                    return null;
                });

                obj[this.props.tabValue] = list;
                this.props.setPartnerFilters(obj);
            }
        } else {
            this.props.setPartnerFilters({});
        }
    }

    handleApply () {
        const assetsObj = { ...this.props.appliedAssets };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const agreementsObj = { ...this.props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[this.props.tabValue]) {
            agreements = new Set(agreementsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.value };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.appliedRights };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const typesObj = { ...this.props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        const rightGroupsObj = { ...this.props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[this.props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[this.props.tabValue]);
        }

        const tenureObj = { ...this.props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[this.props.tabValue]) {
            tenure = new Set(tenureObj[this.props.tabValue]);
        }

        const productionTypeObj = { ...this.props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[this.props.tabValue]) {
            productionType = new Set(productionTypeObj[this.props.tabValue]);
        }

        const gradesObj = { ...this.props.appliedGrades };
        let grades = new Set();
        if (gradesObj[this.props.tabValue]) {
            grades = new Set(gradesObj[this.props.tabValue]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        const toggleValue = this.props.toggleFilter;

        if (this.props.tabValue === 'agreements') {
            const partnersAppliedObj = { ...this.props.appliedPartners };
            partnersAppliedObj[this.props.tabValue] = partners;
            this.props.setAppliedPartnersFilter(partnersAppliedObj);

            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus,
                startDate, endDate, this.props.statusFilter, toggleValue, types, tenure, rightGroups);
        }
        if (this.props.tabValue === 'assets') {
            const partnersAppliedObj = { ...this.props.appliedPartners };
            partnersAppliedObj[this.props.tabValue] = partners;
            this.props.setAppliedPartnersFilter(partnersAppliedObj);

            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy,
                this.props.assetsOrder, this.props.assetsSearchKey, agreements, partners, rights,
                qcStatus, toggleValue, grades, productionType);
        }
        if (this.props.tabValue === 'rights') {
            const partnersAppliedObj = { ...this.props.appliedPartners };
            partnersAppliedObj[this.props.tabValue] = partners;
            this.props.setAppliedPartnersFilter(partnersAppliedObj);

            this.props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.rightsSortBy,
                this.props.rightsOrder, this.props.rightsSearchKey, assets, agreements, partners, qcStatus, toggleValue);
        }

        this.props.hidePopover();
    }

    handleScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((this.props.partners.length < this.props.total) &&
            ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
            !(this.props.inProgress)) {
            this.props.fetchReportPartnersList(this.props.skip + DEFAULT_LIMIT, this.props.limit, this.props.search);
        }
    }

    handleSearch (value) {
        this.props.setSearchFilter(value);

        if (this.state.time) {
            clearInterval(this.state.time);
        }

        const interval = setTimeout(() => {
            this.props.fetchReportPartnersList(DEFAULT_SKIP, DEFAULT_LIMIT, value);
        }, 1000);

        this.setState({
            time: interval,
        });
    }

    hidePopover () {
        this.props.hidePopover();
        this.props.setSearchFilter('');
        if ((this.props.partners && !this.props.partners.length && !this.props.inProgress &&
            !this.props.reportPartnersInProgress) || (this.props.search && this.props.search.length)) {
            this.props.fetchReportPartnersList(DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const inProgress = this.props.inProgress || this.props.reportPartnersInProgress;

        const partnersObj = { ...this.props.value };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.hidePopover}>
                <SearchTextField onChange={this.handleSearch}/>
                <div className="menu_list scroll_bar" onScroll={this.handleScroll}>
                    <FormGroup className="group">
                        {inProgress && this.props.partners && (this.props.partners.length === 0)
                            ? <CircularProgress/>
                            : this.props.partners && this.props.partners.length
                                ? this.props.partners.map((value) => {
                                    return (
                                        <FormControlLabel
                                            key={value && value._id}
                                            control={<Checkbox
                                                checked={(this.props.value && this.props.value[this.props.tabValue] &&
                                                    this.props.value[this.props.tabValue].has(value && value._id)) || false}
                                                onChange={(event) => this.handleFilters(event, value && value._id)}/>}
                                            label={<p>
                                                {(value && value.official_name) || (value && value.custom_Id)}
                                            </p>}/>
                                    );
                                }) : <NoData/>}
                        {(this.props.partners && this.props.partners.length) && inProgress
                            ? <DotsLoading/> : null}
                    </FormGroup>
                </div>
                <div className="apply_filter select_all">
                    <Button onClick={() => this.handleSelectAll(true)}>
                        Select All
                        {' ('}{this.props.total}{')'}
                    </Button>
                    <Button onClick={() => this.handleSelectAll(false)}>
                        Clear All
                        {partners && partners.size && partners.size > 0
                            ? ' (' + partners.size + ')'
                            : null}
                    </Button>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                    </Button>
                </div>
            </Popover>
        );
    }
}

PartnersPopover.propTypes = {
    agreements: PropTypes.object.isRequired,
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartnersList: PropTypes.func.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.array.isRequired,
    qcStatus: PropTypes.object.isRequired,
    reportPartnersInProgress: PropTypes.bool.isRequired,
    rights: PropTypes.object.isRequired,
    searchFilter: PropTypes.string.isRequired,
    setAppliedAgreementsFilter: PropTypes.func.isRequired,
    setAppliedAssetsFilter: PropTypes.func.isRequired,
    setAppliedPartnersFilter: PropTypes.func.isRequired,
    setPartnerFilters: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    anchorEl: PropTypes.any,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    limit: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,

    search: PropTypes.string,
    skip: PropTypes.number,
    total: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.partnersFilter.value,
        tabValue: state.reports.tabValue.value,
        reportPartnersInProgress: state.reports.reportPartners.inProgress,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,

        agreements: state.reports.agreementsFilter.value,
        assets: state.reports.assetsFilter.value,
        rights: state.reports.rightsFilter.value,
        qcStatus: state.reports.qcStatusFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        searchFilter: state.reports.filters.searchFilter.value,
        statusFilter: state.reports.statusFilter.value,
        toggleFilter: state.reports.toggleFilter,

        partners: state.reports.filters.partners.result,
        inProgress: state.reports.filters.partners.inProgress,
        limit: state.reports.filters.partners.limit,
        search: state.reports.filters.partners.searchKey,
        skip: state.reports.filters.partners.skip,
        total: state.reports.filters.partners.total,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        rightsOrder: state.reports.reportRights.order,
        rightsSearchKey: state.reports.reportRights.searchKey,
        rightsSortBy: state.reports.reportRights.sortBy,
        rightsTotal: state.reports.reportRights.total,
    };
};

const actionToProps = {
    fetchReportPartnersList,
    fetchReportAgreements,
    fetchReportRights,
    fetchReportAssets,
    setPartnerFilters,
    setSearchFilter,
    setAppliedAssetsFilter,
    setAppliedAgreementsFilter,
    setAppliedPartnersFilter,
};

export default connect(stateToProps, actionToProps)(PartnersPopover);
