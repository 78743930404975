import React from 'react';
import Overview from '../Overview';
import UpdatedAssets from '../UpdatedContent';
import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setFilterValue } from '../../../actions/reports';
import Table from './Table';

const Assets = (props) => {
    const cards = [{
        head: variables[props.lang]['total_assets_created'],
        value: props.stats && props.stats.created,
    }, {
        head: variables[props.lang]['total_assets_updated'],
        value: props.stats && props.stats.modified,
    }, {
        head: variables[props.lang]['total_qc_assets'],
        value: props.stats && props.stats.qc_count,
    }, {
        head: variables[props.lang]['completion_for_all_assets'],
        value: props.stats && props.stats.completion_percentage,
        percentage: true,
    }];

    const handleFilter = () => {
        const filters = new Set(props.filterValue);
        if (filters.has(props.tabValue)) {
            filters.delete(props.tabValue);
        } else {
            filters.add(props.tabValue);
        }

        props.setFilterValue(filters);
    };

    return (
        <>
            <Overview
                cards={cards}
                date="26th Sep, 2022 - 05th Oct, 2022"
                head={variables[props.lang]['assets_overview']}
                inProgress={props.statsInProgress}/>
            <UpdatedAssets
                handleFilter={handleFilter}
                head={variables[props.lang].assets}/>
            <div className="table_css">
                <Table/>
            </div>
        </>
    );
};

Assets.propTypes = {
    filterValue: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    setFilterValue: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
    statsInProgress: PropTypes.bool.isRequired,
    tabValue: PropTypes.string.isRequired,
    exportDates: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        filterValue: state.reports.filterValue.value,
        tabValue: state.reports.tabValue.value,

        stats: state.reports.stats.assetsReportStats.value,
        statsInProgress: state.reports.stats.assetsReportStats.inProgress,

        exportDates: state.reports.exportDates.value,
    };
};

const actionsToProps = {
    setFilterValue,
};

export default connect(stateToProps, actionsToProps)(Assets);
