import { NAV_REPORT_TAB_VALUE_SET, SEARCH_VALUE_SET, SIDE_BAR_DRAWER } from '../constants/navbar';

export const setNavTab = (value) => {
    return {
        type: NAV_REPORT_TAB_VALUE_SET,
        value,
    };
};

export const setSearchValue = (value) => {
    return {
        type: SEARCH_VALUE_SET,
        value,
    };
};

export const sidebarDrawer = () => {
    return {
        type: SIDE_BAR_DRAWER,
    };
};
