import React from 'react';
import './index.css';
import Tabs from './Tabs';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Assets from './Assets';
import Partners from './Partners';
// import Projects from './Projects';
import Rights from './Rights';
import Platforms from './Platforms';
import Agreements from './Agreements';

const Reports = (props) => {
    return (
        <div className="reports">
            <Tabs/>
            {props.tabValue === 'assets' &&
                <Assets/>}
            {props.tabValue === 'partners' &&
                <Partners/>}
            {props.tabValue === 'agreements' &&
                <Agreements/>}
            {props.tabValue === 'platforms' &&
                <Platforms/>}
            {props.tabValue === 'rights' &&
                <Rights/>}
            {/* {props.tabValue === 'projects' && */}
            {/*    <Projects/>} */}
        </div>
    );
};

Reports.propTypes = {
    lang: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        tabValue: state.reports.tabValue.value,
    };
};

export default connect(stateToProps)(Reports);
