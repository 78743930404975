import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import VendorsTable from './VendorsTable';

const VendorsInfo = (props) => {
    return (
        <div className="vendors_info">
            <VendorsTable list={props.data && props.data.vendors} />
        </div>
    );
};

VendorsInfo.propTypes = {
    data: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(VendorsInfo);
