import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

const AgreementInfo = (props) => {
    const partnerData = props.partnerDetails && props.partnerDetails.partner;
    const fields = [
        variables[props.lang].partner_name,
        variables[props.lang].partner_id,
        variables[props.lang].partner_type,
        variables[props.lang].platform,
        variables[props.lang].payment_method,
        variables[props.lang].bank_name,
        variables[props.lang].bank_account_number,
        variables[props.lang].bank_branch,
        variables[props.lang].bank_ifsc,
        variables[props.lang].bank_swift_code,
        variables[props.lang].paypal_id,
        variables[props.lang].registered_company_name,
        variables[props.lang].signing_authority,
        variables[props.lang].pan_number,
        variables[props.lang].gstin_number,
        variables[props.lang].tin_number,
    ];

    const data = partnerData && partnerData
        ? [
            [partnerData.official_name],
            [partnerData.custom_Id],
            [partnerData.type],
            [partnerData.platform && partnerData.platform.name],
            [partnerData.payment_method],
            [partnerData.bank_name],
            [partnerData.bank_account_number],
            [partnerData.branch_name],
            [partnerData.IFSC_code],
            [partnerData.swift_code],
            [partnerData.PayPal_ID],
            [partnerData.registered_company_name],
            [partnerData.signing_authority],
            [partnerData.PAN_number],
            [partnerData.GSTIN_number],
            [partnerData.TIN_number],
        ]
        : null;

    return (
        <div className="info_section">
            {data && data.map((item, index) => {
                return (
                    <div key={index} className="section1">
                        <span>{fields[index]}</span>
                        <p>{item}</p>
                        {item &&
                            <CopyToClipboard
                                text={item}>
                                <div className="copy_icon">
                                    <Icon className="copy" icon="copy" />
                                    {variables[props.lang].copy}
                                </div>
                            </CopyToClipboard>}
                    </div>
                );
            })}
        </div>
    );
};

AgreementInfo.propTypes = {
    lang: PropTypes.string.isRequired,
    partnerDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        partnerDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AgreementInfo);
