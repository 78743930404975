import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import Section from './Section';
import variables from '../../../../../utils/variables';

const Rights = (props) => {
    const rightsIn = props.platformDetails && props.platformDetails.rights;
    const rightsInCount = props.platformDetails && props.platformDetails.rights &&
        props.platformDetails.rights.length;

    return (
        <div className="assets_right">
            <Section
                count={rightsInCount}
                data={rightsIn}
                header={variables[props.lang].rights}/>
        </div>
    );
};

Rights.propTypes = {
    lang: PropTypes.string.isRequired,
    platformDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        platformDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Rights);
