import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import Card from './Card';

const Overview = (props) => {
    return (
        <div className="overview">
            <div className="header">
                <div className="left_section">
                    <h2>{props.head}</h2>
                </div>
            </div>
            <div className="cards_section">
                {props.cards.map((item, index) =>
                    <Card
                        key={index}
                        content={item.content}
                        content_count={item.content_count}
                        head={item.head}
                        inProgress={props.inProgress}
                        percentage={item.percentage}
                        value={item.value}/>,
                )}
            </div>
        </div>
    );
};

Overview.propTypes = {
    cards: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionsToProps = {};

export default connect(stateToProps, actionsToProps)(Overview);
