import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import React from 'react';

const Notes = (props) => {
    return (
        <div className="info_section">
            <div className="notes_section px-12 py-20 text-18 w-full">
                {props.assetDetails &&
                props.assetDetails.title &&
                props.assetDetails.title.notes
                    ? props.assetDetails.title.notes
                        .split('\n')
                        .map((item, index) => {
                            return (
                                <Typography
                                    key={index}
                                    className="text-18 leading-normal"
                                >
                                    {item}
                                    <br/>
                                </Typography>
                            );
                        })
                    : ''}
            </div>
        </div>
    );
};

Notes.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Notes);
