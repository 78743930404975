import Axios from 'axios';
import {
    FETCH_ALL_AGREEMENTS_STATS_ERROR,
    FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS,
    FETCH_ALL_AGREEMENTS_STATS_SUCCESS,
    FETCH_ALL_ASSETS_STATS_ERROR,
    FETCH_ALL_ASSETS_STATS_IN_PROGRESS,
    FETCH_ALL_ASSETS_STATS_SUCCESS,
    FETCH_ALL_PARTNERS_STATS_ERROR,
    FETCH_ALL_PARTNERS_STATS_IN_PROGRESS,
    FETCH_ALL_PARTNERS_STATS_SUCCESS,
    FETCH_ALL_PLATFORMS_STATS_ERROR,
    FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS,
    FETCH_ALL_PLATFORMS_STATS_SUCCESS,
    FETCH_ALL_PROJECTS_STATS_ERROR,
    FETCH_ALL_PROJECTS_STATS_IN_PROGRESS,
    FETCH_ALL_PROJECTS_STATS_SUCCESS,
    FETCH_ALL_RIGHTS_STATS_ERROR,
    FETCH_ALL_RIGHTS_STATS_IN_PROGRESS,
    FETCH_ALL_RIGHTS_STATS_SUCCESS,
} from '../../constants/allTabs/stats';
import { urlAllStats } from '../../constants/allTabs/url';

const fetchAllAssetsStatsInProgress = () => {
    return {
        type: FETCH_ALL_ASSETS_STATS_IN_PROGRESS,
    };
};

const fetchAllAssetsStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_ASSETS_STATS_SUCCESS,
        value,
    };
};

const fetchAllAssetsStatsError = (message) => {
    return {
        type: FETCH_ALL_ASSETS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllAssetsStats = () => (dispatch) => {
    dispatch(fetchAllAssetsStatsInProgress());

    const url = urlAllStats('assets');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllAssetsStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllAssetsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllAgreementsStatsInProgress = () => {
    return {
        type: FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS,
    };
};

const fetchAllAgreementsStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_AGREEMENTS_STATS_SUCCESS,
        value,
    };
};

const fetchAllAgreementsStatsError = (message) => {
    return {
        type: FETCH_ALL_AGREEMENTS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllAgreementsStats = () => (dispatch) => {
    dispatch(fetchAllAgreementsStatsInProgress());

    const url = urlAllStats('agreements');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllAgreementsStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllAgreementsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllPartnersStatsInProgress = () => {
    return {
        type: FETCH_ALL_PARTNERS_STATS_IN_PROGRESS,
    };
};

const fetchAllPartnersStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_PARTNERS_STATS_SUCCESS,
        value,
    };
};

const fetchAllPartnersStatsError = (message) => {
    return {
        type: FETCH_ALL_PARTNERS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllPartnersStats = () => (dispatch) => {
    dispatch(fetchAllPartnersStatsInProgress());

    const url = urlAllStats('partners');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllPartnersStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllPartnersStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllPlatformsStatsInProgress = () => {
    return {
        type: FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS,
    };
};

const fetchAllPlatformsStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_PLATFORMS_STATS_SUCCESS,
        value,
    };
};

const fetchAllPlatformsStatsError = (message) => {
    return {
        type: FETCH_ALL_PLATFORMS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllPlatformsStats = () => (dispatch) => {
    dispatch(fetchAllPlatformsStatsInProgress());

    const url = urlAllStats('platforms');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllPlatformsStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllPlatformsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllRightsStatsInProgress = () => {
    return {
        type: FETCH_ALL_RIGHTS_STATS_IN_PROGRESS,
    };
};

const fetchAllRightsStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_RIGHTS_STATS_SUCCESS,
        value,
    };
};

const fetchAllRightsStatsError = (message) => {
    return {
        type: FETCH_ALL_RIGHTS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllRightsStats = () => (dispatch) => {
    dispatch(fetchAllRightsStatsInProgress());

    const url = urlAllStats('rights');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllRightsStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllRightsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllProjectsStatsInProgress = () => {
    return {
        type: FETCH_ALL_PROJECTS_STATS_IN_PROGRESS,
    };
};

const fetchAllProjectsStatsSuccess = (value) => {
    return {
        type: FETCH_ALL_PROJECTS_STATS_SUCCESS,
        value,
    };
};

const fetchAllProjectsStatsError = (message) => {
    return {
        type: FETCH_ALL_PROJECTS_STATS_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchAllProjectsStats = () => (dispatch) => {
    dispatch(fetchAllProjectsStatsInProgress());

    const url = urlAllStats('projects');
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllProjectsStatsSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchAllProjectsStatsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
