import { Button, Popover } from '@mui/material';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { setPlatformFilters } from '../../../actions/reports';

const PlatformsPopover = (props) => {
    const handleFilters = (event, value) => {
        const obj = { ...props.value };
        let list = new Set();
        if (obj[props.tabValue]) {
            list = new Set(obj[props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[props.tabValue] = list;

        props.setPlatformFilters(obj);
    };

    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            className="filter_popover"
            id={id}
            open={open}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={props.hidePopover}>
            <div className="menu_list">
                <FormGroup className="group">
                    <FormControlLabel
                        control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                            props.value[props.tabValue].has('partners')}/>}
                        label={<p>
                            {variables[props.lang]['by_partners']}{' '}
                            (234)
                        </p>}
                        onChange={(event) => handleFilters(event, 'partners')}/>
                    <FormControlLabel
                        control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                            props.value[props.tabValue].has('rights')}/>}
                        label={<p>
                            {variables[props.lang]['by_rights']}{' '}
                            (234)
                        </p>}
                        onChange={(event) => handleFilters(event, 'rights')}/>
                    <FormControlLabel
                        control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                            props.value[props.tabValue].has('agreements')}/>}
                        label={<p>
                            {variables[props.lang]['by_agreements']}{' '}
                            (234)
                        </p>}
                        onChange={(event) => handleFilters(event, 'agreements')}/>
                </FormGroup>
                <div className="by_status">
                    <h2>{variables[props.lang]['by_status']}</h2>
                    <FormGroup className="group">
                        <FormControlLabel
                            control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                                props.value[props.tabValue].has('pending')}/>}
                            label={<p>
                                Pending{' '}
                                (234)
                            </p>}
                            onChange={(event) => handleFilters(event, 'pending', 'status')}/>
                        <FormControlLabel
                            control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                                props.value[props.tabValue].has('approved')}/>}
                            label={<p>
                                Approved{' '}
                                (234)
                            </p>}
                            onChange={(event) => handleFilters(event, 'approved', 'status')}/>
                        <FormControlLabel
                            control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                                props.value[props.tabValue].has('rejected')}/>}
                            label={<p>
                                Rejected{' '}
                                (234)
                            </p>}
                            onChange={(event) => handleFilters(event, 'rejected', 'status')}/>
                        <FormControlLabel
                            control={<Checkbox checked={props.value && props.value[props.tabValue] &&
                                props.value[props.tabValue].has('non-qc')}/>}
                            label={<p>
                                Non-QC{' '}
                                (234)
                            </p>}
                            onChange={(event) => handleFilters(event, 'non-qc', 'status')}/>
                    </FormGroup>
                </div>
            </div>
            <div className="apply_filter">
                <Button>
                    {variables[props.lang]['apply_filter']}
                </Button>
            </div>
        </Popover>
    );
};

PlatformsPopover.propTypes = {
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setPlatformFilters: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    anchorEl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.platformsFilter.value,
        tabValue: state.reports.tabValue.value,
    };
};

const actionToProps = {
    setPlatformFilters,
};

export default connect(stateToProps, actionToProps)(PlatformsPopover);
