import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners, setAppliedRightsFilter,
    setRightsFilters,
    setSearchFilter,
} from '../../../actions/reports';
import { DEFAULT_LAZY_FETCH_HEIGHT, DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { fetchReportRightsList } from '../../../actions/reports/filters';
import CircularProgress from '../../../components/CircularProgress';
import NoData from '../../../components/NoData';
import DotsLoading from '../../../components/DotsLoading';
import SearchTextField from './SearchTextField';
import variables from '../../../utils/variables';

class RightsPopover extends Component {
    constructor (props) {
        super(props);

        this.state = {
            time: null,
        };

        this.handleFilters = this.handleFilters.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.hidePopover = this.hidePopover.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    componentDidMount () {
        if (this.props.rights && !this.props.rights.length && !this.props.inProgress &&
            !this.props.reportRightsInProgress) {
            this.props.fetchReportRightsList(DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setRightsFilters(obj);
    }

    handleSelectAll (bool) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (bool) {
            if (this.props.total && this.props.total > DEFAULT_LIMIT) {
                this.props.fetchReportRightsList(null, this.props.total, this.props.search, (res) => {
                    if (res) {
                        res && res.length > 0 &&
                        res.map((value, index) => {
                            if (!list.has(value && value._id)) {
                                list.add(value && value._id);
                            }

                            return null;
                        });

                        obj[this.props.tabValue] = list;
                        this.props.setRightsFilters(obj);
                    }
                });
            } else {
                this.props.rights && this.props.rights.length &&
                this.props.rights.map((value) => {
                    if (!list.has(value && value._id)) {
                        list.add(value && value._id);
                    }

                    return null;
                });

                obj[this.props.tabValue] = list;
                this.props.setRightsFilters(obj);
            }
        } else {
            this.props.setRightsFilters({});
        }
    }

    handleApply () {
        const rightsObj = { ...this.props.value };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const assetsObj = { ...this.props.appliedAssets };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const agreementsObj = { ...this.props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[this.props.tabValue]) {
            agreements = new Set(agreementsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.appliedPartners };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const typesObj = { ...this.props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        const rightGroupsObj = { ...this.props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[this.props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[this.props.tabValue]);
        }

        const tenureObj = { ...this.props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[this.props.tabValue]) {
            tenure = new Set(tenureObj[this.props.tabValue]);
        }

        const productionTypeObj = { ...this.props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[this.props.tabValue]) {
            productionType = new Set(productionTypeObj[this.props.tabValue]);
        }

        const gradesObj = { ...this.props.appliedGrades };
        let grades = new Set();
        if (gradesObj[this.props.tabValue]) {
            grades = new Set(gradesObj[this.props.tabValue]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        const toggleValue = this.props.toggleFilter;

        if (this.props.tabValue === 'agreements') {
            const rightsAppliedObj = { ...this.props.appliedRights };
            rightsAppliedObj[this.props.tabValue] = rights;
            this.props.setAppliedRightsFilter(rightsAppliedObj);

            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus,
                startDate, endDate, this.props.statusFilter, toggleValue, types, tenure, rightGroups);
        }
        if (this.props.tabValue === 'assets') {
            const rightsAppliedObj = { ...this.props.appliedRights };
            rightsAppliedObj[this.props.tabValue] = rights;
            this.props.setAppliedRightsFilter(rightsAppliedObj);

            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy,
                this.props.assetsOrder, this.props.assetsSearchKey, agreements, partners, rights,
                qcStatus, toggleValue, grades, productionType);
        }
        if (this.props.tabValue === 'partners') {
            const rightsAppliedObj = { ...this.props.appliedRights };
            rightsAppliedObj[this.props.tabValue] = rights;
            this.props.setAppliedRightsFilter(rightsAppliedObj);

            this.props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.partnersSortBy,
                this.props.partnersOrder, this.props.partnersSearchKey, assets, agreements, rights, qcStatus, toggleValue);
        }

        this.props.hidePopover();
    }

    handleScroll (e) {
        const scroll = e.target.scrollTop;
        const scrollHeight = e.target.scrollHeight;
        const height = e.target.clientHeight;

        if ((this.props.rights.length < this.props.total) &&
            ((scrollHeight - scroll) <= (height + DEFAULT_LAZY_FETCH_HEIGHT)) &&
            !(this.props.inProgress)) {
            this.props.fetchReportRightsList(this.props.skip + DEFAULT_LIMIT, this.props.limit, this.props.search);
        }
    }

    handleSearch (value) {
        this.props.setSearchFilter(value);

        if (this.state.time) {
            clearInterval(this.state.time);
        }

        const interval = setTimeout(() => {
            this.props.fetchReportRightsList(DEFAULT_SKIP, DEFAULT_LIMIT, value);
        }, 1000);

        this.setState({
            time: interval,
        });
    }

    hidePopover () {
        this.props.hidePopover();
        this.props.setSearchFilter('');
        if ((this.props.rights && !this.props.rights.length && !this.props.inProgress &&
            !this.props.reportRightsInProgress) || (this.props.search && this.props.search.length)) {
            this.props.fetchReportRightsList(DEFAULT_SKIP, DEFAULT_LIMIT);
        }
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const inProgress = this.props.inProgress || this.props.reportRightsInProgress;

        const rightsObj = { ...this.props.value };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.hidePopover}>
                <SearchTextField onChange={this.handleSearch}/>
                <div className="menu_list scroll_bar" onScroll={this.handleScroll}>
                    <FormGroup className="group">
                        {inProgress && this.props.rights && (this.props.rights.length === 0)
                            ? <CircularProgress/>
                            : this.props.rights && this.props.rights.length
                                ? this.props.rights.map((value) => {
                                    return (
                                        <FormControlLabel
                                            key={value && value._id}
                                            control={<Checkbox
                                                checked={(this.props.value && this.props.value[this.props.tabValue] &&
                                                    this.props.value[this.props.tabValue].has(value && value._id)) || false}
                                                onChange={(event) => this.handleFilters(event, value && value._id)}/>}
                                            label={<p>
                                                {value && value.name}
                                            </p>}/>
                                    );
                                }) : <NoData/>}
                        {(this.props.rights && this.props.rights.length) && inProgress
                            ? <DotsLoading/> : null}
                    </FormGroup>
                </div>
                <div className="apply_filter select_all">
                    <Button onClick={() => this.handleSelectAll(true)}>
                        Select All
                        {' ('}{this.props.total}{')'}
                    </Button>
                    <Button onClick={() => this.handleSelectAll(false)}>
                        Clear All
                        {rights && rights.size && rights.size > 0
                            ? ' (' + rights.size + ')'
                            : null}
                    </Button>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                    </Button>
                </div>
            </Popover>
        );
    }
}

RightsPopover.propTypes = {
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedPriority: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartners: PropTypes.func.isRequired,
    fetchReportRightsList: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    qcStatus: PropTypes.object.isRequired,
    reportRightsInProgress: PropTypes.bool.isRequired,
    rights: PropTypes.array.isRequired,
    searchFilter: PropTypes.string.isRequired,
    setAppliedRightsFilter: PropTypes.func.isRequired,
    setRightsFilters: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    anchorEl: PropTypes.any,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    limit: PropTypes.number,

    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    search: PropTypes.string,
    skip: PropTypes.number,
    total: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.rightsFilter.value,
        tabValue: state.reports.tabValue.value,
        reportRightsInProgress: state.reports.reportRights.inProgress,

        agreements: state.reports.agreementsFilter.value,
        assets: state.reports.assetsFilter.value,
        partners: state.reports.partnersFilter.value,
        qcStatus: state.reports.qcStatusFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        searchFilter: state.reports.filters.searchFilter.value,
        statusFilter: state.reports.statusFilter.value,
        toggleFilter: state.reports.toggleFilter,

        rights: state.reports.filters.rights.result,
        inProgress: state.reports.filters.rights.inProgress,
        limit: state.reports.filters.rights.limit,
        search: state.reports.filters.rights.searchKey,
        skip: state.reports.filters.rights.skip,
        total: state.reports.filters.rights.total,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        partnersOrder: state.reports.reportPartners.order,
        partnersSearchKey: state.reports.reportPartners.searchKey,
        partnersSortBy: state.reports.reportPartners.sortBy,
        partnersTotal: state.reports.reportPartners.total,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedPriority: state.reports.reportsAppliedFilters.priority,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportRightsList,
    setRightsFilters,
    setSearchFilter,
    setAppliedRightsFilter,
};

export default connect(stateToProps, actionToProps)(RightsPopover);
