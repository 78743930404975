import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import NoData from '../../../../components/NoData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

const Platforms = (props) => {
    const platformsData = props.assetDetails && props.assetDetails.platforms;
    const fields = [
        variables[props.lang].platform_name,
        variables[props.lang].platform_type,
    ];

    return (
        <div className="assets_data">
            {platformsData && platformsData.length > 0
                ? platformsData.map((item, index) => {
                    const data = [
                        [item.name],
                        [item.type],
                    ];
                    return (
                        <div key={index} className="info_section">
                            <Accordion className="accordion">
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    className="accordion_summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header">
                                    <h2>{item.name}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data && data.map((item1, index1) => {
                                        return (
                                            <div key={index1} className="section1">
                                                <span>{fields[index1]}</span>
                                                <p>{item1}</p>
                                                {item1 &&
                                                    <CopyToClipboard
                                                        text={item1}>
                                                        <div className="copy_icon">
                                                            <Icon className="copy" icon="copy" />
                                                            {variables[props.lang].copy}
                                                        </div>
                                                    </CopyToClipboard>}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })
                : <NoData />}
        </div>
    );
};

Platforms.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Platforms);
