import { combineReducers } from 'redux';
import { DISCONNECT_SET } from '../../constants/profile';
import {
    FETCH_ALL_AGREEMENTS_STATS_ERROR,
    FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS,
    FETCH_ALL_AGREEMENTS_STATS_SUCCESS,
    FETCH_ALL_ASSETS_STATS_ERROR,
    FETCH_ALL_ASSETS_STATS_IN_PROGRESS,
    FETCH_ALL_ASSETS_STATS_SUCCESS,
    FETCH_ALL_PARTNERS_STATS_ERROR,
    FETCH_ALL_PARTNERS_STATS_IN_PROGRESS,
    FETCH_ALL_PARTNERS_STATS_SUCCESS,
    FETCH_ALL_PLATFORMS_STATS_ERROR,
    FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS,
    FETCH_ALL_PLATFORMS_STATS_SUCCESS,
    FETCH_ALL_PROJECTS_STATS_ERROR,
    FETCH_ALL_PROJECTS_STATS_IN_PROGRESS,
    FETCH_ALL_PROJECTS_STATS_SUCCESS,
    FETCH_ALL_RIGHTS_STATS_ERROR,
    FETCH_ALL_RIGHTS_STATS_IN_PROGRESS,
    FETCH_ALL_RIGHTS_STATS_SUCCESS,
} from '../../constants/allTabs/stats';

const allAssetsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_ASSETS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_ASSETS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_ASSETS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allAgreementsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_AGREEMENTS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_AGREEMENTS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allPartnersStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PARTNERS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PARTNERS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_PARTNERS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allPlatformsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PLATFORMS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_PLATFORMS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allRightsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_RIGHTS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_RIGHTS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_RIGHTS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const allProjectsStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PROJECTS_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PROJECTS_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_ALL_PROJECTS_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    allAssetsStats,
    allAgreementsStats,
    allPartnersStats,
    allPlatformsStats,
    allRightsStats,
    allProjectsStats,
});
