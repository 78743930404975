import React from 'react';
import moment from 'moment/moment';
import OutsideClick from 'detect-outside-click-react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import * as PropTypes from 'prop-types';
import { fetchReportAgreements, setEndDateFilters, setStartDateFilters } from '../../actions/reports';
import { connect } from 'react-redux';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';

function formatDateDisplay (date, defaultText) {
    if (!date) {
        return defaultText;
    }
    const newDate = new Date(date);

    return moment(newDate).format('MMM DD, YYYY ');
}

class Calendar extends React.Component {
    constructor (props) {
        super(props);

        this.dateRangeHandler = this.dateRangeHandler.bind(this);
        this.outSideClick = this.outSideClick.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.state = {
            dateRangePicker: {
                selection: {
                    startDate: '',
                    endDate: '',
                    key: 'selection',
                },
            },
        };
    }

    dateRangeHandler () {
        this.setState({ date: !this.state.date });
    }

    outSideClick (e) {
        this.setState({
            date: false,
            toggle: false,
        });
    }

    handleRangeChange (which, payload) {
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });

        const assetsObj = { ...this.props.assetsFilter };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.partnersFilter };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.rightsFilter };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const startDate = { ...this.props.startDateFilter };
        startDate[this.props.tabValue] = payload && payload.selection && payload.selection.startDate;

        const endDate = { ...this.props.endDateFilter };
        endDate[this.props.tabValue] = payload && payload.selection && payload.selection.endDate;

        this.props.setStartDateFilters(startDate);
        this.props.setEndDateFilters(endDate);

        if (this.props.tabValue === 'agreements') {
            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus,
                payload && payload.selection && payload.selection.startDate,
                payload && payload.selection && payload.selection.endDate, this.props.status);
        }
    }

    render () {
        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        return (
            <div>
                <input
                    readOnly
                    className="selected_range"
                    type="text"
                    value={
                        startDate &&
                        endDate
                            ? formatDateDisplay(
                                startDate,
                            ) +
                            ' - ' +
                            formatDateDisplay(
                                endDate,
                            )
                            : 'Select Dates'
                    }
                    onClick={() => this.dateRangeHandler()}
                />
                {this.state.date ? (
                    <OutsideClick close={() => this.dateRangeHandler()}>
                        <DateRangePicker
                            className="preview_area"
                            direction="vertical"
                            months={1}
                            moveRangeOnFirstSelection={false}
                            ranges={[this.state.dateRangePicker.selection]}
                            showSelectionPreview={true}
                            onBlur={this.dateRangeHandler}
                            onChange={this.handleRangeChange.bind(
                                this,
                                'dateRangePicker',
                            )}
                        />
                    </OutsideClick>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

Calendar.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    setEndDateFilters: PropTypes.func.isRequired,
    setStartDateFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        tabValue: state.reports.tabValue.value,

        assetsFilter: state.reports.assetsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        agreementsFilter: state.reports.agreementsFilter.value,
        platformsFilter: state.reports.platformsFilter.value,
        rightsFilter: state.reports.rightsFilter.value,
        projectsFilter: state.reports.projectsFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
        priorityFilter: state.reports.priorityFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        status: state.reports.statusFilter.value,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,
    };
};

const actionsToProps = {
    fetchReportAgreements,
    setEndDateFilters,
    setStartDateFilters,
};

export default connect(stateToProps, actionsToProps)(Calendar);
