import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportPlatforms,
    fetchReportProjects,
    fetchReportRights, setAppliedQCStatusFilter,
    setQCStatusFilters,
} from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

class QCStatusPopover extends Component {
    constructor (props) {
        super(props);

        this.handleFilters = this.handleFilters.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleCount = this.handleCount.bind(this);
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setQCStatusFilters(obj);
    }

    handleApply () {
        const assetsObj = { ...this.props.appliedAssets };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const agreementsObj = { ...this.props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[this.props.tabValue]) {
            agreements = new Set(agreementsObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.appliedRights };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.appliedPartners };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.value };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const priorityObj = { ...this.props.appliedPriority };
        let priority = new Set();
        if (priorityObj[this.props.tabValue]) {
            priority = new Set(priorityObj[this.props.tabValue]);
        }

        const typesObj = { ...this.props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        const rightGroupsObj = { ...this.props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[this.props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[this.props.tabValue]);
        }

        const tenureObj = { ...this.props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[this.props.tabValue]) {
            tenure = new Set(tenureObj[this.props.tabValue]);
        }

        const productionTypeObj = { ...this.props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[this.props.tabValue]) {
            productionType = new Set(productionTypeObj[this.props.tabValue]);
        }

        const gradesObj = { ...this.props.appliedGrades };
        let grades = new Set();
        if (gradesObj[this.props.tabValue]) {
            grades = new Set(gradesObj[this.props.tabValue]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        const toggleValue = this.props.toggleFilter;

        if (this.props.tabValue === 'agreements') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                endDate, this.props.statusFilter, toggleValue, types, tenure, rightGroups);
        }
        if (this.props.tabValue === 'assets') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy,
                this.props.assetsOrder, this.props.assetsSearchKey, agreements, partners, rights, qcStatus,
                toggleValue, grades, productionType);
        }
        if (this.props.tabValue === 'partners') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.partnersSortBy,
                this.props.partnersOrder, this.props.partnersSearchKey, assets, agreements, rights, qcStatus, toggleValue);
        }
        if (this.props.tabValue === 'rights') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.rightsSortBy,
                this.props.rightsOrder, this.props.rightsSearchKey, assets, agreements, partners, qcStatus, toggleValue);
        }
        if (this.props.tabValue === 'platforms') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportPlatforms(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.platformsSortBy,
                this.props.platformsOrder, this.props.platformsSearchKey, qcStatus);
        }
        if (this.props.tabValue === 'projects') {
            const qcAppliedObj = { ...this.props.appliedQcStatus };
            qcAppliedObj[this.props.tabValue] = qcStatus;
            this.props.setAppliedQCStatusFilter(qcAppliedObj);

            this.props.fetchReportProjects(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.projectsSortBy,
                this.props.projectsOrder, this.props.projectsSearchKey, qcStatus, priority);
        }

        this.props.hidePopover();
    }

    handleCount (value) {
        let data = {};

        switch (this.props.tabValue) {
        case 'agreements':
            data = this.props.statsAgreements;
            break;
        case 'assets':
            data = this.props.statsAssets;
            break;
        case 'partners':
            data = this.props.statsPartners;
            break;
        case 'rights':
            data = this.props.statsRights;
            break;
        case 'platforms':
            data = this.props.statsPlatforms;
            break;
        case 'projects':
            data = this.props.statsProjects;
            break;
        default:
            break;
        }

        switch (value) {
        case 'created':
            return data && data.qc_created && data.qc_created ? `(${data.qc_created})` : '(0)';
        case 'pending':
            return data && data.qc_pending && data.qc_pending ? `(${data.qc_pending})` : '(0)';
        case 'in-progress':
            return data && data.qc_in_progress && data.qc_in_progress ? `(${data.qc_in_progress})` : '(0)';
        case 'approved':
            return data && data.qc_approved && data.qc_approved ? `(${data.qc_approved})` : '(0)';
        case 'rejected':
            return data && data.qc_rejected && data.qc_rejected ? `(${data.qc_rejected})` : '(0)';
        default:
            return null;
        }
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const qcStatusObj = { ...this.props.value };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.props.hidePopover}>
                <div className="menu_list">
                    <div className="by_status">
                        <h2>{variables[this.props.lang]['by_qc_status']}</h2>
                        <FormGroup className="group">
                            {this.props.tabValue === 'projects'
                                ? <FormControlLabel
                                    control={<Checkbox
                                        checked={this.props.value && this.props.value[this.props.tabValue] &&
                                            this.props.value[this.props.tabValue].has('CREATED')}/>}
                                    label={<p>
                                        Created
                                        &nbsp;{this.handleCount('created')}
                                    </p>}
                                    onChange={(event) => this.handleFilters(event, 'CREATED')}/> : null}
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('PENDING')}/>}
                                label={<p>
                                    Pending
                                    &nbsp;{this.handleCount('pending')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'PENDING')}/>
                            {this.props.tabValue === 'projects'
                                ? <FormControlLabel
                                    control={<Checkbox
                                        checked={this.props.value && this.props.value[this.props.tabValue] &&
                                            this.props.value[this.props.tabValue].has('IN_PROGRESS')}/>}
                                    label={<p>
                                        In Progress
                                        &nbsp;{this.handleCount('in-progress')}
                                    </p>}
                                    onChange={(event) => this.handleFilters(event, 'IN_PROGRESS')}/> : null}
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('APPROVED')}/>}
                                label={<p>
                                    Approved
                                    &nbsp;{this.handleCount('approved')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'APPROVED')}/>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('REJECTED')}/>}
                                label={<p>
                                    Rejected
                                    &nbsp;{this.handleCount('rejected')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'REJECTED')}/>
                        </FormGroup>
                    </div>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                        {qcStatus && qcStatus.size && qcStatus.size > 0
                            ? ' (' + qcStatus.size + ')'
                            : null}
                    </Button>
                </div>
            </Popover>
        );
    }
}

QCStatusPopover.propTypes = {
    agreements: PropTypes.object.isRequired,
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedPriority: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartners: PropTypes.func.isRequired,
    fetchReportPlatforms: PropTypes.func.isRequired,
    fetchReportProjects: PropTypes.func.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    rights: PropTypes.object.isRequired,
    setAppliedQCStatusFilter: PropTypes.func.isRequired,
    setQCStatusFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,

    statsAgreements: PropTypes.object.isRequired,
    statsAssets: PropTypes.object.isRequired,
    statsPartners: PropTypes.object.isRequired,
    statsPlatforms: PropTypes.object.isRequired,
    statsProjects: PropTypes.object.isRequired,
    statsRights: PropTypes.object.isRequired,

    statusFilter: PropTypes.string.isRequired,

    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    anchorEl: PropTypes.any,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    platformsOrder: PropTypes.string,
    platformsSearchKey: PropTypes.string,
    platformsSortBy: PropTypes.string,
    platformsTotal: PropTypes.number,

    projectsOrder: PropTypes.string,
    projectsSearchKey: PropTypes.string,
    projectsSortBy: PropTypes.string,
    projectsTotal: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.qcStatusFilter.value,
        tabValue: state.reports.tabValue.value,

        agreements: state.reports.agreementsFilter.value,
        assets: state.reports.assetsFilter.value,
        partners: state.reports.partnersFilter.value,
        priorityFilter: state.reports.priorityFilter.value,
        rights: state.reports.rightsFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        statusFilter: state.reports.statusFilter.value,
        toggleFilter: state.reports.toggleFilter,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        partnersOrder: state.reports.reportPartners.order,
        partnersSearchKey: state.reports.reportPartners.searchKey,
        partnersSortBy: state.reports.reportPartners.sortBy,
        partnersTotal: state.reports.reportPartners.total,

        rightsOrder: state.reports.reportRights.order,
        rightsSearchKey: state.reports.reportRights.searchKey,
        rightsSortBy: state.reports.reportRights.sortBy,
        rightsTotal: state.reports.reportRights.total,

        platformsOrder: state.reports.reportPlatforms.order,
        platformsSearchKey: state.reports.reportPlatforms.searchKey,
        platformsSortBy: state.reports.reportPlatforms.sortBy,
        platformsTotal: state.reports.reportPlatforms.total,

        projectsOrder: state.reports.reportProjects.order,
        projectsSearchKey: state.reports.reportProjects.searchKey,
        projectsSortBy: state.reports.reportProjects.sortBy,
        projectsTotal: state.reports.reportProjects.total,

        statsAgreements: state.reports.stats.agreementsReportStats.value,
        statsAssets: state.reports.stats.assetsReportStats.value,
        statsPartners: state.reports.stats.partnersReportStats.value,
        statsPlatforms: state.reports.stats.platformsReportStats.value,
        statsProjects: state.reports.stats.projectsReportStats.value,
        statsRights: state.reports.stats.rightsReportStats.value,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedPriority: state.reports.reportsAppliedFilters.priority,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportRights,
    setQCStatusFilters,
    fetchReportPlatforms,
    fetchReportProjects,
    setAppliedQCStatusFilter,
};

export default connect(stateToProps, actionToProps)(QCStatusPopover);
