import { API_URL, CORE_API, MMM_API } from '../config';

export const USER_LOGIN_URL = `${CORE_API}/login`;
export const USER_ACCESS_TOKEN = `${CORE_API}/me/access-token`;
export const PROFILE_DETAILS_FETCH_URL = `${CORE_API}/me/profile-details`;
export const PROFILE_IMAGE_FETCH_URL = `${CORE_API}/me/profile-image`;

export const urlReportStats = (value) => `${API_URL}/reports/${value}/stats`;
export const urlDownloadExport = (from) => `${API_URL}/reports/${from}`;

export const URL_REPORT_AGREEMENTS = `${API_URL}/reports/all-agreements`;
export const URL_REPORT_PARTNERS = `${API_URL}/reports/all-partners`;
export const URL_REPORT_PLATFORMS = `${API_URL}/reports/all-platforms`;
export const URL_REPORT_PROJECTS = `${API_URL}/reports/all-projects`;
export const URL_REPORT_ASSETS = `${API_URL}/reports/all-assets`;
export const URL_REPORT_RIGHTS = `${API_URL}/reports/all-rights`;
export const URL_REPORT_GRADES = `${MMM_API}/assets/grades`;
