import React from 'react';
import './index.css';

const NoData = () => {
    return (
        <div className="no_data">
            <p>No Data</p>
        </div>
    );
};

export default NoData;
