import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../../config';
import { combineReducers } from 'redux';
import {
    REPORT_AGREEMENTS_LIST_FETCH_ERROR,
    REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS,
    REPORT_AGREEMENTS_LIST_FETCH_SUCCESS,
    REPORT_ASSETS_LIST_FETCH_ERROR,
    REPORT_ASSETS_LIST_FETCH_IN_PROGRESS,
    REPORT_ASSETS_LIST_FETCH_SUCCESS,
    REPORT_GRADES_LIST_FETCH_ERROR,
    REPORT_GRADES_LIST_FETCH_IN_PROGRESS,
    REPORT_GRADES_LIST_FETCH_SUCCESS,
    REPORT_PARTNERS_LIST_FETCH_ERROR,
    REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS,
    REPORT_PARTNERS_LIST_FETCH_SUCCESS,
    REPORT_RIGHTS_LIST_FETCH_ERROR,
    REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS,
    REPORT_RIGHTS_LIST_FETCH_SUCCESS,
} from '../../constants/reports/filters';
import {
    FETCH_REPORT_AGREEMENTS_SUCCESS,
    FETCH_REPORT_ASSETS_SUCCESS,
    FETCH_REPORT_PARTNERS_SUCCESS,
    FETCH_REPORT_RIGHTS_SUCCESS,
    REPORT_SEARCH_FILTER_SET,
} from '../../constants/reports';

const assets = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case REPORT_ASSETS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REPORT_ASSETS_LIST_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case FETCH_REPORT_ASSETS_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP && state.result && !state.result.length) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case REPORT_ASSETS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const partners = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REPORT_PARTNERS_LIST_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case FETCH_REPORT_PARTNERS_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP && state.result && !state.result.length) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case REPORT_PARTNERS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const rights = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REPORT_RIGHTS_LIST_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case FETCH_REPORT_RIGHTS_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP && state.result && !state.result.length) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case REPORT_RIGHTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const agreements = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REPORT_AGREEMENTS_LIST_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case FETCH_REPORT_AGREEMENTS_SUCCESS: {
        const fullList = state.fullList;
        if (action.value && action.value.length) {
            action.value.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP && state.result && !state.result.length) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case REPORT_AGREEMENTS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const searchFilter = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REPORT_SEARCH_FILTER_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const reportGrades = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case REPORT_GRADES_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case REPORT_GRADES_LIST_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    }
    case REPORT_GRADES_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    assets,
    partners,
    rights,
    agreements,
    searchFilter,
    reportGrades,
});
