import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    fetchReportAgreements,
    setAppliedTenureFilter, setTenureFilters,
} from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

class TenurePopover extends Component {
    constructor (props) {
        super(props);

        this.handleFilters = this.handleFilters.bind(this);
        this.handleApply = this.handleApply.bind(this);
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setTenureFilters(obj);
        // this.handleApply(value);
    }

    handleApply () {
        const assetsObj = { ...this.props.appliedAssets };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.appliedRights };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.appliedPartners };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const typesObj = { ...this.props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        const rightGroupsObj = { ...this.props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[this.props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[this.props.tabValue]);
        }

        const tenureObj = { ...this.props.value };
        let tenure = new Set();
        if (tenureObj[this.props.tabValue]) {
            tenure = new Set(tenureObj[this.props.tabValue]);
        }

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        const toggleValue = this.props.toggleFilter;

        const tenureAppliedObj = { ...this.props.appliedTenure };
        tenureAppliedObj[this.props.tabValue] = tenure;
        this.props.setAppliedTenureFilter(tenureAppliedObj);

        this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
            this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
            endDate, this.props.statusFilter, toggleValue, types, tenure, rightGroups);

        this.props.hidePopover();
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const typesObj = { ...this.props.value };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.props.hidePopover}>
                <div className="menu_list">
                    <div className="by_status">
                        <h2>{variables[this.props.lang]['by_qc_status']}</h2>
                        <FormGroup className="group">
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('PERMANENT AND PERPETUAL')}/>}
                                label={<p>
                                    PERMANENT AND PERPETUAL
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'PERMANENT AND PERPETUAL')}/>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('TERM')}/>}
                                label={<p>
                                    TERM
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'TERM')}/>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('PERPETUAL')}/>}
                                label={<p>
                                    PERPETUAL
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'PERPETUAL')}/>
                        </FormGroup>
                    </div>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                        {types && types.size && types.size > 0
                            ? ' (' + types.size + ')'
                            : null}
                    </Button>
                </div>
            </Popover>
        );
    }
}

TenurePopover.propTypes = {
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    qcStatus: PropTypes.object.isRequired,
    rights: PropTypes.object.isRequired,
    setAppliedTenureFilter: PropTypes.func.isRequired,
    setTenureFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statsAgreements: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    toggleFilter: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    anchorEl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.tenureFilter.value,
        tabValue: state.reports.tabValue.value,

        qcStatus: state.reports.qcStatusFilter.value,
        assets: state.reports.assetsFilter.value,
        partners: state.reports.partnersFilter.value,
        rights: state.reports.rightsFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        toggleFilter: state.reports.toggleFilter,
        statusFilter: state.reports.statusFilter.value,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        statsAgreements: state.reports.stats.agreementsReportStats.value,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
    };
};

const actionToProps = {
    setTenureFilters,
    setAppliedTenureFilter,
    fetchReportAgreements,
};

export default connect(stateToProps, actionToProps)(TenurePopover);
