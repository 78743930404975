import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import variables from '../../../../utils/variables';
import AssetsInfo from './AssetsInfo';
import AudioTracks from './AudioTracks';
import Files from './Files';
import Rights from './Rights';
import Agreements from './Agreements';
import Partners from './Partners';
import Platforms from './Platforms';
import Notes from './Notes';

const Tabs = (props) => {
    const [value, setValue] = useState('asset_info');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const audioTracks = props.assetDetails && props.assetDetails.title && props.assetDetails.title.audio_tracks &&
        props.assetDetails.title.audio_tracks.length;
    const files = props.assetDetails && props.assetDetails.title && props.assetDetails.title.files &&
        props.assetDetails.title.files.length;
    const partners = props.assetDetails && props.assetDetails.partners &&
        props.assetDetails.partners.length;
    const agreements = props.assetDetails && props.assetDetails.agreements &&
        props.assetDetails.agreements.length;
    const platforms = props.assetDetails && props.assetDetails.platforms &&
        props.assetDetails.platforms.length;
    const rights = props.assetDetails && props.assetDetails.rights &&
        props.assetDetails.rights.length;

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext className="tab_context" value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList className="tab_list" onChange={handleChange}>
                        <Tab
                            className={value === 'asset_info' ? 'active_list' : ''}
                            label={variables[props.lang]['asset_info']}
                            value="asset_info" />
                        <Tab
                            className={value === 'audio_tracks' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang]['audio_tracks']}
                                {audioTracks ? ' (' + audioTracks + ')' : null}
                            </>}
                            value="audio_tracks" />
                        <Tab
                            className={value === 'partners' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].partners}
                                {partners ? ' (' + partners + ')' : null}
                            </>}
                            value="partners" />
                        <Tab
                            className={value === 'agreements' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].agreements}
                                {agreements ? ' (' + agreements + ')' : null}
                            </>}
                            value="agreements" />
                        <Tab
                            className={value === 'platforms' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].platforms}
                                {platforms ? ' (' + platforms + ')' : null}
                            </>}
                            value="platforms" />
                        <Tab
                            className={value === 'rights' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].rights}
                                {rights ? ' (' + rights + ')' : null}
                            </>}
                            value="rights" />
                        <Tab
                            className={value === 'files' ? 'active_list' : ''}
                            label={<>
                                {variables[props.lang].files}
                                {files ? ' (' + files + ')' : null}
                            </>}
                            value="files" />
                        <Tab
                            className={value === 'notes' ? 'active_list' : ''}
                            label={variables[props.lang].notes}
                            value="notes" />
                    </TabList>
                </Box>
                <TabPanel className="assets_info" value="asset_info">
                    <AssetsInfo />
                </TabPanel>
                <TabPanel className="assets_info" value="audio_tracks">
                    <AudioTracks />
                </TabPanel>
                <TabPanel className="assets_info" value="partners">
                    <Partners />
                </TabPanel>
                <TabPanel className="assets_info" value="agreements">
                    <Agreements />
                </TabPanel>
                <TabPanel className="assets_info" value="platforms">
                    <Platforms />
                </TabPanel>
                <TabPanel className="assets_info" value="rights">
                    <Rights />
                </TabPanel>
                <TabPanel className="assets_info" value="files">
                    <Files />
                </TabPanel>
                <TabPanel className="assets_info" value="notes">
                    <Notes />
                </TabPanel>
                <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
        </Box>
    );
};

Tabs.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Tabs);
