import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

const AgreementInfo = (props) => {
    const partnerData = props.platformDetails && props.platformDetails.platform;
    const fields = [
        variables[props.lang].platform_name,
        variables[props.lang].platform_type,
    ];

    const data = partnerData && partnerData
        ? [
            [partnerData.name],
            [partnerData.type],
        ]
        : null;

    return (
        <div className="info_section">
            {data && data.map((item, index) => {
                return (
                    <div key={index} className="section1">
                        <span>{fields[index]}</span>
                        <p>{item}</p>
                        {item &&
                            <CopyToClipboard
                                text={item}>
                                <div className="copy_icon">
                                    <Icon className="copy" icon="copy" />
                                    {variables[props.lang].copy}
                                </div>
                            </CopyToClipboard>}
                    </div>
                );
            })}
        </div>
    );
};

AgreementInfo.propTypes = {
    lang: PropTypes.string.isRequired,
    platformDetails: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        platformDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AgreementInfo);
