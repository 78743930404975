import Axios from 'axios';
import {
    ALL_AGREEMENTS_FILTER_SET,
    ALL_ASSETS_FILTER_SET,
    ALL_END_DATE_FILTER_SET,
    ALL_EXPORT_DATES_SET,
    ALL_FILTER_VALUE_SET,
    ALL_PARTNERS_FILTER_SET,
    ALL_PLATFORMS_FILTER_SET,
    ALL_PRIORITY_FILTER_SET,
    ALL_PROJECTS_FILTER_SET,
    ALL_QC_STATUS_FILTER_SET,
    ALL_RIGHTS_FILTER_SET,
    ALL_SEARCH_FILTER_SET,
    ALL_START_DATE_FILTER_SET,
    ALL_TAB_VALUE_SET,
    DOWNLOAD_ALL_FILES_ERROR,
    DOWNLOAD_ALL_FILES_IN_PROGRESS,
    DOWNLOAD_ALL_FILES_SUCCESS,
    FETCH_ALL_AGREEMENTS_ERROR,
    FETCH_ALL_AGREEMENTS_IN_PROGRESS,
    FETCH_ALL_AGREEMENTS_SUCCESS,
    FETCH_ALL_ASSETS_ERROR,
    FETCH_ALL_ASSETS_IN_PROGRESS,
    FETCH_ALL_ASSETS_SUCCESS,
    FETCH_ALL_PARTNERS_ERROR,
    FETCH_ALL_PARTNERS_IN_PROGRESS,
    FETCH_ALL_PARTNERS_SUCCESS,
    FETCH_ALL_PLATFORMS_ERROR,
    FETCH_ALL_PLATFORMS_IN_PROGRESS,
    FETCH_ALL_PLATFORMS_SUCCESS,
    FETCH_ALL_PROJECTS_ERROR,
    FETCH_ALL_PROJECTS_IN_PROGRESS,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_RIGHTS_ERROR,
    FETCH_ALL_RIGHTS_IN_PROGRESS,
    FETCH_ALL_RIGHTS_SUCCESS,
} from '../../constants/allTabs';
import {
    urlAllAgreements,
    urlAllAssets,
    urlAllPartners,
    urlAllPlatforms,
    urlAllProjects,
    urlAllRights,
    urlDownloadAllFiles,
} from '../../constants/allTabs/url';

export const setExportsDate = (start, end) => {
    return {
        type: ALL_EXPORT_DATES_SET,
        start,
        end,
    };
};

export const setSearchFilter = (value) => {
    return {
        type: ALL_SEARCH_FILTER_SET,
        value,
    };
};

export const setTabValue = (value) => {
    return {
        type: ALL_TAB_VALUE_SET,
        value,
    };
};

export const setFilterValue = (value) => {
    return {
        type: ALL_FILTER_VALUE_SET,
        value,
    };
};

export const setAssetsFilter = (value) => {
    return {
        type: ALL_ASSETS_FILTER_SET,
        value,
    };
};

export const setPartnerFilters = (value) => {
    return {
        type: ALL_PARTNERS_FILTER_SET,
        value,
    };
};

export const setAgreementFilters = (value) => {
    return {
        type: ALL_AGREEMENTS_FILTER_SET,
        value,
    };
};

export const setPlatformFilters = (value) => {
    return {
        type: ALL_PLATFORMS_FILTER_SET,
        value,
    };
};

export const setRightsFilters = (value) => {
    return {
        type: ALL_RIGHTS_FILTER_SET,
        value,
    };
};

export const setProjectsFilters = (value) => {
    return {
        type: ALL_PROJECTS_FILTER_SET,
        value,
    };
};

export const setQCStatusFilters = (value) => {
    return {
        type: ALL_QC_STATUS_FILTER_SET,
        value,
    };
};

export const setStartDateFilters = (value) => {
    return {
        type: ALL_START_DATE_FILTER_SET,
        value,
    };
};

export const setEndDateFilters = (value) => {
    return {
        type: ALL_END_DATE_FILTER_SET,
        value,
    };
};

export const setPriorityFilters = (value) => {
    return {
        type: ALL_PRIORITY_FILTER_SET,
        value,
    };
};

const fetchAllAgreementsInProgress = () => {
    return {
        type: FETCH_ALL_AGREEMENTS_IN_PROGRESS,
    };
};

const fetchAllAgreementsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_AGREEMENTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllAgreementsError = (message) => {
    return {
        type: FETCH_ALL_AGREEMENTS_ERROR,
        message,
    };
};

export const fetchAllAgreements = (skip, limit, sortBy, order, searchKey, assets, partners, rights, qcStatus, startDate, endDate) => (dispatch) => {
    dispatch(fetchAllAgreementsInProgress());

    const url = urlAllAgreements(skip, limit, sortBy, order, searchKey, assets, partners, rights, qcStatus, startDate, endDate);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllAgreementsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllAgreementsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllPartnersInProgress = () => {
    return {
        type: FETCH_ALL_PARTNERS_IN_PROGRESS,
    };
};

const fetchAllPartnersSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_PARTNERS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllPartnersError = (message) => {
    return {
        type: FETCH_ALL_PARTNERS_ERROR,
        message,
    };
};

export const fetchAllPartners = (skip, limit, sortBy, order, searchKey, assets, agreements, rights, qcStatus) => (dispatch) => {
    dispatch(fetchAllPartnersInProgress());

    const url = urlAllPartners(skip, limit, sortBy, order, searchKey, assets, agreements, rights, qcStatus);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllPartnersSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllPartnersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllPlatformsInProgress = () => {
    return {
        type: FETCH_ALL_PLATFORMS_IN_PROGRESS,
    };
};

const fetchAllPlatformsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_PLATFORMS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllPlatformsError = (message) => {
    return {
        type: FETCH_ALL_PLATFORMS_ERROR,
        message,
    };
};

export const fetchAllPlatforms = (skip, limit, sortBy, order, searchKey, qcStatus) => (dispatch) => {
    dispatch(fetchAllPlatformsInProgress());

    const url = urlAllPlatforms(skip, limit, sortBy, order, searchKey, qcStatus);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllPlatformsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllPlatformsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllProjectsInProgress = () => {
    return {
        type: FETCH_ALL_PROJECTS_IN_PROGRESS,
    };
};

const fetchAllProjectsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_PROJECTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllProjectsError = (message) => {
    return {
        type: FETCH_ALL_PROJECTS_ERROR,
        message,
    };
};

export const fetchAllProjects = (skip, limit, sortBy, order, searchKey, qcStatus, priority) => (dispatch) => {
    dispatch(fetchAllProjectsInProgress());

    const url = urlAllProjects(skip, limit, sortBy, order, searchKey, qcStatus, priority);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllProjectsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllProjectsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllAssetsInProgress = () => {
    return {
        type: FETCH_ALL_ASSETS_IN_PROGRESS,
    };
};

const fetchAllAssetsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_ASSETS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllAssetsError = (message) => {
    return {
        type: FETCH_ALL_ASSETS_ERROR,
        message,
    };
};

export const fetchAllAssets = (skip, limit, sortBy, order, searchKey, agreements, partners, rights, qcStatus) => (dispatch) => {
    dispatch(fetchAllAssetsInProgress());

    const url = urlAllAssets(skip, limit, sortBy, order, searchKey, agreements, partners, rights, qcStatus);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllAssetsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllAssetsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchAllRightsInProgress = () => {
    return {
        type: FETCH_ALL_RIGHTS_IN_PROGRESS,
    };
};

const fetchAllRightsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_ALL_RIGHTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchAllRightsError = (message) => {
    return {
        type: FETCH_ALL_RIGHTS_ERROR,
        message,
    };
};

export const fetchAllRights = (skip, limit, sortBy, order, searchKey, assets, agreements, partners, qcStatus) => (dispatch) => {
    dispatch(fetchAllRightsInProgress());

    const url = urlAllRights(skip, limit, sortBy, order, searchKey, assets, agreements, partners, qcStatus);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchAllRightsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchAllRightsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

// Download List
const downloadAllFilesInProgress = () => {
    return {
        type: DOWNLOAD_ALL_FILES_IN_PROGRESS,
    };
};

const downloadAllFilesSuccess = (value) => {
    return {
        type: DOWNLOAD_ALL_FILES_SUCCESS,
        value,
    };
};

const downloadAllFilesError = (message) => {
    return {
        type: DOWNLOAD_ALL_FILES_ERROR,
        message,
    };
};

export const downloadAllFiles = (format, from, assets, agreements, partners, rights, qcStatus, priority, startDate, endDate, cb) => (dispatch) => {
    dispatch(downloadAllFilesInProgress());

    const url = from === 'agreements'
        ? urlDownloadAllFiles(format, 'all-agreements', assets, null, partners, rights,
            qcStatus, null, startDate, endDate)
        : from === 'platforms'
            ? urlDownloadAllFiles(format, 'all-platforms', null, null, null, null,
                qcStatus, null, startDate, endDate)
            : from === 'projects'
                ? urlDownloadAllFiles(format, 'all-projects', null, null, null, null,
                    qcStatus, priority, startDate, endDate)
                : from === 'assets'
                    ? urlDownloadAllFiles(format, 'all-assets', null, agreements, partners, rights, qcStatus,
                        null, startDate, endDate)
                    : from === 'partners'
                        ? urlDownloadAllFiles(format, 'all-partners', assets, agreements, null, rights,
                            qcStatus, null, startDate, endDate)
                        : from === 'rights'
                            ? urlDownloadAllFiles(format, 'all-rights', assets, agreements, partners, null,
                                qcStatus, null, startDate, endDate)
                            : '';
    Axios.get(url, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(downloadAllFilesSuccess(res.data));
            if (cb) {
                cb(res.data);
            }
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(downloadAllFilesError(errorMessage.message));
            if (cb) {
                cb(null);
            }
        });
};
