import { combineReducers } from 'redux';
import {
    LOGIN_EMAIL_SET,
    LOGIN_PASSWORD_SET,
    LOGIN_USER_ERROR,
    LOGIN_USER_IN_PROGRESS,
    LOGIN_USER_SUCCESS,
} from '../constants/login';

const email = (state = {
    value: '',
    valid: true,
}, action) => {
    if (action.type === LOGIN_EMAIL_SET) {
        return {
            value: action.value,
            valid: action.valid,
        };
    }

    return state;
};

const password = (state = '', action) => {
    if (action.type === LOGIN_PASSWORD_SET) {
        return action.value;
    }

    return state;
};

const inProgress = (state = false, action) => {
    switch (action.type) {
    case LOGIN_USER_IN_PROGRESS:
        return true;
    case LOGIN_USER_SUCCESS:
    case LOGIN_USER_ERROR:
        return false;
    default:
        return state;
    }
};

const authenticate = (state = false, action) => {
    if (action.type === LOGIN_USER_SUCCESS) {
        return true;
    }

    return state;
};

const userLogin = (state = {
    result: {},
}, action) => {
    if (action.type === LOGIN_USER_SUCCESS) {
        return {
            ...state,
            result: action.result,
        };
    }

    return state;
};

export default combineReducers({
    email,
    password,
    authenticate,
    inProgress,
    userLogin,
});
