import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { LOGIN_USER_ERROR } from '../constants/login';
import { ACCESS_TOKEN_FETCH_ERROR } from '../constants/accessToken';
import { FETCH_PROFILE_DETAILS_ERROR, FETCH_PROFILE_IMAGE_ERROR } from '../constants/profile';
import {
    DOWNLOAD_REPORT_FILE_ERROR,
    FETCH_REPORT_AGREEMENTS_ERROR,
    FETCH_REPORT_ASSETS_ERROR,
    FETCH_REPORT_PARTNERS_ERROR, FETCH_REPORT_PLATFORMS_ERROR, FETCH_REPORT_PROJECTS_ERROR, FETCH_REPORT_RIGHTS_ERROR,
} from '../constants/reports';
import {
    FETCH_ALL_AGREEMENTS_ERROR, FETCH_ALL_ASSETS_ERROR,
    FETCH_ALL_PARTNERS_ERROR,
    FETCH_ALL_PLATFORMS_ERROR,
    FETCH_ALL_PROJECTS_ERROR,
    FETCH_ALL_RIGHTS_ERROR,
} from '../constants/allTabs';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case DOWNLOAD_REPORT_FILE_ERROR:
    case FETCH_ALL_PROJECTS_ERROR:
    case FETCH_ALL_RIGHTS_ERROR:
    case FETCH_ALL_PLATFORMS_ERROR:
    case FETCH_ALL_PARTNERS_ERROR:
    case FETCH_ALL_AGREEMENTS_ERROR:
    case FETCH_ALL_ASSETS_ERROR:
    case FETCH_REPORT_ASSETS_ERROR:
    case FETCH_REPORT_AGREEMENTS_ERROR:
    case FETCH_REPORT_PARTNERS_ERROR:
    case FETCH_REPORT_PLATFORMS_ERROR:
    case FETCH_REPORT_RIGHTS_ERROR:
    case FETCH_REPORT_PROJECTS_ERROR:
    case FETCH_PROFILE_IMAGE_ERROR:
    case FETCH_PROFILE_DETAILS_ERROR:
    case LOGIN_USER_ERROR:
    case ACCESS_TOKEN_FETCH_ERROR:
    case MESSAGE_SHOW:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
