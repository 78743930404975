const variables = {
    en: {
        search: 'Search',
        reports: 'Reports',
        assets: 'Assets',
        partners: 'Partners',
        agreements: 'Agreements',
        platforms: 'Platforms',
        rights: 'Rights',
        projects: 'Projects',
        selected_range: 'Selected range',
        export: 'Export',
        filters: 'Filters',
        added_date: 'Added Date',
        last_updated: 'Last Updated',
        per_completion: '% of completion',
        total_fields_updates: 'Total Fields Updated',
        actions: 'Actions',
        something_went_wrong: 'Something went wrong',
        qc_status: 'QC Status',
        start_date: 'Start Date',
        end_date: 'End Date',
        email_address_error: 'Please Enter Valid Email Address',
        apply_filter: 'Apply Filter',
        priority: 'Priority',
        status: 'Status',
        copy: 'Copy',
        filter_type: 'Filter Type',
        by_toggle: 'By Toggle',
        agreement_types: 'Agreement Types',
        right_groups: 'Right Groups',
        grades: 'Grades',

        assets_overview: 'Assets Overview',
        total_assets_created: 'Total Assets Created',
        total_assets_updated: 'Total Assets Updated',
        total_qc_assets: 'Total QC Assets',
        completion_for_all_assets: '% of Completion for All Assets',
        updated_assets: 'Updated Assets',
        feature_film_name: 'Feature Film Name',

        partners_overview: 'Partners Overview',
        total_partners_created: 'Total Partners Created',
        total_partners_updated: 'Total Partners Updated',
        total_partners_qc: 'Total Partners For which QC has been done',
        per_completion_all_partners: 'Percentage of Completion for All Partners',
        updated_partners: 'Updated Partners',

        agreements_overview: 'Agreements Overview',
        total_agreements_created: 'Total Agreements Created',
        total_agreements_updated: 'Total Agreements Updated',
        total_agreements_qc: 'Total Agreements For which QC has been done',
        per_completion_all_agreements: 'Percentage of Completion for All Agreements',
        updated_agreements: 'Updated Agreements',

        platforms_overview: 'Platforms Overview',
        total_platforms_created: 'Total Platforms Created',
        total_platforms_updated: 'Total Platforms Updated',
        total_platforms_qc: 'Total Platforms For which QC has been done',
        per_completion_all_platforms: 'Percentage of Completion for All Platforms',
        updated_platforms: 'Updated Platforms',

        rights_overview: 'Rights Overview',
        total_rights_created: 'Total Rights Created',
        total_rights_updated: 'Total Rights Updated',
        total_rights_qc: 'Total Rights For which QC has been done',
        per_completion_all_rights: 'Percentage of Completion for All Rights',
        updated_rights: 'Updated Rights',

        projects_overview: 'Projects Overview',
        total_projects_created: 'Total Projects Created',
        total_projects_updated: 'Total Projects Updated',
        total_projects_qc: 'Total Projects For which QC has been done',
        per_completion_all_projects: 'Percentage of Completion for All Projects',
        updated_projects: 'Updated Projects',

        select_by_status: 'Select By Status',
        by_group: 'By Group',
        by_type: 'By Type',
        by_quality_check_status: 'By Quality Check Status',
        by_status: 'By Status',
        by_qc_status: 'By QC Status',
        by_rights: 'By Rights',
        by_partners: 'By Partners',
        by_assets: 'By Assets',
        by_agreements: 'By Agreements',
        by_priority: 'By Priority',

        sign_in_your_account: 'SignIn to your account',

        added_today: 'Added Today',
        active_assets: 'Active Assets',
        total_assets: 'Total Assets',
        assets_added_this_month: 'Assets Added this Month',
        assets_without_partners: 'Assets Without Partners',
        assets_without_agreements: 'Assets Without Agreements',

        total_partners: 'Total Partners',
        partners_without_agreements: 'Partners Without Agreements',
        partners_without_assets: 'Partners Without Assets',
        partners_with_agreements: 'Partners With Agreements',

        total_in_record: 'Total in Record',
        active_agreements: 'Active Agreements',
        agreements_added_this_month: 'Agreements Added This Month',
        agreements_without_contract_file: 'Agreements Without Contract File',
        agreements_expiring_next_month: 'Agreements Expiring Next Month',

        active_platforms: 'Active Platform',
        total_platforms: 'Total Platforms',
        platforms_without_agreements: 'Platforms Without Agreements',
        platforms_with_agreements: 'Platforms With Agreements',
        total_assets_distributed: 'Total Assets Distributed to Platforms',

        total_rights: 'Total Rights',
        rights_with_assets: 'Rights with assets',
        rights_without_assets: 'Rights without assets',

        total_projects_added_today: 'Total Projects Added Today',
        total_projects_assigned_me: 'Total Active Projects Assigned to me',
        my_projects_due_today: 'My Projects Due Today',
        projects_due_tomorrow: 'Projects Due Tomorrow',

        last_updated_on: 'Last Updated on',
        asset_completion: 'Asset Completion',
        asset_info: 'Asset Info',
        audio_tracks: 'Audio Tracks',
        files: 'Files',
        notes: 'Notes',

        mmm_meta_data: 'MMM Meta Data',
        mmm_grade: 'MMM Grade',
        feature_film_id: 'Feature Film ID',
        custom_id: 'Custom ID',
        distributors: 'Distributors',
        right_group: 'Right Group',
        video_on_demand: 'Video On Demand',
        tags: 'Tags',
        type: 'Type',
        languages: 'Languages',
        resolution: 'Resolution',
        current_library: 'Current or Library',
        source_types: 'Source Types',
        custom_source_types: 'Custom Source Types',
        sound: 'Sound',

        feature_film_meta_data: 'Feature Film Meta Data',
        release_date: 'Release Date',
        release_year: 'Release Year',
        duration: 'Duration (Hour:Min:Sec)',
        no_reels: 'No. of Reels',
        imdb_uri: 'IMDB Uri',
        wiki_link: 'Wiki Link',
        additional_links: 'Additional Links',
        feature_film_display_name: 'Feature Film Display Name',
        banners: 'Banners',
        genre: 'Genre',
        music_label: 'Music Label',
        appreciation: 'Appreciation',
        release_status: 'Release Status',
        synopsis: 'Synopsis',
        highlights_movie: 'Highlights of Movie',
        production_type: 'Production Type',

        people_meta_data: 'People Meta Data',
        directors: 'Director(s)',
        producers: 'Producer(s)',
        lead_cast: 'Lead Cast',
        other_cast: 'Other Cast',
        story_writer: 'Story Writer',
        screenplay: 'Screenplay',
        lyrics: 'Lyrics',
        dialogues: 'Dialogues',
        playback_singers: 'Playback Singer(s)',
        music_directors: 'Music Director(s)',
        background_score: 'Background Score',
        sound_engineer: 'Sound Engineer',

        supporting_crew: 'Supporting Crew',
        editor: 'Editor',
        cinematography: 'Cinematography',
        dance_choreography: 'Dance Choreography',
        art_director: 'Art Director',
        costumer: 'Costumer',
        publicity: 'Publicity',
        action_choreography: 'Action Choreography',
        production_designer: 'Production Designer',

        track_name: 'Track Name',
        track_type: 'Track Type',
        published_platforms: 'Published Platforms',
        file_format: 'File Format',
        singers: 'Singer(s)',

        // Agreement Overview
        agreement_info: 'Agreement Info',
        agreement_id: 'Agreement ID',
        agreement_name: 'Agreement Name',
        agreement_category: 'Agreement Category',
        agreement_type: 'Agreement Type',
        tenure: 'Tenure',
        agreement_date: 'Agreement Date',
        assignors: 'Assignors',
        assignees: 'Assignees',
        additional_info: 'Additional Info',
        rights_group: 'Rights Group',

        // Partners Overview
        partner_name: 'Partner Name',
        partner_id: 'Partner ID',
        partner_type: 'Partner Type',
        platform: 'Platform',
        payment_method: 'Payment Method',
        bank_name: 'Bank Name',
        bank_account_number: 'Bank Account Number',
        bank_branch: 'Bank Branch',
        bank_ifsc: 'Bank IFSC',
        bank_swift_code: 'Bank SWIFT Code',
        paypal_id: 'Paypal ID',
        registered_company_name: 'Registered Company Name',
        signing_authority: 'Signing Authority',
        pan_number: 'PAN Number',
        gstin_number: 'GSTIN Number',
        tin_number: 'TIN Number',
        partner_info: 'Partner Info',
        financial_info: 'Financial Info',
        contacts: 'Contacts',

        //    Rights Overview
        rights_details: 'Rights Details',
        remake: 'Remake',
        dubbed: 'Dubbed',
        rights_in: 'Rights IN',
        rights_out: 'Rights OUT',
        remaining_rights: 'Remaining Rights',
        future_rights: 'Future Rights',
        right_info: 'Right Info',
        right_id: 'Right ID',
        right_name: 'Right Name',
        delivery_medium: 'Delivery Medium',
        monetization_model: 'Monetization Models',
        right_type: 'Right Type',

        //    Platforms
        platform_name: 'Platform Name',
        platform_type: 'Platform Type',
        platform_info: 'Platform Info',
        platform_id: 'Platform ID',
    },
};

export default variables;
