import { Button, Popover } from '@material-ui/core';
import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import fileDownload from 'js-file-download';
import { showMessage } from '../../actions/snackbar';
import CircularProgress from '../../components/CircularProgress';
import { downloadExportFile } from '../../actions/reports';

const ExportPopover = (props) => {
    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const downloadFile = (format) => {
        props.hidePopover();

        const assetsObj = { ...props.appliedAssets };
        let assets = new Set();
        if (assetsObj[props.tabValue]) {
            assets = new Set(assetsObj[props.tabValue]);
        }

        const agreementsObj = { ...props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[props.tabValue]) {
            agreements = new Set(agreementsObj[props.tabValue]);
        }

        const partnersObj = { ...props.appliedPartners };
        let partners = new Set();
        if (partnersObj[props.tabValue]) {
            partners = new Set(partnersObj[props.tabValue]);
        }

        const qcStatusObj = { ...props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[props.tabValue]) {
            qcStatus = new Set(qcStatusObj[props.tabValue]);
        }
        const rightsObj = { ...props.appliedRights };
        let rights = new Set();
        if (rightsObj[props.tabValue]) {
            rights = new Set(rightsObj[props.tabValue]);
        }

        const typesObj = { ...props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[props.tabValue]) {
            types = new Set(typesObj[props.tabValue]);
        }

        const rightGroupsObj = { ...props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[props.tabValue]);
        }

        const tenureObj = { ...props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[props.tabValue]) {
            tenure = new Set(tenureObj[props.tabValue]);
        }

        const productionTypeObj = { ...props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[props.tabValue]) {
            productionType = new Set(productionTypeObj[props.tabValue]);
        }

        const gradesObj = { ...props.appliedGrades };
        let grades = new Set();
        if (gradesObj[props.tabValue]) {
            grades = new Set(gradesObj[props.tabValue]);
        }

        const startDateObj = { ...props.startDateFilter };
        const startDate = startDateObj[props.tabValue];

        const endDateObj = { ...props.endDateFilter };
        const endDate = endDateObj[props.tabValue];

        props.downloadExportFile(format, props.from, assets, agreements, partners, rights,
            qcStatus, startDate, endDate, props.status, rightGroups, types, tenure, productionType, grades, (cb) => {
                if (cb) {
                    const filename = 'report-' + props.from + '.' + format;
                    fileDownload(cb, filename);
                // } else {
                //     props.showMessage(variables[props.lang]['something_went_wrong'], 'error');
                }
            });
    };

    return (
        <>
            {props.downloadInProgress && <CircularProgress className="full_screen"/>}
            <Popover
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                className="export_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={props.hidePopover}>
                <div className="export_formats">
                    <Button onClick={() => downloadFile('xlsx')}>
                        xlsx
                    </Button>
                    <Button onClick={() => downloadFile('csv')}>
                        csv
                    </Button>
                </div>
            </Popover>
        </>
    );
};

ExportPopover.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,

    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedPriority: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,

    assetsFilter: PropTypes.object.isRequired,
    downloadExportFile: PropTypes.func.isRequired,
    downloadInProgress: PropTypes.bool.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    from: PropTypes.string.isRequired,
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    platformsFilter: PropTypes.object.isRequired,
    priorityFilter: PropTypes.object.isRequired,
    projectsFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    anchorEl: PropTypes.any,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        from: state.reports.tabValue.value,
        tabValue: state.reports.tabValue.value,

        assetsFilter: state.reports.assetsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        agreementsFilter: state.reports.agreementsFilter.value,
        platformsFilter: state.reports.platformsFilter.value,
        rightsFilter: state.reports.rightsFilter.value,
        projectsFilter: state.reports.projectsFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
        priorityFilter: state.reports.priorityFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        status: state.reports.statusFilter.value,

        downloadInProgress: state.reports.downloadExportFiles.inProgress,
        downloadResult: state.reports.downloadExportFiles.value,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    downloadExportFile,
    showMessage,
};

export default withRouter(connect(stateToProps, actionToProps)(ExportPopover));
