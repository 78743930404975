import {
    DOWNLOAD_REPORT_FILE_ERROR,
    DOWNLOAD_REPORT_FILE_IN_PROGRESS,
    DOWNLOAD_REPORT_FILE_SUCCESS,
    FETCH_REPORT_AGREEMENTS_ERROR,
    FETCH_REPORT_AGREEMENTS_IN_PROGRESS,
    FETCH_REPORT_AGREEMENTS_SUCCESS,
    FETCH_REPORT_ASSETS_ERROR,
    FETCH_REPORT_ASSETS_IN_PROGRESS,
    FETCH_REPORT_ASSETS_SUCCESS,
    FETCH_REPORT_PARTNERS_ERROR,
    FETCH_REPORT_PARTNERS_IN_PROGRESS,
    FETCH_REPORT_PARTNERS_SUCCESS,
    FETCH_REPORT_PLATFORMS_ERROR,
    FETCH_REPORT_PLATFORMS_IN_PROGRESS,
    FETCH_REPORT_PLATFORMS_SUCCESS,
    FETCH_REPORT_PROJECTS_ERROR,
    FETCH_REPORT_PROJECTS_IN_PROGRESS,
    FETCH_REPORT_PROJECTS_SUCCESS,
    FETCH_REPORT_RIGHTS_ERROR,
    FETCH_REPORT_RIGHTS_IN_PROGRESS,
    FETCH_REPORT_RIGHTS_SUCCESS, REPORT_AGREEMENT_TYPES_FILTER_SET,
    REPORT_AGREEMENTS_FILTER_SET, REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET,
    REPORT_APPLIED_AGREEMENTS_FILTER_SET,
    REPORT_APPLIED_ASSETS_FILTER_SET, REPORT_APPLIED_GRADES_FILTER_SET,
    REPORT_APPLIED_PARTNERS_FILTER_SET,
    REPORT_APPLIED_PRIORITY_FILTER_SET, REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET,
    REPORT_APPLIED_QC_STATUS_FILTER_SET, REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET,
    REPORT_APPLIED_RIGHTS_FILTER_SET, REPORT_APPLIED_TENURE_FILTER_SET,
    REPORT_ASSETS_FILTER_SET,
    REPORT_END_DATE_FILTER_SET,
    REPORT_EXPORT_DATES_SET,
    REPORT_FILTER_VALUE_SET, REPORT_GRADES_FILTER_SET,
    REPORT_PARTNERS_FILTER_SET,
    REPORT_PLATFORMS_FILTER_SET,
    REPORT_PRIORITY_FILTER_SET, REPORT_PRODUCTION_TYPE_FILTER_SET,
    REPORT_PROJECTS_FILTER_SET,
    REPORT_QC_STATUS_FILTER_SET, REPORT_RIGHT_GROUPS_FILTER_SET,
    REPORT_RIGHTS_FILTER_SET,
    REPORT_SEARCH_FILTER_SET,
    REPORT_START_DATE_FILTER_SET,
    REPORT_STATUS_FILTER_SET,
    REPORT_TAB_VALUE_SET, REPORT_TENURE_FILTER_SET, REPORT_TOGGLE_FILTER_SET,
} from '../../constants/reports';
import {
    URL_REPORT_AGREEMENTS,
    URL_REPORT_ASSETS,
    URL_REPORT_PARTNERS,
    URL_REPORT_PLATFORMS,
    URL_REPORT_PROJECTS,
    URL_REPORT_RIGHTS,
    urlDownloadExport,
} from '../../constants/url';
import Axios from 'axios';

export const setExportsDate = (start, end) => {
    return {
        type: REPORT_EXPORT_DATES_SET,
        start,
        end,
    };
};

export const setSearchFilter = (value) => {
    return {
        type: REPORT_SEARCH_FILTER_SET,
        value,
    };
};

export const setTabValue = (value) => {
    return {
        type: REPORT_TAB_VALUE_SET,
        value,
    };
};

export const setFilterValue = (value) => {
    return {
        type: REPORT_FILTER_VALUE_SET,
        value,
    };
};

export const setAssetsFilter = (value) => {
    return {
        type: REPORT_ASSETS_FILTER_SET,
        value,
    };
};

export const setPartnerFilters = (value) => {
    return {
        type: REPORT_PARTNERS_FILTER_SET,
        value,
    };
};

export const setAgreementFilters = (value) => {
    return {
        type: REPORT_AGREEMENTS_FILTER_SET,
        value,
    };
};

export const setPlatformFilters = (value) => {
    return {
        type: REPORT_PLATFORMS_FILTER_SET,
        value,
    };
};

export const setRightsFilters = (value) => {
    return {
        type: REPORT_RIGHTS_FILTER_SET,
        value,
    };
};

export const setProjectsFilters = (value) => {
    return {
        type: REPORT_PROJECTS_FILTER_SET,
        value,
    };
};

export const setQCStatusFilters = (value) => {
    return {
        type: REPORT_QC_STATUS_FILTER_SET,
        value,
    };
};

export const setStartDateFilters = (value) => {
    return {
        type: REPORT_START_DATE_FILTER_SET,
        value,
    };
};

export const setEndDateFilters = (value) => {
    return {
        type: REPORT_END_DATE_FILTER_SET,
        value,
    };
};

export const setPriorityFilters = (value) => {
    return {
        type: REPORT_PRIORITY_FILTER_SET,
        value,
    };
};

export const setStatusFilters = (value) => {
    return {
        type: REPORT_STATUS_FILTER_SET,
        value,
    };
};

export const setAgreementTypesFilters = (value) => {
    return {
        type: REPORT_AGREEMENT_TYPES_FILTER_SET,
        value,
    };
};

export const setTenureFilters = (value) => {
    return {
        type: REPORT_TENURE_FILTER_SET,
        value,
    };
};

export const setRightGroupsFilters = (value) => {
    return {
        type: REPORT_RIGHT_GROUPS_FILTER_SET,
        value,
    };
};

export const setGradesFilters = (value) => {
    return {
        type: REPORT_GRADES_FILTER_SET,
        value,
    };
};

export const setProductionTypeFilters = (value) => {
    return {
        type: REPORT_PRODUCTION_TYPE_FILTER_SET,
        value,
    };
};

export const setToggleFilters = (value) => {
    return {
        type: REPORT_TOGGLE_FILTER_SET,
        value,
    };
};

export const setAppliedAssetsFilter = (value) => {
    return {
        type: REPORT_APPLIED_ASSETS_FILTER_SET,
        value,
    };
};

export const setAppliedAgreementsFilter = (value) => {
    return {
        type: REPORT_APPLIED_AGREEMENTS_FILTER_SET,
        value,
    };
};

export const setAppliedPartnersFilter = (value) => {
    return {
        type: REPORT_APPLIED_PARTNERS_FILTER_SET,
        value,
    };
};

export const setAppliedRightsFilter = (value) => {
    return {
        type: REPORT_APPLIED_RIGHTS_FILTER_SET,
        value,
    };
};

export const setAppliedPriorityFilter = (value) => {
    return {
        type: REPORT_APPLIED_PRIORITY_FILTER_SET,
        value,
    };
};

export const setAppliedQCStatusFilter = (value) => {
    return {
        type: REPORT_APPLIED_QC_STATUS_FILTER_SET,
        value,
    };
};

export const setAppliedAgreementTypesFilter = (value) => {
    return {
        type: REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET,
        value,
    };
};

export const setAppliedTenureFilter = (value) => {
    return {
        type: REPORT_APPLIED_TENURE_FILTER_SET,
        value,
    };
};

export const setAppliedRightGroupsFilter = (value) => {
    return {
        type: REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET,
        value,
    };
};

export const setAppliedGradesFilter = (value) => {
    return {
        type: REPORT_APPLIED_GRADES_FILTER_SET,
        value,
    };
};

export const setAppliedProductionTypeFilter = (value) => {
    return {
        type: REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET,
        value,
    };
};

const fetchReportAgreementsInProgress = () => {
    return {
        type: FETCH_REPORT_AGREEMENTS_IN_PROGRESS,
    };
};

const fetchReportAgreementsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_AGREEMENTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportAgreementsError = (message) => {
    return {
        type: FETCH_REPORT_AGREEMENTS_ERROR,
        message,
    };
};

export const fetchReportAgreements = (skip, limit, sortBy, order, searchKey, assets, partners, rights, qcStatus, startDate, endDate, status, toggle, types, tenure, rightGroups) => (dispatch) => {
    dispatch(fetchReportAgreementsInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (status && status !== '') {
        data.activeInactiveStatus = status;
    }
    if (types && types.size) {
        const array = [];
        types.forEach((value) => array.push(value));
        data.types = array;
    }
    if (tenure && tenure.size) {
        const array = [];
        tenure.forEach((value) => array.push(value));
        data.tenure = array;
    }
    if (rightGroups && rightGroups.size) {
        const array = [];
        rightGroups.forEach((value) => array.push(value));
        data.withRightGroup = array;
    }
    if (toggle === 'AND') {
        data.isAnd = true;
    } else if (toggle === 'OR') {
        data.isAnd = false;
    } else {
        data.isAnd = true;
    }
    if (assets && assets.size) {
        const array = [];
        assets.forEach((value) => array.push(value));
        data.withAsset = array;
    }
    if (partners && partners.size) {
        const array = [];
        partners.forEach((value) => array.push(value));
        data.withPartners = array;
    }
    if (rights && rights.size) {
        const array = [];
        rights.forEach((value) => array.push(value));
        data.withRights = array;
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }
    if (startDate) {
        data.startDate = startDate;
    }
    if (endDate) {
        data.endDate = endDate;
    }

    Axios.post(URL_REPORT_AGREEMENTS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportAgreementsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportAgreementsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportPartnersInProgress = () => {
    return {
        type: FETCH_REPORT_PARTNERS_IN_PROGRESS,
    };
};

const fetchReportPartnersSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_PARTNERS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportPartnersError = (message) => {
    return {
        type: FETCH_REPORT_PARTNERS_ERROR,
        message,
    };
};

export const fetchReportPartners = (skip, limit, sortBy, order, searchKey, assets, agreements, rights, qcStatus, toggle) => (dispatch) => {
    dispatch(fetchReportPartnersInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (toggle === 'AND') {
        data.isAnd = true;
    } else if (toggle === 'OR') {
        data.isAnd = false;
    } else {
        data.isAnd = true;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (assets && assets.size) {
        const array = [];
        assets.forEach((value) => array.push(value));
        data.withAsset = array;
    }
    if (agreements && agreements.size) {
        const array = [];
        agreements.forEach((value) => array.push(value));
        data.withAgreement = array;
    }
    if (rights && rights.size) {
        const array = [];
        rights.forEach((value) => array.push(value));
        data.withRights = array;
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }

    Axios.post(URL_REPORT_PARTNERS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportPartnersSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportPartnersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportPlatformsInProgress = () => {
    return {
        type: FETCH_REPORT_PLATFORMS_IN_PROGRESS,
    };
};

const fetchReportPlatformsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_PLATFORMS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportPlatformsError = (message) => {
    return {
        type: FETCH_REPORT_PLATFORMS_ERROR,
        message,
    };
};

export const fetchReportPlatforms = (skip, limit, sortBy, order, searchKey, qcStatus) => (dispatch) => {
    dispatch(fetchReportPlatformsInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }

    Axios.post(URL_REPORT_PLATFORMS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportPlatformsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportPlatformsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportProjectsInProgress = () => {
    return {
        type: FETCH_REPORT_PROJECTS_IN_PROGRESS,
    };
};

const fetchReportProjectsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_PROJECTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportProjectsError = (message) => {
    return {
        type: FETCH_REPORT_PROJECTS_ERROR,
        message,
    };
};

export const fetchReportProjects = (skip, limit, sortBy, order, searchKey, qcStatus, priority) => (dispatch) => {
    dispatch(fetchReportProjectsInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }
    if (priority && priority.size) {
        const array = [];
        priority.forEach((value) => array.push(value === 'High' ? 0
            : value === 'Medium' ? 1
                : value === 'Low' ? 2 : 0));
        data.priority = array;
    }

    Axios.post(URL_REPORT_PROJECTS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportProjectsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportProjectsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportAssetsInProgress = () => {
    return {
        type: FETCH_REPORT_ASSETS_IN_PROGRESS,
    };
};

const fetchReportAssetsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_ASSETS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportAssetsError = (message) => {
    return {
        type: FETCH_REPORT_ASSETS_ERROR,
        message,
    };
};

export const fetchReportAssets = (skip, limit, sortBy, order, searchKey, agreements, partners, rights, qcStatus, toggle, grades, productionType) => (dispatch) => {
    dispatch(fetchReportAssetsInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (agreements && agreements.size) {
        const array = [];
        agreements.forEach((value) => array.push(value));
        data.withAgreement = array;
    }
    if (partners && partners.size) {
        const array = [];
        partners.forEach((value) => array.push(value));
        data.withPartners = array;
    }
    if (rights && rights.size) {
        const array = [];
        rights.forEach((value) => array.push(value));
        data.withRights = array;
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }
    if (grades && grades.size) {
        const array = [];
        grades.forEach((value) => array.push(value));
        data.grades = array;
    }
    if (productionType && productionType.size) {
        const array = [];
        productionType.forEach((value) => array.push(value));
        data.productionType = array;
    }
    if (toggle === 'AND') {
        data.isAnd = true;
    } else if (toggle === 'OR') {
        data.isAnd = false;
    } else {
        data.isAnd = true;
    }

    Axios.post(URL_REPORT_ASSETS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportAssetsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportAssetsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportRightsInProgress = () => {
    return {
        type: FETCH_REPORT_RIGHTS_IN_PROGRESS,
    };
};

const fetchReportRightsSuccess = (value, skip, limit, total, sortBy, order, searchKey) => {
    return {
        type: FETCH_REPORT_RIGHTS_SUCCESS,
        value,
        skip,
        limit,
        total,
        sortBy,
        order,
        searchKey,
    };
};

const fetchReportRightsError = (message) => {
    return {
        type: FETCH_REPORT_RIGHTS_ERROR,
        message,
    };
};

export const fetchReportRights = (skip, limit, sortBy, order, searchKey, assets, agreements, partners, qcStatus, toggle) => (dispatch) => {
    dispatch(fetchReportRightsInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }
    if (sortBy && sortBy.length > 0) {
        data.sortBy = sortBy;
        if (order) {
            data.order = order;
        } else {
            data.order = 'desc';
        }
    }
    if (assets && assets.size) {
        const array = [];
        assets.forEach((value) => array.push(value));
        data.withAsset = array;
    }
    if (agreements && agreements.size) {
        const array = [];
        agreements.forEach((value) => array.push(value));
        data.withAgreement = array;
    }
    if (partners && partners.size) {
        const array = [];
        partners.forEach((value) => array.push(value));
        data.withPartners = array;
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }
    if (toggle === 'AND') {
        data.isAnd = true;
    } else if (toggle === 'OR') {
        data.isAnd = false;
    } else {
        data.isAnd = true;
    }

    Axios.post(URL_REPORT_RIGHTS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportRightsSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, sortBy, order, searchKey));
        })
        .catch((error) => {
            dispatch(fetchReportRightsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

// Download List
const downloadExportInProgress = () => {
    return {
        type: DOWNLOAD_REPORT_FILE_IN_PROGRESS,
    };
};

const downloadExportSuccess = (value) => {
    return {
        type: DOWNLOAD_REPORT_FILE_SUCCESS,
        value,
    };
};

const downloadExportError = (message) => {
    return {
        type: DOWNLOAD_REPORT_FILE_ERROR,
        message,
    };
};

export const downloadExportFile = (format, from, assets, agreements, partners, rights, qcStatus, startDate, endDate,
    status, rightGroups, types, tenure, productionType, grades, cb) => (dispatch) => {
    dispatch(downloadExportInProgress());

    const data = {
        exportTo: format,
    };

    if (status && status !== '') {
        data.activeInactiveStatus = status;
    }
    if (assets && assets.size) {
        const array = [];
        assets.forEach((value) => array.push(value));
        data.withAsset = array;
    }
    if (agreements && agreements.size) {
        const array = [];
        agreements.forEach((value) => array.push(value));
        data.withAgreement = array;
    }
    if (partners && partners.size) {
        const array = [];
        partners.forEach((value) => array.push(value));
        data.withPartners = array;
    }
    if (rights && rights.size) {
        const array = [];
        rights.forEach((value) => array.push(value));
        data.withRights = array;
    }
    if (qcStatus && qcStatus.size) {
        const array = [];
        qcStatus.forEach((value) => array.push(value));
        data.qcStatus = array;
    }

    if (grades && grades.size) {
        const array = [];
        grades.forEach((value) => array.push(value));
        data.grades = array;
    }
    if (productionType && productionType.size) {
        const array = [];
        productionType.forEach((value) => array.push(value));
        data.productionType = array;
    }
    if (types && types.size) {
        const array = [];
        types.forEach((value) => array.push(value));
        data.types = array;
    }
    if (tenure && tenure.size) {
        const array = [];
        tenure.forEach((value) => array.push(value));
        data.tenure = array;
    }
    if (rightGroups && rightGroups.size) {
        const array = [];
        rightGroups.forEach((value) => array.push(value));
        data.withRightGroup = array;
    }

    if (startDate) {
        data.startDate = startDate;
    }
    if (endDate) {
        data.endDate = endDate;
    }

    const url = from === 'agreements'
        ? urlDownloadExport('all-agreements')
        : from === 'platforms'
            ? urlDownloadExport('all-platforms')
            : from === 'projects'
                ? urlDownloadExport('all-projects')
                : from === 'assets'
                    ? urlDownloadExport('all-assets')
                    : from === 'partners'
                        ? urlDownloadExport('all-partners')
                        : from === 'rights'
                            ? urlDownloadExport('all-rights')
                            : '';
    Axios.post(url, data, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(downloadExportSuccess(res.data));
            if (cb) {
                cb(res.data);
            }
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(downloadExportError(errorMessage.message));
            if (cb) {
                cb(null);
            }
        });
};
