import * as PropTypes from 'prop-types';
import React from 'react';
import DotsLoading from '../../components/DotsLoading';

const Card = (props) => {
    return (
        <div className="overview_card">
            <h2>{props.head}</h2>
            {props.inProgress
                ? <DotsLoading/>
                : props.value
                    ? props.percentage
                        ? <p>{(props.value).toFixed(1)}</p>
                        : <p>{props.value}</p>
                    : <p>0</p>}
            {props.content
                ? <span>{props.content} {' - '} {props.content_count}</span>
                : null}
        </div>
    );
};

Card.propTypes = {
    head: PropTypes.string.isRequired,
    inProgress: PropTypes.bool.isRequired,
    content: PropTypes.string,
    content_count: PropTypes.number,
    percentage: PropTypes.bool,
    value: PropTypes.number,
};

export default Card;
