export const REPORT_ASSETS_LIST_FETCH_IN_PROGRESS = 'REPORT_ASSETS_LIST_FETCH_IN_PROGRESS';
export const REPORT_ASSETS_LIST_FETCH_SUCCESS = 'REPORT_ASSETS_LIST_FETCH_SUCCESS';
export const REPORT_ASSETS_LIST_FETCH_ERROR = 'REPORT_ASSETS_LIST_FETCH_ERROR';

export const REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS = 'REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS';
export const REPORT_PARTNERS_LIST_FETCH_SUCCESS = 'REPORT_PARTNERS_LIST_FETCH_SUCCESS';
export const REPORT_PARTNERS_LIST_FETCH_ERROR = 'REPORT_PARTNERS_LIST_FETCH_ERROR';

export const REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS = 'REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS';
export const REPORT_RIGHTS_LIST_FETCH_SUCCESS = 'REPORT_RIGHTS_LIST_FETCH_SUCCESS';
export const REPORT_RIGHTS_LIST_FETCH_ERROR = 'REPORT_RIGHTS_LIST_FETCH_ERROR';

export const REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS = 'REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS';
export const REPORT_AGREEMENTS_LIST_FETCH_SUCCESS = 'REPORT_AGREEMENTS_LIST_FETCH_SUCCESS';
export const REPORT_AGREEMENTS_LIST_FETCH_ERROR = 'REPORT_AGREEMENTS_LIST_FETCH_ERROR';

export const REPORT_GRADES_LIST_FETCH_IN_PROGRESS = 'REPORT_GRADES_LIST_FETCH_IN_PROGRESS';
export const REPORT_GRADES_LIST_FETCH_SUCCESS = 'REPORT_GRADES_LIST_FETCH_SUCCESS';
export const REPORT_GRADES_LIST_FETCH_ERROR = 'REPORT_GRADES_LIST_FETCH_ERROR';
