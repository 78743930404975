import React from 'react';
import DataTable from '../../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Tooltip } from '@mui/material';
import moment from 'moment';

const VendorsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        responsive: 'standard',
        serverSide: false,
        pagination: true,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
    };

    const columns = [{
        name: 'vendor',
        label: 'Vendor',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value.partner_ids && value.partner_ids.length > 0 &&
                            value.partner_ids.map((par) => par.official_name).toString()
                                .replace(/,/g, ', ')}
                    </div>
                );
            },
        },
    }, {
        name: 'vendor_type',
        label: 'Vendor Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.type}</div>
                );
            },
        },
    }, {
        name: 'remake',
        label: 'Remake',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.remake === true ? 'Yes' : 'No'}</div>
                );
            },
        },
    }, {
        name: 'dubbed',
        label: 'Dubbed',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.dub === true ? 'Yes' : 'No'}</div>
                );
            },
        },
    }, {
        name: 'exclusivity',
        label: 'Exclusivity',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.exclusivity === true ? 'Exclusive'
                        : value.exclusivity === false ? 'Non-Exclusive' : ''}</div>
                );
            },
        },
    }, {
        name: 'start_date',
        label: 'Start Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value.start_date
                        ? moment(value.start_date).format('DD-MMM-YYYY')
                        : '-'}</div>
                );
            },
        },
    }, {
        name: 'end_date',
        label: 'End Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{ value.end_date
                        ? moment(value.end_date).format('DD-MMM-YYYY')
                        : '-'}</div>
                );
            },
        },
    }, {
        name: 'rights',
        label: 'Rights',
        options: {
            customBodyRender: function (value) {
                // Rights
                const holdRights = [];
                const rights = [];
                // eslint-disable-next-line no-unused-vars
                const ar = value.holdbacks && value.holdbacks.length > 0 &&
                    value.holdbacks.map((hold) => {
                        if (hold.rights) {
                            holdRights.push(...hold.rights);
                        }
                    });
                const uniqueRights = [...holdRights.reduce((map, obj) => map.set(obj._id, obj), new Map()).values()];
                // eslint-disable-next-line no-unused-vars
                const aar = value.rights && value.rights.length > 0 &&
                    value.rights.map((l) => {
                        // eslint-disable-next-line no-empty
                        if (uniqueRights.filter((u) => u._id === l._id) && uniqueRights.filter((u) => u._id === l._id).length > 0) {

                        } else {
                            rights.push(l);
                        }
                    });

                return (
                    <div className="clm_value">
                        <Tooltip
                            title={rights && rights.length > 0 &&
                                rights.map((lan) => lan.name).toString()
                                    .replace(/,/g, ', ')
                            }>
                            <span
                                className="right_value"> {rights && rights.length}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    }, {
        name: 'languages',
        label: 'Languages',
        options: {
            customBodyRender: function (value) {
                // lang
                const holdLang = [];
                const lang = [];
                // eslint-disable-next-line no-unused-vars
                const languages = value.holdbacks && value.holdbacks.length > 0 &&
                    value.holdbacks.map((hold) => {
                        if (hold.languages) {
                            holdLang.push(...hold.languages);
                        }
                    });
                const uniqueLang = [...holdLang.reduce((map, obj) => map.set(obj._id, obj), new Map()).values()];
                // eslint-disable-next-line no-unused-vars
                const aa = value.permitted_languages && value.permitted_languages.length > 0 &&
                    value.permitted_languages.map((l) => {
                        // eslint-disable-next-line no-empty
                        if (uniqueLang.filter((u) => u._id === l._id) && uniqueLang.filter((u) => u._id === l._id).length > 0) {

                        } else {
                            lang.push(l);
                        }
                    });
                return (
                    <div className="clm_value">
                        <Tooltip
                            title={lang && lang.length > 0 &&
                                lang.map((lan) => lan.name).toString()
                                    .replace(/,/g, ', ')
                            }>
                            <span>{lang && lang.length}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    }, {
        name: 'territories',
        label: 'Territories',
        options: {
            customBodyRender: function (value) {
                // Countries
                const holdcoun = [];
                const country = [];
                // eslint-disable-next-line no-unused-vars
                const ac = value.holdbacks && value.holdbacks.length > 0 &&
                    value.holdbacks.map((hold) => {
                        if (hold.countries) {
                            holdcoun.push(...hold.countries);
                        }
                    });
                const uniquecoun = [...holdcoun.reduce((map, obj) => map.set(obj, obj), new Map()).values()];
                // eslint-disable-next-line no-unused-vars
                const aac = value.countries && value.countries.length > 0 &&
                    value.countries.map((l) => {
                        // eslint-disable-next-line no-empty
                        if (uniquecoun.filter((u) => u === l) && uniquecoun.filter((u) => u === l).length > 0) {

                        } else {
                            country.push(l);
                        }
                    });
                return (
                    <div className="clm_value">
                        <Tooltip
                            title={country && country.length > 0 &&
                                country.map((lan) => lan).toString()
                                    .replace(/,/g, ', ')
                            }>
                            <span
                                className="coun_value"> {country && country.length}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    }, {
        name: 'delivery_medium',
        label: 'Delivery Medium',
        options: {
            customBodyRender: function (value) {
                // DM
                const holddm = [];
                const dm = [];
                // eslint-disable-next-line no-unused-vars
                const ad = value.holdbacks && value.holdbacks.length > 0 &&
                    value.holdbacks.map((hold) => {
                        if (hold.delivery_mediums) {
                            holddm.push(...hold.delivery_mediums);
                        }
                    });
                const uniquedm = [...holddm.reduce((map, obj) => map.set(obj._id, obj), new Map()).values()];
                // eslint-disable-next-line no-unused-vars
                const aad = value.delivery_mediums && value.delivery_mediums.length > 0 &&
                    value.delivery_mediums.map((l) => {
                        // eslint-disable-next-line no-empty
                        if (uniquedm.filter((u) => u._id === l._id) && uniquedm.filter((u) => u._id === l._id).length > 0) {

                        } else {
                            dm.push(l);
                        }
                    });
                return (
                    <div className="clm_value">
                        <Tooltip
                            title={dm && dm.length > 0 &&
                                dm.map((lan) => lan.name).toString()
                                    .replace(/,/g, ', ')
                            }>
                            <span
                                className="dm_value"> {dm && dm.length}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    }, {
        name: 'monetization_model',
        label: 'Monetization Model',
        options: {
            customBodyRender: function (value) {
                // MM
                const holdmm = [];
                const mm = [];
                // eslint-disable-next-line no-unused-vars
                const am = value.holdbacks && value.holdbacks.length > 0 &&
                    value.holdbacks.map((hold) => {
                        if (hold.monetization_models) {
                            holdmm.push(...hold.monetization_models);
                        }
                    });
                const uniquemm = [...holdmm.reduce((map, obj) => map.set(obj._id, obj), new Map()).values()];
                // eslint-disable-next-line no-unused-vars
                const aam = value.monetization_models && value.monetization_models.length > 0 &&
                    value.monetization_models.map((l) => {
                        // eslint-disable-next-line no-empty
                        if (uniquemm.filter((u) => u._id === l._id) && uniquemm.filter((u) => u._id === l._id).length > 0) {

                        } else {
                            mm.push(l);
                        }
                    });
                return (
                    <div className="clm_value">
                        <Tooltip
                            title={mm && mm.length > 0 &&
                                mm.map((lan) => lan.name).toString()
                                    .replace(/,/g, ', ')
                            }>
                            <span className="mm_value"> {mm && mm.length}</span>
                        </Tooltip>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item,
                item,
                item,
                item,
                item,
                item,
                item,
                item,
                item, item, item, item]) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={tableData}
                name="Vendor Details"
                options={options}/>
        </>
    );
};

VendorsTable.propTypes = {
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default withRouter(connect(stateToProps)(VendorsTable));
