export const REPORT_TAB_VALUE_SET = 'REPORT_TAB_VALUE_SET';
export const REPORT_FILTER_VALUE_SET = 'REPORT_FILTER_VALUE_SET';

export const REPORT_SEARCH_FILTER_SET = 'REPORT_SEARCH_FILTER_SET';
export const REPORT_ASSETS_FILTER_SET = 'REPORT_ASSETS_FILTER_SET';
export const REPORT_PARTNERS_FILTER_SET = 'REPORT_PARTNERS_FILTER_SET';
export const REPORT_AGREEMENTS_FILTER_SET = 'REPORT_AGREEMENTS_FILTER_SET';
export const REPORT_PLATFORMS_FILTER_SET = 'REPORT_PLATFORMS_FILTER_SET';
export const REPORT_RIGHTS_FILTER_SET = 'REPORT_RIGHTS_FILTER_SET';
export const REPORT_PROJECTS_FILTER_SET = 'REPORT_PROJECTS_FILTER_SET';
export const REPORT_QC_STATUS_FILTER_SET = 'REPORT_QC_STATUS_FILTER_SET';
export const REPORT_START_DATE_FILTER_SET = 'REPORT_START_DATE_FILTER_SET';
export const REPORT_END_DATE_FILTER_SET = 'REPORT_END_DATE_FILTER_SET';
export const REPORT_PRIORITY_FILTER_SET = 'REPORT_PRIORITY_FILTER_SET';
export const REPORT_EXPORT_DATES_SET = 'REPORT_EXPORT_DATES_SET';
export const REPORT_STATUS_FILTER_SET = 'REPORT_STATUS_FILTER_SET';
export const REPORT_TOGGLE_FILTER_SET = 'REPORT_TOGGLE_FILTER_SET';
export const REPORT_AGREEMENT_TYPES_FILTER_SET = 'REPORT_AGREEMENT_TYPES_FILTER_SET';
export const REPORT_TENURE_FILTER_SET = 'REPORT_TENURE_FILTER_SET';
export const REPORT_RIGHT_GROUPS_FILTER_SET = 'REPORT_RIGHT_GROUPS_FILTER_SET';
export const REPORT_GRADES_FILTER_SET = 'REPORT_GRADES_FILTER_SET';
export const REPORT_PRODUCTION_TYPE_FILTER_SET = 'REPORT_PRODUCTION_TYPE_FILTER_SET';

export const REPORT_APPLIED_ASSETS_FILTER_SET = 'REPORT_APPLIED_ASSETS_FILTER_SET';
export const REPORT_APPLIED_PARTNERS_FILTER_SET = 'REPORT_APPLIED_PARTNERS_FILTER_SET';
export const REPORT_APPLIED_AGREEMENTS_FILTER_SET = 'REPORT_APPLIED_AGREEMENTS_FILTER_SET';
export const REPORT_APPLIED_RIGHTS_FILTER_SET = 'REPORT_APPLIED_RIGHTS_FILTER_SET';
export const REPORT_APPLIED_PRIORITY_FILTER_SET = 'REPORT_APPLIED_PRIORITY_FILTER_SET';
export const REPORT_APPLIED_QC_STATUS_FILTER_SET = 'REPORT_APPLIED_QC_STATUS_FILTER_SET';
export const REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET = 'REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET';
export const REPORT_APPLIED_TENURE_FILTER_SET = 'REPORT_APPLIED_TENURE_FILTER_SET';
export const REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET = 'REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET';
export const REPORT_APPLIED_GRADES_FILTER_SET = 'REPORT_APPLIED_GRADES_FILTER_SET';
export const REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET = 'REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET';

export const FETCH_REPORT_AGREEMENTS_SUCCESS = 'FETCH_REPORT_AGREEMENTS_SUCCESS';
export const FETCH_REPORT_AGREEMENTS_IN_PROGRESS = 'FETCH_REPORT_AGREEMENTS_IN_PROGRESS';
export const FETCH_REPORT_AGREEMENTS_ERROR = 'FETCH_REPORT_AGREEMENTS_ERROR';

export const FETCH_REPORT_PARTNERS_SUCCESS = 'FETCH_REPORT_PARTNERS_SUCCESS';
export const FETCH_REPORT_PARTNERS_IN_PROGRESS = 'FETCH_REPORT_PARTNERS_IN_PROGRESS';
export const FETCH_REPORT_PARTNERS_ERROR = 'FETCH_REPORT_PARTNERS_ERROR';

export const FETCH_REPORT_PLATFORMS_SUCCESS = 'FETCH_REPORT_PLATFORMS_SUCCESS';
export const FETCH_REPORT_PLATFORMS_IN_PROGRESS = 'FETCH_REPORT_PLATFORMS_IN_PROGRESS';
export const FETCH_REPORT_PLATFORMS_ERROR = 'FETCH_REPORT_PLATFORMS_ERROR';

export const FETCH_REPORT_PROJECTS_SUCCESS = 'FETCH_REPORT_PROJECTS_SUCCESS';
export const FETCH_REPORT_PROJECTS_IN_PROGRESS = 'FETCH_REPORT_PROJECTS_IN_PROGRESS';
export const FETCH_REPORT_PROJECTS_ERROR = 'FETCH_REPORT_PROJECTS_ERROR';

export const FETCH_REPORT_ASSETS_SUCCESS = 'FETCH_REPORT_ASSETS_SUCCESS';
export const FETCH_REPORT_ASSETS_IN_PROGRESS = 'FETCH_REPORT_ASSETS_IN_PROGRESS';
export const FETCH_REPORT_ASSETS_ERROR = 'FETCH_REPORT_ASSETS_ERROR';

export const FETCH_REPORT_RIGHTS_SUCCESS = 'FETCH_REPORT_RIGHTS_SUCCESS';
export const FETCH_REPORT_RIGHTS_IN_PROGRESS = 'FETCH_REPORT_RIGHTS_IN_PROGRESS';
export const FETCH_REPORT_RIGHTS_ERROR = 'FETCH_REPORT_RIGHTS_ERROR';

export const DOWNLOAD_REPORT_FILE_SUCCESS = 'DOWNLOAD_REPORT_FILE_SUCCESS';
export const DOWNLOAD_REPORT_FILE_IN_PROGRESS = 'DOWNLOAD_REPORT_FILE_IN_PROGRESS';
export const DOWNLOAD_REPORT_FILE_ERROR = 'DOWNLOAD_REPORT_FILE_ERROR';
