export const FETCH_ALL_ASSETS_STATS_IN_PROGRESS = 'FETCH_ALL_ASSETS_STATS_IN_PROGRESS';
export const FETCH_ALL_ASSETS_STATS_SUCCESS = 'FETCH_ALL_ASSETS_STATS_SUCCESS';
export const FETCH_ALL_ASSETS_STATS_ERROR = 'FETCH_ALL_ASSETS_STATS_ERROR';

export const FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS = 'FETCH_ALL_AGREEMENTS_STATS_IN_PROGRESS';
export const FETCH_ALL_AGREEMENTS_STATS_SUCCESS = 'FETCH_ALL_AGREEMENTS_STATS_SUCCESS';
export const FETCH_ALL_AGREEMENTS_STATS_ERROR = 'FETCH_ALL_AGREEMENTS_STATS_ERROR';

export const FETCH_ALL_PARTNERS_STATS_IN_PROGRESS = 'FETCH_ALL_PARTNERS_STATS_IN_PROGRESS';
export const FETCH_ALL_PARTNERS_STATS_SUCCESS = 'FETCH_ALL_PARTNERS_STATS_SUCCESS';
export const FETCH_ALL_PARTNERS_STATS_ERROR = 'FETCH_ALL_PARTNERS_STATS_ERROR';

export const FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS = 'FETCH_ALL_PLATFORMS_STATS_IN_PROGRESS';
export const FETCH_ALL_PLATFORMS_STATS_SUCCESS = 'FETCH_ALL_PLATFORMS_STATS_SUCCESS';
export const FETCH_ALL_PLATFORMS_STATS_ERROR = 'FETCH_ALL_PLATFORMS_STATS_ERROR';

export const FETCH_ALL_RIGHTS_STATS_IN_PROGRESS = 'FETCH_ALL_RIGHTS_STATS_IN_PROGRESS';
export const FETCH_ALL_RIGHTS_STATS_SUCCESS = 'FETCH_ALL_RIGHTS_STATS_SUCCESS';
export const FETCH_ALL_RIGHTS_STATS_ERROR = 'FETCH_ALL_RIGHTS_STATS_ERROR';

export const FETCH_ALL_PROJECTS_STATS_IN_PROGRESS = 'FETCH_ALL_PROJECTS_STATS_IN_PROGRESS';
export const FETCH_ALL_PROJECTS_STATS_SUCCESS = 'FETCH_ALL_PROJECTS_STATS_SUCCESS';
export const FETCH_ALL_PROJECTS_STATS_ERROR = 'FETCH_ALL_PROJECTS_STATS_ERROR';
