import { combineReducers } from 'redux';
import {
    DOWNLOAD_REPORT_FILE_ERROR,
    DOWNLOAD_REPORT_FILE_IN_PROGRESS,
    DOWNLOAD_REPORT_FILE_SUCCESS,
    FETCH_REPORT_AGREEMENTS_ERROR,
    FETCH_REPORT_AGREEMENTS_IN_PROGRESS,
    FETCH_REPORT_AGREEMENTS_SUCCESS,
    FETCH_REPORT_ASSETS_ERROR,
    FETCH_REPORT_ASSETS_IN_PROGRESS,
    FETCH_REPORT_ASSETS_SUCCESS,
    FETCH_REPORT_PARTNERS_ERROR,
    FETCH_REPORT_PARTNERS_IN_PROGRESS,
    FETCH_REPORT_PARTNERS_SUCCESS,
    FETCH_REPORT_PLATFORMS_ERROR,
    FETCH_REPORT_PLATFORMS_IN_PROGRESS,
    FETCH_REPORT_PLATFORMS_SUCCESS,
    FETCH_REPORT_PROJECTS_ERROR,
    FETCH_REPORT_PROJECTS_IN_PROGRESS,
    FETCH_REPORT_PROJECTS_SUCCESS,
    FETCH_REPORT_RIGHTS_ERROR,
    FETCH_REPORT_RIGHTS_IN_PROGRESS,
    FETCH_REPORT_RIGHTS_SUCCESS, REPORT_AGREEMENT_TYPES_FILTER_SET,
    REPORT_AGREEMENTS_FILTER_SET, REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET,
    REPORT_APPLIED_AGREEMENTS_FILTER_SET,
    REPORT_APPLIED_ASSETS_FILTER_SET, REPORT_APPLIED_GRADES_FILTER_SET,
    REPORT_APPLIED_PARTNERS_FILTER_SET,
    REPORT_APPLIED_PRIORITY_FILTER_SET, REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET,
    REPORT_APPLIED_QC_STATUS_FILTER_SET, REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET,
    REPORT_APPLIED_RIGHTS_FILTER_SET, REPORT_APPLIED_TENURE_FILTER_SET,
    REPORT_ASSETS_FILTER_SET,
    REPORT_END_DATE_FILTER_SET,
    REPORT_EXPORT_DATES_SET,
    REPORT_FILTER_VALUE_SET, REPORT_GRADES_FILTER_SET,
    REPORT_PARTNERS_FILTER_SET,
    REPORT_PLATFORMS_FILTER_SET,
    REPORT_PRIORITY_FILTER_SET, REPORT_PRODUCTION_TYPE_FILTER_SET,
    REPORT_PROJECTS_FILTER_SET,
    REPORT_QC_STATUS_FILTER_SET, REPORT_RIGHT_GROUPS_FILTER_SET,
    REPORT_RIGHTS_FILTER_SET,
    REPORT_START_DATE_FILTER_SET,
    REPORT_STATUS_FILTER_SET,
    REPORT_TAB_VALUE_SET, REPORT_TENURE_FILTER_SET, REPORT_TOGGLE_FILTER_SET,
} from '../../constants/reports';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY, DEFAULT_TOTAL } from '../../config';
import filters from './filters';
import stats from './stats';
import {
    REPORT_AGREEMENTS_LIST_FETCH_SUCCESS,
    REPORT_ASSETS_LIST_FETCH_SUCCESS,
    REPORT_PARTNERS_LIST_FETCH_SUCCESS,
    REPORT_RIGHTS_LIST_FETCH_SUCCESS,
} from '../../constants/reports/filters';

const tabValue = (state = {
    value: 'assets',
}, action) => {
    switch (action.type) {
    case REPORT_TAB_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const exportDates = (state = {
    start: '',
    end: '',
}, action) => {
    switch (action.type) {
    case REPORT_EXPORT_DATES_SET:
        return {
            start: action.start,
            end: action.end,
        };
    default:
        return state;
    }
};

const filterValue = (state = {
    value: new Set(),
}, action) => {
    switch (action.type) {
    case REPORT_FILTER_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const assetsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_ASSETS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const partnersFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_PARTNERS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const agreementsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_AGREEMENTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const platformsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_PLATFORMS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const rightsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_RIGHTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const projectsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_PROJECTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const qcStatusFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_QC_STATUS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const startDateFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_START_DATE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const endDateFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_END_DATE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const priorityFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_PRIORITY_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const statusFilter = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case REPORT_STATUS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const agreementTypesFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_AGREEMENT_TYPES_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const tenureFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_TENURE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const rightGroupsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_RIGHT_GROUPS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const gradesFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_GRADES_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const productionTypeFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case REPORT_PRODUCTION_TYPE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const toggleFilter = (state = 'AND', action) => {
    switch (action.type) {
    case REPORT_TOGGLE_FILTER_SET:
        return action.value;
    default:
        return state;
    }
};

const reportAgreements = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_AGREEMENTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_AGREEMENTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case REPORT_AGREEMENTS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_REPORT_AGREEMENTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const reportPartners = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_PARTNERS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_PARTNERS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case REPORT_PARTNERS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_REPORT_PARTNERS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const reportPlatforms = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_PLATFORMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_PLATFORMS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case FETCH_REPORT_PLATFORMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const reportProjects = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_PROJECTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_PROJECTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case FETCH_REPORT_PROJECTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const reportAssets = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_ASSETS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_ASSETS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case REPORT_ASSETS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_REPORT_ASSETS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const reportRights = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_REPORT_RIGHTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_REPORT_RIGHTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case REPORT_RIGHTS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_REPORT_RIGHTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const downloadExportFiles = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DOWNLOAD_REPORT_FILE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DOWNLOAD_REPORT_FILE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case DOWNLOAD_REPORT_FILE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const reportsAppliedFilters = (state = {
    assets: {},
    agreements: {},
    partners: {},
    rights: {},
    priority: {},
    qcStatus: {},
    agreementTypes: {},
    tenure: {},
    rightGroups: {},
    grades: {},
    productionType: {},
}, action) => {
    switch (action.type) {
    case REPORT_APPLIED_ASSETS_FILTER_SET:
        return {
            ...state,
            assets: action.value,
        };
    case REPORT_APPLIED_AGREEMENTS_FILTER_SET:
        return {
            ...state,
            agreements: action.value,
        };
    case REPORT_APPLIED_PARTNERS_FILTER_SET:
        return {
            ...state,
            partners: action.value,
        };
    case REPORT_APPLIED_RIGHTS_FILTER_SET:
        return {
            ...state,
            rights: action.value,
        };
    case REPORT_APPLIED_PRIORITY_FILTER_SET:
        return {
            ...state,
            priority: action.value,
        };
    case REPORT_APPLIED_QC_STATUS_FILTER_SET:
        return {
            ...state,
            qcStatus: action.value,
        };
    case REPORT_APPLIED_AGREEMENT_TYPES_FILTER_SET:
        return {
            ...state,
            agreementTypes: action.value,
        };
    case REPORT_APPLIED_TENURE_FILTER_SET:
        return {
            ...state,
            tenure: action.value,
        };
    case REPORT_APPLIED_RIGHT_GROUPS_FILTER_SET:
        return {
            ...state,
            rightGroups: action.value,
        };
    case REPORT_APPLIED_GRADES_FILTER_SET:
        return {
            ...state,
            grades: action.value,
        };
    case REPORT_APPLIED_PRODUCTION_TYPE_FILTER_SET:
        return {
            ...state,
            productionType: action.value,
        };
    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    exportDates,
    filters,
    stats,
    filterValue,
    assetsFilter,
    partnersFilter,
    agreementsFilter,
    platformsFilter,
    rightsFilter,
    projectsFilter,
    qcStatusFilter,
    priorityFilter,
    statusFilter,
    agreementTypesFilter,
    tenureFilter,
    rightGroupsFilter,
    gradesFilter,
    productionTypeFilter,
    toggleFilter,
    startDateFilter,
    endDateFilter,
    reportAgreements,
    reportPartners,
    reportPlatforms,
    reportProjects,
    reportAssets,
    reportRights,
    downloadExportFiles,
    reportsAppliedFilters,
});
