import {
    DISCONNECT_SET,
    FETCH_PROFILE_DETAILS_ERROR,
    FETCH_PROFILE_DETAILS_IN_PROGRESS,
    FETCH_PROFILE_DETAILS_SUCCESS,
    FETCH_PROFILE_IMAGE_ERROR,
    FETCH_PROFILE_IMAGE_IN_PROGRESS,
    FETCH_PROFILE_IMAGE_SUCCESS,
} from '../constants/profile';
import { PROFILE_DETAILS_FETCH_URL, PROFILE_IMAGE_FETCH_URL } from '../constants/url';
import Axios from 'axios';

export const setDisconnect = () => {
    return {
        type: DISCONNECT_SET,
    };
};

const fetchProfileDetailsInProgress = () => {
    return {
        type: FETCH_PROFILE_DETAILS_IN_PROGRESS,
    };
};

const fetchProfileDetailsSuccess = (value) => {
    return {
        type: FETCH_PROFILE_DETAILS_SUCCESS,
        value,
    };
};

const fetchProfileDetailsError = (message) => {
    return {
        type: FETCH_PROFILE_DETAILS_ERROR,
        message,
    };
};

export const fetchProfileDetails = (cb) => (dispatch) => {
    dispatch(fetchProfileDetailsInProgress());

    Axios.get(PROFILE_DETAILS_FETCH_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchProfileDetailsSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProfileDetailsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchProfileImageInProgress = () => {
    return {
        type: FETCH_PROFILE_IMAGE_IN_PROGRESS,
    };
};

const fetchProfileImageSuccess = (value) => {
    return {
        type: FETCH_PROFILE_IMAGE_SUCCESS,
        value,
    };
};

const fetchProfileImageError = (message) => {
    return {
        type: FETCH_PROFILE_IMAGE_ERROR,
        message,
    };
};

export const fetchProfileImage = () => (dispatch) => {
    dispatch(fetchProfileImageInProgress());

    Axios.get(PROFILE_IMAGE_FETCH_URL, {
        responseType: 'blob',
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            const profileImage = res.data;
            const file = new Blob([profileImage], { type: profileImage.type });
            const fileUrl = URL.createObjectURL(file);

            dispatch(fetchProfileImageSuccess(fileUrl));
        })
        .catch(async (error) => {
            const responseObj = await error.response.data.text();
            const errorMessage = JSON.parse(responseObj);
            dispatch(fetchProfileImageError(errorMessage.message));
        });
};
