import { combineReducers } from 'redux';
import {
    FETCH_DETAILS_EXPORT_ERROR,
    FETCH_DETAILS_EXPORT_IN_PROGRESS,
    FETCH_DETAILS_EXPORT_SUCCESS,
    FETCH_PROJECT_DETAILS_ERROR,
    FETCH_PROJECT_DETAILS_IN_PROGRESS,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_QUICK_VIEW_DETAILS_ERROR,
    FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS,
    FETCH_QUICK_VIEW_DETAILS_SUCCESS,
    QUICK_VIEW_HIDE,
    QUICK_VIEW_SHOW,
} from '../../constants/allTabs/quickview';
import { DISCONNECT_SET } from '../../constants/profile';

const drawer = (state = {
    open: false,
    agreementOpen: false,
}, action) => {
    switch (action.type) {
    case QUICK_VIEW_SHOW:
        return {
            ...state,
            open: true,
        };
    case QUICK_VIEW_HIDE:
        return {
            ...state,
            open: false,
        };

    default:
        return state;
    }
};

const quickViewDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_QUICK_VIEW_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_QUICK_VIEW_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_QUICK_VIEW_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const exportDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_DETAILS_EXPORT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_DETAILS_EXPORT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_DETAILS_EXPORT_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const projectDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FETCH_PROJECT_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_PROJECT_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_PROJECT_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

export default combineReducers({
    drawer,
    quickViewDetails,
    exportDetails,
    projectDetails,
});
