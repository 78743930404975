import React, { useState } from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import { withRouter } from 'react-router';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import { fetchReportAgreements } from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const AgreementsTable = (props) => {
    const [time, setTime] = useState(null);
    const handleFetch = (skip, limit, sortBy, order, searchText) => {
        const assetsObj = { ...props.assetsFilter };
        let assets = new Set();
        if (assetsObj[props.tabValue]) {
            assets = new Set(assetsObj[props.tabValue]);
        }

        const partnersObj = { ...props.partnersFilter };
        let partners = new Set();
        if (partnersObj[props.tabValue]) {
            partners = new Set(partnersObj[props.tabValue]);
        }

        const rightsObj = { ...props.rightsFilter };
        let rights = new Set();
        if (rightsObj[props.tabValue]) {
            rights = new Set(rightsObj[props.tabValue]);
        }

        const qcStatusObj = { ...props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[props.tabValue]) {
            qcStatus = new Set(qcStatusObj[props.tabValue]);
        }

        const startDateObj = { ...props.startDateFilter };
        const startDate = startDateObj[props.tabValue];

        const endDateObj = { ...props.endDateFilter };
        const endDate = endDateObj[props.tabValue];

        props.fetchReportAgreements(skip, limit, sortBy, order, searchText, assets, partners, rights, qcStatus, startDate, endDate, props.status);
    };

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (time) {
                clearInterval(time);
            }

            const interval = setTimeout(() => {
                handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, props.sortBy, props.order, searchText);
            }, 1000);

            setTime(interval);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: true,
        viewColumns: true,
        page: props.skip / 10,
    };

    const columns = [{
        name: 'agreement_id',
        label: 'Agreement ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value || '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'agreement_name',
        label: 'Agreement Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value || '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'category',
        label: 'Agreement Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'type',
        label: 'Contract Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'active_inactive_status',
        label: 'Status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'start_date',
        label: 'Start Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'end_date',
        label: 'End Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'quality_check.status',
        label: 'Quality Check',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.agreement_id ? item.agreement_id : '',
                item.agreement_name ? item.agreement_name : '',
                item.category,
                item.type,
                item.active_inactive_status,
                item.start_date
                    ? moment(item.start_date).format('DD-MMM-YYYY')
                    : null,
                item.end_date
                    ? moment(item.end_date).format('DD-MMM-YYYY')
                    : null,
                item.quality_check
                    ? item.quality_check.status === 'PENDING'
                        ? 'Pending'
                        : item.quality_check.status === 'REJECTED'
                            ? 'Rejected'
                            : item.quality_check.status === 'APPROVED'
                                ? 'Approved'
                                : item.quality_check.status === 'IN_PROGRESS'
                                    ? 'In Progress'
                                    : item.quality_check.status === 'CREATED'
                                        ? 'Created'
                                        : item.quality_check.status
                    : '']) : [];
    return (
        <>
            {props.inProgress && <LinearProgress className="linear_progress"/>}
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

AgreementsTable.propTypes = {
    assetsFilter: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    rightsFilter: PropTypes.object.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.reports.reportAgreements.result,
        inProgress: state.reports.reportAgreements.inProgress,
        skip: state.reports.reportAgreements.skip,
        limit: state.reports.reportAgreements.limit,
        sortBy: state.reports.reportAgreements.sortBy,
        total: state.reports.reportAgreements.total,
        order: state.reports.reportAgreements.order,
        searchKey: state.reports.reportAgreements.searchKey,

        tabValue: state.reports.tabValue.value,
        assetsFilter: state.reports.assetsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        rightsFilter: state.reports.rightsFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        status: state.reports.statusFilter.value,
    };
};

const actionToProps = {
    fetchReportAgreements,
};

export default withRouter(connect(stateToProps, actionToProps)(AgreementsTable));
