import React, { useState } from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import { withRouter } from 'react-router';
import { LinearProgress } from '@mui/material';
import { fetchReportPlatforms } from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const PlatformsTable = (props) => {
    const [time, setTime] = useState(null);
    const handleFetch = (skip, limit, sortBy, order, searchText) => {
        const qcStatusObj = { ...props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj.platforms) {
            qcStatus = new Set(qcStatusObj.platforms);
        }

        props.fetchReportPlatforms(skip, limit, sortBy, order, searchText, qcStatus);
    };

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (time) {
                clearInterval(time);
            }

            const interval = setTimeout(() => {
                handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, props.sortBy, props.order, searchText);
            }, 1000);

            setTime(interval);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: true,
        viewColumns: true,
        page: props.skip / 10,
    };

    const columns = [{
        name: 'name',
        label: 'Platform Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'type',
        label: 'Platform Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'quality_check_status',
        label: 'Quality Check',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.name ? item.name : '',
                item.type,
                item.quality_check_status
                    ? item.quality_check_status === 'PENDING'
                        ? 'Pending'
                        : item.quality_check_status === 'REJECTED'
                            ? 'Rejected'
                            : item.quality_check_status === 'APPROVED'
                                ? 'Approved'
                                : item.quality_check_status === 'IN_PROGRESS'
                                    ? 'In Progress'
                                    : item.quality_check_status === 'CREATED'
                                        ? 'Created'
                                        : item.quality_check_status
                    : '']) : [];

    return (
        <>
            {props.inProgress && <LinearProgress className="linear_progress"/>}
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

PlatformsTable.propTypes = {
    fetchReportPlatforms: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.reports.reportPlatforms.result,
        inProgress: state.reports.reportPlatforms.inProgress,
        skip: state.reports.reportPlatforms.skip,
        limit: state.reports.reportPlatforms.limit,
        sortBy: state.reports.reportPlatforms.sortBy,
        total: state.reports.reportPlatforms.total,
        order: state.reports.reportPlatforms.order,
        searchKey: state.reports.reportPlatforms.searchKey,

        qcStatusFilter: state.reports.qcStatusFilter.value,
    };
};

const actionToProps = {
    fetchReportPlatforms,
};

export default withRouter(connect(stateToProps, actionToProps)(PlatformsTable));
