import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';
import variables from '../../../../utils/variables';
import moment from 'moment';

const GroupInfo = (props) => {
    return (
        <div>
            <div className="section1">
                <span>{variables[props.lang].tenure}</span>
                <p>{props.data && props.data.tenure}</p>
                {props.data && props.data.tenure &&
                    <CopyToClipboard
                        text={props.data && props.data.tenure}>
                        <div className="copy_icon">
                            <Icon className="copy" icon="copy" />
                            {variables[props.lang].copy}
                        </div>
                    </CopyToClipboard>}
            </div>
            <div className="section1">
                <span>{variables[props.lang].start_date}</span>
                <p>{props.data && props.data.start_date !== ''
                    ? moment(props.data.start_date).format(
                        'DD-MMM-YYYY',
                    )
                    : ''}</p>
                {props.data && props.data.start_date !== '' &&
                    <CopyToClipboard
                        text={props.data.start_date !== ''
                            ? moment(props.data.start_date).format(
                                'DD-MMM-YYYY',
                            )
                            : ''}>
                        <div className="copy_icon">
                            <Icon className="copy" icon="copy" />
                            {variables[props.lang].copy}
                        </div>
                    </CopyToClipboard>}
            </div>
            {props.data && props.data.end_date !== '' &&
                <div className="section1">
                    <span>{variables[props.lang].end_date}</span>
                    <p>{props.data && props.data.end_date !== ''
                        ? moment(props.data.end_date).format(
                            'DD-MMM-YYYY',
                        )
                        : ''}</p>
                    {props.data && props.data.end_date !== '' &&
                    <CopyToClipboard
                        text={props.data.end_date !== ''
                            ? moment(props.data.end_date).format(
                                'DD-MMM-YYYY',
                            )
                            : ''}>
                        <div className="copy_icon">
                            <Icon className="copy" icon="copy" />
                            {variables[props.lang].copy}
                        </div>
                    </CopyToClipboard>}
                </div>}

        </div>
    );
};

GroupInfo.propTypes = {
    data: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(GroupInfo);
