import { Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportRights,
    setToggleFilters,
} from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';

class TogglePopover extends Component {
    constructor (props) {
        super(props);

        this.handleFilters = this.handleFilters.bind(this);
        this.handleApply = this.handleApply.bind(this);
    }

    handleFilters (event, value) {
        this.props.setToggleFilters(value);
        this.handleApply(value);
    }

    handleApply (value) {
        const assetsObj = { ...this.props.appliedAssets };
        let assets = new Set();
        if (assetsObj[this.props.tabValue]) {
            assets = new Set(assetsObj[this.props.tabValue]);
        }

        const agreementsObj = { ...this.props.appliedAgreements };
        let agreements = new Set();
        if (agreementsObj[this.props.tabValue]) {
            agreements = new Set(agreementsObj[this.props.tabValue]);
        }

        const rightsObj = { ...this.props.appliedRights };
        let rights = new Set();
        if (rightsObj[this.props.tabValue]) {
            rights = new Set(rightsObj[this.props.tabValue]);
        }

        const partnersObj = { ...this.props.appliedPartners };
        let partners = new Set();
        if (partnersObj[this.props.tabValue]) {
            partners = new Set(partnersObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        const typesObj = { ...this.props.appliedAgreementTypes };
        let types = new Set();
        if (typesObj[this.props.tabValue]) {
            types = new Set(typesObj[this.props.tabValue]);
        }

        const rightGroupsObj = { ...this.props.appliedRightGroups };
        let rightGroups = new Set();
        if (rightGroupsObj[this.props.tabValue]) {
            rightGroups = new Set(rightGroupsObj[this.props.tabValue]);
        }

        const tenureObj = { ...this.props.appliedTenure };
        let tenure = new Set();
        if (tenureObj[this.props.tabValue]) {
            tenure = new Set(tenureObj[this.props.tabValue]);
        }

        const productionTypeObj = { ...this.props.appliedProductionType };
        let productionType = new Set();
        if (productionTypeObj[this.props.tabValue]) {
            productionType = new Set(productionTypeObj[this.props.tabValue]);
        }

        const gradesObj = { ...this.props.appliedGrades };
        let grades = new Set();
        if (gradesObj[this.props.tabValue]) {
            grades = new Set(gradesObj[this.props.tabValue]);
        }

        const toggleValue = value;

        const startDateObj = { ...this.props.startDateFilter };
        const startDate = startDateObj[this.props.tabValue];

        const endDateObj = { ...this.props.endDateFilter };
        const endDate = endDateObj[this.props.tabValue];

        if (this.props.tabValue === 'agreements') {
            this.props.fetchReportAgreements(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.agreementsSortBy,
                this.props.agreementsOrder, this.props.agreementsSearchKey, assets, partners, rights, qcStatus, startDate,
                endDate, this.props.statusFilter, toggleValue, types, tenure, rightGroups);
        }
        if (this.props.tabValue === 'assets') {
            this.props.fetchReportAssets(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.assetsSortBy,
                this.props.assetsOrder, this.props.assetsSearchKey, agreements, partners, rights,
                qcStatus, toggleValue, grades, productionType);
        }
        if (this.props.tabValue === 'partners') {
            this.props.fetchReportPartners(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.partnersSortBy,
                this.props.partnersOrder, this.props.partnersSearchKey, assets, agreements, rights, qcStatus, toggleValue);
        }
        if (this.props.tabValue === 'rights') {
            this.props.fetchReportRights(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.rightsSortBy,
                this.props.rightsOrder, this.props.rightsSearchKey, assets, agreements, partners, qcStatus, toggleValue);
        }

        this.props.hidePopover();
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover status_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.props.hidePopover}>
                <div className="menu_list">
                    <div className="by_status">
                        <h2>{variables[this.props.lang]['by_toggle']}</h2>
                        <FormControl className="group">
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    control={<Radio checked={this.props.value === 'AND'}/>}
                                    label={<p>
                                        AND
                                    </p>}
                                    onChange={(event) => this.handleFilters(event, 'AND')}/>
                                <FormControlLabel
                                    control={<Radio
                                        checked={this.props.value === 'OR'}
                                        // checked={this.props.value && this.props.value === 'OR'}
                                    />}
                                    label={<p>
                                        OR
                                    </p>}
                                    onChange={(event) => this.handleFilters(event, 'OR')}/>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </Popover>
        );
    }
}

TogglePopover.propTypes = {
    appliedAgreementTypes: PropTypes.object.isRequired,
    appliedAgreements: PropTypes.object.isRequired,
    appliedAssets: PropTypes.object.isRequired,
    appliedGrades: PropTypes.object.isRequired,
    appliedPartners: PropTypes.object.isRequired,
    appliedProductionType: PropTypes.object.isRequired,
    appliedQcStatus: PropTypes.object.isRequired,
    appliedRightGroups: PropTypes.object.isRequired,
    appliedRights: PropTypes.object.isRequired,
    appliedTenure: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    endDateFilter: PropTypes.object.isRequired,
    fetchReportAgreements: PropTypes.func.isRequired,
    fetchReportAssets: PropTypes.func.isRequired,
    fetchReportPartners: PropTypes.func.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    partners: PropTypes.object.isRequired,
    qcStatus: PropTypes.object.isRequired,
    rights: PropTypes.object.isRequired,
    setToggleFilters: PropTypes.func.isRequired,
    startDateFilter: PropTypes.object.isRequired,
    statsAgreements: PropTypes.object.isRequired,
    statusFilter: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,

    agreementsOrder: PropTypes.string,
    agreementsSearchKey: PropTypes.string,
    agreementsSortBy: PropTypes.string,
    agreementsTotal: PropTypes.number,

    anchorEl: PropTypes.any,

    assetsOrder: PropTypes.string,
    assetsSearchKey: PropTypes.string,
    assetsSortBy: PropTypes.string,
    assetsTotal: PropTypes.number,

    partnersOrder: PropTypes.string,
    partnersSearchKey: PropTypes.string,
    partnersSortBy: PropTypes.string,
    partnersTotal: PropTypes.number,

    rightsOrder: PropTypes.string,
    rightsSearchKey: PropTypes.string,
    rightsSortBy: PropTypes.string,
    rightsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.toggleFilter,
        tabValue: state.reports.tabValue.value,

        qcStatus: state.reports.qcStatusFilter.value,
        assets: state.reports.assetsFilter.value,
        partners: state.reports.partnersFilter.value,
        rights: state.reports.rightsFilter.value,
        startDateFilter: state.reports.startDateFilter.value,
        endDateFilter: state.reports.endDateFilter.value,
        statusFilter: state.reports.statusFilter.value,

        agreementsOrder: state.reports.reportAgreements.order,
        agreementsSearchKey: state.reports.reportAgreements.searchKey,
        agreementsSortBy: state.reports.reportAgreements.sortBy,
        agreementsTotal: state.reports.reportAgreements.total,

        assetsOrder: state.reports.reportAssets.order,
        assetsSearchKey: state.reports.reportAssets.searchKey,
        assetsSortBy: state.reports.reportAssets.sortBy,
        assetsTotal: state.reports.reportAssets.total,

        partnersOrder: state.reports.reportPartners.order,
        partnersSearchKey: state.reports.reportPartners.searchKey,
        partnersSortBy: state.reports.reportPartners.sortBy,
        partnersTotal: state.reports.reportPartners.total,

        rightsOrder: state.reports.reportRights.order,
        rightsSearchKey: state.reports.reportRights.searchKey,
        rightsSortBy: state.reports.reportRights.sortBy,
        rightsTotal: state.reports.reportRights.total,

        statsAgreements: state.reports.stats.agreementsReportStats.value,

        appliedAgreements: state.reports.reportsAppliedFilters.agreements,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
        appliedAssets: state.reports.reportsAppliedFilters.assets,
        appliedRights: state.reports.reportsAppliedFilters.rights,
        appliedPartners: state.reports.reportsAppliedFilters.partners,
        appliedAgreementTypes: state.reports.reportsAppliedFilters.agreementTypes,
        appliedTenure: state.reports.reportsAppliedFilters.tenure,
        appliedRightGroups: state.reports.reportsAppliedFilters.rightGroups,
        appliedGrades: state.reports.reportsAppliedFilters.grades,
        appliedProductionType: state.reports.reportsAppliedFilters.productionType,
    };
};

const actionToProps = {
    setToggleFilters,
    fetchReportAgreements,
    fetchReportAssets,
    fetchReportPartners,
    fetchReportRights,
};

export default connect(stateToProps, actionToProps)(TogglePopover);
