import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import NoData from '../../../../components/NoData';
import moment from 'moment/moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

const Agreements = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors] = useState([
        '#EE7D2B', '#127681', '#E24759', '#3246FF',
        '#FF0000', '#9500AD', '#FF5C00', '#4343A4',
    ]);
    // eslint-disable-next-line no-unused-vars
    const [seletedColors, setseletedColors] = useState({});

    const agreementsData = props.assetDetails && props.assetDetails.agreements;
    const fields = [
        variables[props.lang].agreement_name,
        variables[props.lang].agreement_category,
        variables[props.lang].agreement_type,
        variables[props.lang].tenure,
        variables[props.lang].agreement_date,
        variables[props.lang].start_date,
        variables[props.lang].end_date,
        variables[props.lang].assets,
        variables[props.lang].assignors,
        variables[props.lang].assignees,
        variables[props.lang].additional_info,
        variables[props.lang].rights_group,
        variables[props.lang].tags,
    ];

    return (
        <div className="assets_data">
            {agreementsData && agreementsData.length > 0
                ? agreementsData.map((item, index) => {
                    const data = [
                        [item.agreement_name ? item.agreement_name : ''],
                        item.category,
                        item.type,
                        item.tenure,
                        item.date
                            ? moment(item.date).format('DD-MMM-YYYY')
                            : null,
                        item.start_date
                            ? moment(item.start_date).format(
                                'DD-MMM-YYYY',
                            )
                            : null,
                        item.end_date
                            ? moment(item.end_date).format(
                                'DD-MMM-YYYY',
                            )
                            : null,
                        item &&
                        item.assets &&
                        item.assets.length > 0
                            ? item.assets
                                .map((item) => item.name)
                                .toString()
                                .replace(/,/g, ', ')
                            : [],
                        item &&
                        item.vendors &&
                        item.vendors.length > 0
                            ? item.vendors
                                .map((item) => item.official_name)
                                .toString()
                                .replace(/,/g, ', ')
                            : [],
                        item &&
                        item.assignees &&
                        item.assignees.length > 0
                            ? item.assignees
                                .map((item) => item.official_name)
                                .toString()
                                .replace(/,/g, ', ')
                            : [],
                        item.description ? item.description : '',
                        <div key={0} className="agreement_groups">
                            {
                                item &&
                                    item.right_groups &&
                                    item.right_groups.length > 0
                                    ? item.right_groups
                                        .map((item, index) =>
                                            <div
                                                key={index} className={
                                                    item.name === 'Linear' ? 'linear_group common_group'
                                                        : item.name === 'Non-Linear' ? 'non_linear_group common_group'
                                                            : item.name === 'Physical' ? 'physical_group common_group'
                                                                : item.name === 'Ancillary' ? 'ancillary_group common_group' : 'common_group'}>
                                                {item.name}
                                            </div>)
                                    : []
                            }
                        </div>,
                        item &&
                        item.tags &&
                        item.tags.length > 0
                            ? item.tags
                                .map((chip, chipIndex) => {
                                    let randomColor = colors[Math.floor(
                                        Math.random() * colors.length)];

                                    if (seletedColors && Object.keys(seletedColors).length &&
                                            seletedColors[chip + chip._id]) {
                                        randomColor = seletedColors[chip + chip._id];
                                    }

                                    return (
                                        <div
                                            key={chipIndex}
                                            className={item.tags.length > 3 ? 'chip_back_qv' : 'chip_back_qv chip_back_length'}
                                            style={{ background: randomColor }}
                                            title={chip.name}>
                                            {chip.name}
                                        </div>
                                    );
                                })
                            : [],
                    ];

                    return (
                        <div key={index} className="info_section">
                            <Accordion className="accordion">
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    className="accordion_summary"
                                    expandIcon={<ExpandMoreIcon />}
                                    id="panel1a-header">
                                    <h2>{item.agreement_id}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {data && data.map((item1, index1) => {
                                        return (
                                            <div key={index1} className="section1">
                                                <span>{fields[index1]}</span>
                                                <p>{item1}</p>
                                                {item1 &&
                                                    <CopyToClipboard
                                                        text={item1}>
                                                        <div className="copy_icon">
                                                            <Icon className="copy" icon="copy" />
                                                            {variables[props.lang].copy}
                                                        </div>
                                                    </CopyToClipboard>}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })
                : <NoData />}
        </div>
    );
};

Agreements.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(Agreements);
