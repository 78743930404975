import {
    URL_REPORT_AGREEMENTS,
    URL_REPORT_ASSETS,
    URL_REPORT_GRADES,
    URL_REPORT_PARTNERS,
    URL_REPORT_RIGHTS,
} from '../../constants/url';
import Axios from 'axios';
import {
    REPORT_AGREEMENTS_LIST_FETCH_ERROR,
    REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS,
    REPORT_AGREEMENTS_LIST_FETCH_SUCCESS,
    REPORT_ASSETS_LIST_FETCH_ERROR,
    REPORT_ASSETS_LIST_FETCH_IN_PROGRESS,
    REPORT_ASSETS_LIST_FETCH_SUCCESS,
    REPORT_GRADES_LIST_FETCH_ERROR,
    REPORT_GRADES_LIST_FETCH_IN_PROGRESS,
    REPORT_GRADES_LIST_FETCH_SUCCESS,
    REPORT_PARTNERS_LIST_FETCH_ERROR,
    REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS,
    REPORT_PARTNERS_LIST_FETCH_SUCCESS,
    REPORT_RIGHTS_LIST_FETCH_ERROR,
    REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS,
    REPORT_RIGHTS_LIST_FETCH_SUCCESS,
} from '../../constants/reports/filters';

const fetchReportGradesInProgress = () => {
    return {
        type: REPORT_GRADES_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReportGradesSuccess = (value) => {
    return {
        type: REPORT_GRADES_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchReportGradesError = (message) => {
    return {
        type: REPORT_GRADES_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReportGrades = () => (dispatch) => {
    dispatch(fetchReportGradesInProgress());

    Axios.get(URL_REPORT_GRADES, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportGradesSuccess(res && res.data && res.data.result,
                res && res.data && res.data.count));
        })
        .catch((error) => {
            dispatch(fetchReportGradesError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchReportAssetsListInProgress = () => {
    return {
        type: REPORT_ASSETS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReportAssetsListSuccess = (value, skip, limit, total, searchKey) => {
    return {
        type: REPORT_ASSETS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        searchKey,
    };
};

const fetchReportAssetsListError = (message) => {
    return {
        type: REPORT_ASSETS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReportAssetsList = (skip, limit, searchKey, cb) => (dispatch) => {
    dispatch(fetchReportAssetsListInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }

    Axios.post(URL_REPORT_ASSETS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportAssetsListSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, searchKey));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchReportAssetsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchReportPartnersListInProgress = () => {
    return {
        type: REPORT_PARTNERS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReportPartnersListSuccess = (value, skip, limit, total, searchKey) => {
    return {
        type: REPORT_PARTNERS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        searchKey,
    };
};

const fetchReportPartnersListError = (message) => {
    return {
        type: REPORT_PARTNERS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReportPartnersList = (skip, limit, searchKey, cb) => (dispatch) => {
    dispatch(fetchReportPartnersListInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }

    Axios.post(URL_REPORT_PARTNERS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportPartnersListSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, searchKey));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchReportPartnersListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchReportRightsListInProgress = () => {
    return {
        type: REPORT_RIGHTS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReportRightsListSuccess = (value, skip, limit, total, searchKey) => {
    return {
        type: REPORT_RIGHTS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        searchKey,
    };
};

const fetchReportRightsListError = (message) => {
    return {
        type: REPORT_RIGHTS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReportRightsList = (skip, limit, searchKey, cb) => (dispatch) => {
    dispatch(fetchReportRightsListInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }

    Axios.post(URL_REPORT_RIGHTS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportRightsListSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, searchKey));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchReportRightsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchReportAgreementsListInProgress = () => {
    return {
        type: REPORT_AGREEMENTS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchReportAgreementsListSuccess = (value, skip, limit, total, searchKey) => {
    return {
        type: REPORT_AGREEMENTS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        total,
        searchKey,
    };
};

const fetchReportAgreementsListError = (message) => {
    return {
        type: REPORT_AGREEMENTS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchReportAgreementsList = (skip, limit, searchKey, cb) => (dispatch) => {
    dispatch(fetchReportAgreementsListInProgress());

    const data = {};
    if (skip) {
        data.skip = skip;
    }
    if (limit) {
        data.limit = limit;
    }
    if (searchKey && searchKey.length) {
        data.search = searchKey;
    }

    Axios.post(URL_REPORT_AGREEMENTS, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_DAMReports'),
        },
    })
        .then((res) => {
            dispatch(fetchReportAgreementsListSuccess(res && res.data && res.data.result,
                skip, limit, res && res.data && res.data.count, searchKey));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchReportAgreementsListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};
