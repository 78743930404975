import { combineReducers } from 'redux';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY, DEFAULT_TOTAL } from '../../config';
import filters from './filters';
import stats from './stats';
import {
    ALL_AGREEMENTS_FILTER_SET,
    ALL_ASSETS_FILTER_SET,
    ALL_END_DATE_FILTER_SET,
    ALL_EXPORT_DATES_SET,
    ALL_FILTER_VALUE_SET,
    ALL_PARTNERS_FILTER_SET,
    ALL_PLATFORMS_FILTER_SET,
    ALL_PRIORITY_FILTER_SET,
    ALL_PROJECTS_FILTER_SET,
    ALL_QC_STATUS_FILTER_SET,
    ALL_RIGHTS_FILTER_SET,
    ALL_START_DATE_FILTER_SET,
    ALL_TAB_VALUE_SET,
    DOWNLOAD_ALL_FILES_ERROR,
    DOWNLOAD_ALL_FILES_IN_PROGRESS,
    DOWNLOAD_ALL_FILES_SUCCESS,
    FETCH_ALL_AGREEMENTS_ERROR,
    FETCH_ALL_AGREEMENTS_IN_PROGRESS,
    FETCH_ALL_AGREEMENTS_SUCCESS,
    FETCH_ALL_ASSETS_ERROR,
    FETCH_ALL_ASSETS_IN_PROGRESS,
    FETCH_ALL_ASSETS_SUCCESS,
    FETCH_ALL_PARTNERS_ERROR,
    FETCH_ALL_PARTNERS_IN_PROGRESS,
    FETCH_ALL_PARTNERS_SUCCESS,
    FETCH_ALL_PLATFORMS_ERROR,
    FETCH_ALL_PLATFORMS_IN_PROGRESS,
    FETCH_ALL_PLATFORMS_SUCCESS,
    FETCH_ALL_PROJECTS_ERROR,
    FETCH_ALL_PROJECTS_IN_PROGRESS,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_RIGHTS_ERROR,
    FETCH_ALL_RIGHTS_IN_PROGRESS,
    FETCH_ALL_RIGHTS_SUCCESS,
} from '../../constants/allTabs';
import {
    ALL_AGREEMENTS_LIST_FETCH_SUCCESS,
    ALL_ASSETS_LIST_FETCH_SUCCESS,
    ALL_PARTNERS_LIST_FETCH_SUCCESS,
    ALL_RIGHTS_LIST_FETCH_SUCCESS,
} from '../../constants/allTabs/filters';
import quickview from './quickview';
import files from './files';

const tabValue = (state = {
    value: 'assets',
}, action) => {
    switch (action.type) {
    case ALL_TAB_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const exportDates = (state = {
    start: '',
    end: '',
}, action) => {
    switch (action.type) {
    case ALL_EXPORT_DATES_SET:
        return {
            start: action.start,
            end: action.end,
        };
    default:
        return state;
    }
};

const filterValue = (state = {
    value: new Set(),
}, action) => {
    switch (action.type) {
    case ALL_FILTER_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const assetsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_ASSETS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const partnersFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_PARTNERS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const agreementsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_AGREEMENTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const platformsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_PLATFORMS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const rightsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_RIGHTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const projectsFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_PROJECTS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const qcStatusFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_QC_STATUS_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const startDateFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_START_DATE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const endDateFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_END_DATE_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const priorityFilter = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ALL_PRIORITY_FILTER_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const allAgreements = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_AGREEMENTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_AGREEMENTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case ALL_AGREEMENTS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_ALL_AGREEMENTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allPartners = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PARTNERS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PARTNERS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case ALL_PARTNERS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_ALL_PARTNERS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allPlatforms = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PLATFORMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PLATFORMS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case FETCH_ALL_PLATFORMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allProjects = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_PROJECTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_PROJECTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case FETCH_ALL_PROJECTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allAssets = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_ASSETS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_ASSETS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case ALL_ASSETS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_ALL_ASSETS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const allRights = (state = {
    inProgress: false,
    result: [],
    skip: 0,
    limit: 10,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    searchKey: '',
}, action) => {
    switch (action.type) {
    case FETCH_ALL_RIGHTS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_ALL_RIGHTS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            searchKey: action.searchKey,
        };
    }
    case ALL_RIGHTS_LIST_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
                searchKey: action.searchKey,
            };
        } else {
            return {
                ...state,
            };
        }
    }
    case FETCH_ALL_RIGHTS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const downloadExportFiles = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DOWNLOAD_ALL_FILES_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DOWNLOAD_ALL_FILES_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case DOWNLOAD_ALL_FILES_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    exportDates,
    filters,
    stats,
    quickview,
    files,
    filterValue,
    assetsFilter,
    partnersFilter,
    agreementsFilter,
    platformsFilter,
    rightsFilter,
    projectsFilter,
    qcStatusFilter,
    priorityFilter,
    startDateFilter,
    endDateFilter,
    allAgreements,
    allPartners,
    allPlatforms,
    allProjects,
    allAssets,
    allRights,
    downloadExportFiles,
});
