import React, { useState } from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import { withRouter } from 'react-router';
import { LinearProgress } from '@mui/material';
import { fetchReportRights } from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const RightsTable = (props) => {
    const [time, setTime] = useState(null);
    const handleFetch = (skip, limit, sortBy, order, searchText) => {
        const assetsObj = { ...props.assetsFilter };
        let assets = new Set();
        if (assetsObj[props.tabValue]) {
            assets = new Set(assetsObj[props.tabValue]);
        }

        const agreementsObj = { ...props.agreementsFilter };
        let agreements = new Set();
        if (agreementsObj[props.tabValue]) {
            agreements = new Set(agreementsObj[props.tabValue]);
        }

        const partnersObj = { ...props.partnersFilter };
        let partners = new Set();
        if (partnersObj[props.tabValue]) {
            partners = new Set(partnersObj[props.tabValue]);
        }

        const qcStatusObj = { ...props.qcStatusFilter };
        let qcStatus = new Set();
        if (qcStatusObj[props.tabValue]) {
            qcStatus = new Set(qcStatusObj[props.tabValue]);
        }

        props.fetchReportRights(skip, limit, sortBy, order, searchText, assets, agreements, partners, qcStatus);
    };

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (time) {
                clearInterval(time);
            }

            const interval = setTimeout(() => {
                handleFetch(DEFAULT_SKIP, DEFAULT_LIMIT, props.sortBy, props.order, searchText);
            }, 1000);

            setTime(interval);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            handleFetch(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: true,
        viewColumns: true,
        page: props.skip / 10,
    };

    const columns = [{
        name: 'id',
        label: 'Right ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Right Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }, {
        name: 'quality_check_status',
        label: 'Quality Check',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">{value}</div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.id,
                item.name,
                item.quality_check_status
                    ? item.quality_check_status === 'PENDING'
                        ? 'Pending'
                        : item.quality_check_status === 'REJECTED'
                            ? 'Rejected'
                            : item.quality_check_status === 'APPROVED'
                                ? 'Approved'
                                : item.quality_check_status === 'IN_PROGRESS'
                                    ? 'In Progress'
                                    : item.quality_check_status === 'CREATED'
                                        ? 'Created'
                                        : item.quality_check_status
                    : '']) : [];

    return (
        <>
            {props.inProgress && <LinearProgress className="linear_progress"/>}
            <DataTable
                columns={columns}
                data={tableData}
                name=""
                options={options}/>
        </>
    );
};

RightsTable.propTypes = {
    agreementsFilter: PropTypes.object.isRequired,
    assetsFilter: PropTypes.object.isRequired,
    fetchReportRights: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    partnersFilter: PropTypes.object.isRequired,
    qcStatusFilter: PropTypes.object.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    tabValue: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.reports.reportRights.result,
        inProgress: state.reports.reportRights.inProgress,
        skip: state.reports.reportRights.skip,
        limit: state.reports.reportRights.limit,
        sortBy: state.reports.reportRights.sortBy,
        total: state.reports.reportRights.total,
        order: state.reports.reportRights.order,
        searchKey: state.reports.reportRights.searchKey,

        tabValue: state.reports.tabValue.value,
        assetsFilter: state.reports.assetsFilter.value,
        agreementsFilter: state.reports.agreementsFilter.value,
        partnersFilter: state.reports.partnersFilter.value,
        qcStatusFilter: state.reports.qcStatusFilter.value,
    };
};

const actionToProps = {
    fetchReportRights,
};

export default withRouter(connect(stateToProps, actionToProps)(RightsTable));
