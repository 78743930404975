import { NAV_REPORT_TAB_VALUE_SET, SEARCH_VALUE_SET, SIDE_BAR_DRAWER } from '../constants/navbar';
import { combineReducers } from 'redux';

const navTabValue = (state = {
    value: 'reports',
}, action) => {
    switch (action.type) {
    case NAV_REPORT_TAB_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const searchValue = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case SEARCH_VALUE_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const drawer = (state = true, action) => {
    if (action.type === SIDE_BAR_DRAWER) {
        return !state;
    }

    return state;
};

export default combineReducers({
    navTabValue,
    searchValue,
    drawer,
});
