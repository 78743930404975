import { Button, Popover } from '@mui/material';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import './index.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { fetchReportProjects, setAppliedPriorityFilter, setPriorityFilters } from '../../../actions/reports';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

class PriorityPopover extends Component {
    constructor (props) {
        super(props);

        this.handleFilters = this.handleFilters.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleCount = this.handleCount.bind(this);
    }

    handleFilters (event, value) {
        const obj = { ...this.props.value };
        let list = new Set();
        if (obj[this.props.tabValue]) {
            list = new Set(obj[this.props.tabValue]);
        }

        if (list.has(value)) {
            list.delete(value);
        } else {
            list.add(value);
        }

        obj[this.props.tabValue] = list;

        this.props.setPriorityFilters(obj);
    }

    handleApply () {
        const priorityObj = { ...this.props.value };
        let priority = new Set();
        if (priorityObj[this.props.tabValue]) {
            priority = new Set(priorityObj[this.props.tabValue]);
        }

        const qcStatusObj = { ...this.props.appliedQcStatus };
        let qcStatus = new Set();
        if (qcStatusObj[this.props.tabValue]) {
            qcStatus = new Set(qcStatusObj[this.props.tabValue]);
        }

        if (this.props.tabValue === 'projects') {
            const priorityAppliedObj = { ...this.props.priorityApplied };
            priorityAppliedObj[this.props.tabValue] = priority;
            this.props.setAppliedPriorityFilter(priorityAppliedObj);

            this.props.fetchReportProjects(DEFAULT_SKIP, DEFAULT_LIMIT, this.props.projectsSortBy,
                this.props.projectsOrder, this.props.projectsSearchKey, qcStatus, priority);
        }

        this.props.hidePopover();
    }

    handleCount (value) {
        let data = {};

        switch (this.props.tabValue) {
        case 'projects':
            data = this.props.statsProjects;
            break;
        default:
            break;
        }

        switch (value) {
        case 'high':
            return data && data.priority_high && data.priority_high ? `(${data.priority_high})` : '(0)';
        case 'medium':
            return data && data.priority_medium && data.priority_medium ? `(${data.priority_medium})` : '(0)';
        case 'low':
            return data && data.priority_low && data.priority_low ? `(${data.priority_low})` : '(0)';
        default:
            return null;
        }
    }

    render () {
        const open = Boolean(this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const priorityObj = { ...this.props.value };
        let priority = new Set();
        if (priorityObj[this.props.tabValue]) {
            priority = new Set(priorityObj[this.props.tabValue]);
        }

        return (
            <Popover
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                className="filter_popover"
                id={id}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={this.props.hidePopover}>
                <div className="menu_list">
                    <div className="by_status">
                        <h2>{variables[this.props.lang]['by_priority']}</h2>
                        <FormGroup className="group">
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('High')}/>}
                                label={<p>
                                    High
                                    &nbsp;{this.handleCount('high')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'High')}/>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('Medium')}/>}
                                label={<p>
                                    Medium
                                    &nbsp;{this.handleCount('medium')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'Medium')}/>
                            <FormControlLabel
                                control={<Checkbox checked={this.props.value && this.props.value[this.props.tabValue] &&
                                    this.props.value[this.props.tabValue].has('Low')}/>}
                                label={<p>
                                    Low
                                    &nbsp;{this.handleCount('low')}
                                </p>}
                                onChange={(event) => this.handleFilters(event, 'Low')}/>
                        </FormGroup>
                    </div>
                </div>
                <div className="apply_filter">
                    <Button onClick={this.handleApply}>
                        {variables[this.props.lang]['apply_filter']}
                        {priority && priority.size && priority.size > 0
                            ? ' (' + priority.size + ')'
                            : null}
                    </Button>
                </div>
            </Popover>
        );
    }
}

PriorityPopover.propTypes = {
    appliedQcStatus: PropTypes.object.isRequired,
    fetchReportProjects: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    priorityApplied: PropTypes.object.isRequired,
    qcStatus: PropTypes.object.isRequired,
    setAppliedPriorityFilter: PropTypes.func.isRequired,
    setPriorityFilters: PropTypes.func.isRequired,
    statsProjects: PropTypes.object.isRequired,
    tabValue: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
    anchorEl: PropTypes.any,

    projectsOrder: PropTypes.string,
    projectsSearchKey: PropTypes.string,
    projectsSortBy: PropTypes.string,
    projectsTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.reports.priorityFilter.value,
        tabValue: state.reports.tabValue.value,
        qcStatus: state.reports.qcStatusFilter.value,

        projectsOrder: state.reports.reportProjects.order,
        projectsSearchKey: state.reports.reportProjects.searchKey,
        projectsSortBy: state.reports.reportProjects.sortBy,
        projectsTotal: state.reports.reportProjects.total,

        statsProjects: state.reports.stats.projectsReportStats.value,
        priorityApplied: state.reports.reportsAppliedFilters.priority,
        appliedQcStatus: state.reports.reportsAppliedFilters.qcStatus,
    };
};

const actionToProps = {
    setPriorityFilters,
    fetchReportProjects,
    setAppliedPriorityFilter,
};

export default connect(stateToProps, actionToProps)(PriorityPopover);
