import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClassName from 'classnames';
import NavBar from './containers/NavBar';
import LeftNav from './containers/NavBar/LeftNav';
import Reports from './containers/Reports';
import Login from './containers/Login';
import Assets from './containers/AllTabs/Assets';
import Agreements from './containers/AllTabs/Agreements';
import Partners from './containers/AllTabs/Partners';
import Platforms from './containers/AllTabs/Platforms';
import Rights from './containers/AllTabs/Rights';
// import Projects from './containers/AllTabs/Projects';

const routes = [{
    path: '/login',
    component: Login,
}, {
    path: '/reports',
    component: Reports,
}, {
    path: '/assets',
    component: Assets,
}, {
    path: '/agreements',
    component: Agreements,
}, {
    path: '/partners',
    component: Partners,
}, {
    path: '/platforms',
    component: Platforms,
}, {
    path: '/rights',
    component: Rights,
// }, {
//     path: '/projects',
//     component: Projects,
}];

const Router = () => {
    const token = localStorage.getItem('authorizationToken_DAMReports');

    return (
        <div className={ClassName('main_content reports_ui', !token ? 'main_content_with_out_token' : '')}>
            {token && <LeftNav/>}
            <div className="content_div side_bar_content scroll_bar">
                {token && <NavBar/>}
                <div
                    className="router_content scroll_bar"
                    id="scroll-bar">
                    <Switch>
                        {token && routes.map((route) =>
                            <Route
                                key={route.path}
                                exact
                                component={route.component}
                                path={route.path}/>,
                        )}
                        <Route
                            exact
                            component={Login}
                            path="*"/>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Router;
