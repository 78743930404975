export const ALL_TAB_VALUE_SET = 'ALL_TAB_VALUE_SET';
export const ALL_FILTER_VALUE_SET = 'ALL_FILTER_VALUE_SET';

export const ALL_SEARCH_FILTER_SET = 'ALL_SEARCH_FILTER_SET';
export const ALL_ASSETS_FILTER_SET = 'ALL_ASSETS_FILTER_SET';
export const ALL_PARTNERS_FILTER_SET = 'ALL_PARTNERS_FILTER_SET';
export const ALL_AGREEMENTS_FILTER_SET = 'ALL_AGREEMENTS_FILTER_SET';
export const ALL_PLATFORMS_FILTER_SET = 'ALL_PLATFORMS_FILTER_SET';
export const ALL_RIGHTS_FILTER_SET = 'ALL_RIGHTS_FILTER_SET';
export const ALL_PROJECTS_FILTER_SET = 'ALL_PROJECTS_FILTER_SET';
export const ALL_QC_STATUS_FILTER_SET = 'ALL_QC_STATUS_FILTER_SET';
export const ALL_START_DATE_FILTER_SET = 'ALL_START_DATE_FILTER_SET';
export const ALL_END_DATE_FILTER_SET = 'ALL_END_DATE_FILTER_SET';
export const ALL_PRIORITY_FILTER_SET = 'ALL_PRIORITY_FILTER_SET';
export const ALL_EXPORT_DATES_SET = 'ALL_EXPORT_DATES_SET';

export const FETCH_ALL_AGREEMENTS_SUCCESS = 'FETCH_ALL_AGREEMENTS_SUCCESS';
export const FETCH_ALL_AGREEMENTS_IN_PROGRESS = 'FETCH_ALL_AGREEMENTS_IN_PROGRESS';
export const FETCH_ALL_AGREEMENTS_ERROR = 'FETCH_ALL_AGREEMENTS_ERROR';

export const FETCH_ALL_PARTNERS_SUCCESS = 'FETCH_ALL_PARTNERS_SUCCESS';
export const FETCH_ALL_PARTNERS_IN_PROGRESS = 'FETCH_ALL_PARTNERS_IN_PROGRESS';
export const FETCH_ALL_PARTNERS_ERROR = 'FETCH_ALL_PARTNERS_ERROR';

export const FETCH_ALL_PLATFORMS_SUCCESS = 'FETCH_ALL_PLATFORMS_SUCCESS';
export const FETCH_ALL_PLATFORMS_IN_PROGRESS = 'FETCH_ALL_PLATFORMS_IN_PROGRESS';
export const FETCH_ALL_PLATFORMS_ERROR = 'FETCH_ALL_PLATFORMS_ERROR';

export const FETCH_ALL_PROJECTS_SUCCESS = 'FETCH_ALL_PROJECTS_SUCCESS';
export const FETCH_ALL_PROJECTS_IN_PROGRESS = 'FETCH_ALL_PROJECTS_IN_PROGRESS';
export const FETCH_ALL_PROJECTS_ERROR = 'FETCH_ALL_PROJECTS_ERROR';

export const FETCH_ALL_ASSETS_SUCCESS = 'FETCH_ALL_ASSETS_SUCCESS';
export const FETCH_ALL_ASSETS_IN_PROGRESS = 'FETCH_ALL_ASSETS_IN_PROGRESS';
export const FETCH_ALL_ASSETS_ERROR = 'FETCH_ALL_ASSETS_ERROR';

export const FETCH_ALL_RIGHTS_SUCCESS = 'FETCH_ALL_RIGHTS_SUCCESS';
export const FETCH_ALL_RIGHTS_IN_PROGRESS = 'FETCH_ALL_RIGHTS_IN_PROGRESS';
export const FETCH_ALL_RIGHTS_ERROR = 'FETCH_ALL_RIGHTS_ERROR';

export const DOWNLOAD_ALL_FILES_SUCCESS = 'DOWNLOAD_ALL_FILES_SUCCESS';
export const DOWNLOAD_ALL_FILES_IN_PROGRESS = 'DOWNLOAD_ALL_FILES_IN_PROGRESS';
export const DOWNLOAD_ALL_FILES_ERROR = 'DOWNLOAD_ALL_FILES_ERROR';
