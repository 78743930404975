import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideQuickView } from '../../../../actions/allTabs/quickview';
import { Drawer } from '@mui/material';
import Header from './Header';
import Tabs from './Tabs';
import CircularProgress from '../../../../components/CircularProgress';

const QuickView = (props) => {
    return (
        <div className="quickview_section">
            <Drawer
                anchor="right"
                className="drawer"
                open={props.open}
                onClose={props.onClose}>
                <div>
                    {props.inProgress
                        ? <CircularProgress />
                        : <>
                            <Header />
                            <Tabs />
                        </>}
                </div>
            </Drawer>
        </div>
    );
};

QuickView.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.allTabs.quickview.drawer.open,
        inProgress: state.allTabs.quickview.quickViewDetails.inProgress,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

const actionToProps = {
    onClose: hideQuickView,
};

export default connect(stateToProps, actionToProps)(QuickView);
