import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../../utils/variables';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../../../../components/Icon';

function numberToDay (j) {
    return ('0' + j).slice(-2);
}

const AssetsInfo = (props) => {
    const titleData = props.assetDetails && props.assetDetails.title;
    const mmmMetaData = [
        variables[props.lang].mmm_grade,
        variables[props.lang].custom_id,
        variables[props.lang].distributors,
        variables[props.lang].type,
        variables[props.lang].languages,
        variables[props.lang].video_on_demand,
        variables[props.lang].resolution,
        variables[props.lang].current_library,
        variables[props.lang].source_types,
        variables[props.lang].custom_source_types,
        variables[props.lang].sound,
        variables[props.lang].tags,
    ];

    const sound = [];
    if (titleData && titleData.sound &&
        titleData.sound.tracks_5_1) {
        sound.push({ value: '5.1 Tracks' });
    }
    if (titleData && titleData.sound &&
        titleData.sound.it_tracks) {
        sound.push({ value: 'IT Tracks' });
    }
    const mmmMetaInfo = [
        [titleData ? titleData.grade : ''],
        [titleData ? titleData.custom_id : ''],
        [titleData && titleData.distributors
            ? titleData.distributors
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.type],
        [titleData && titleData.languages
            ? titleData.languages
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.video_on_demand],
        [titleData.resolution],
        [titleData.current_or_library],
        [titleData.source_types &&
        titleData.source_types.length > 0
            ? titleData.source_types
                .map((item, i) => item)
                .toString()
                .replace(/,/g, ', ')
            : []],
        [titleData.custom_source_types &&
        titleData.custom_source_types.length > 0
            ? titleData.custom_source_types
                .map((item, i) => item)
                .toString()
                .replace(/,/g, ', ')
            : []],
        [sound && sound.length > 0
            ? sound.map((item) => item.value)
            : []],
        [titleData.tags &&
        titleData.tags.length > 0
            ? titleData.tags
                .map((item, i) => item)
                .toString()
                .replace(/,/g, ', ')
            : []],
    ];
    const featureFilmMetaData = [
        variables[props.lang].release_date,
        variables[props.lang].release_year,
        variables[props.lang].duration,
        variables[props.lang].no_reels,
        variables[props.lang].imdb_uri,
        variables[props.lang].wiki_link,
        variables[props.lang].additional_links,
        variables[props.lang].feature_film_display_name,
        variables[props.lang].banners,
        variables[props.lang].genre,
        variables[props.lang].music_label,
        variables[props.lang].appreciation,
        variables[props.lang].release_status,
        variables[props.lang].synopsis,
        variables[props.lang].highlights_movie,
        variables[props.lang].production_type,
    ];

    const originalTime = titleData.duration_before_censor
        ? titleData.duration_before_censor
        : 0;
    const originalhour = Math.floor(originalTime / 3600);
    const originalmin = Math.floor((originalTime - originalhour * 3600) / 60);
    const originalSeconds = numberToDay(
        Math.floor(originalTime - originalhour * 3600 - originalmin * 60),
    );
    const originalDuration =
        numberToDay(originalhour) +
        ': ' +
        numberToDay(originalmin) +
        ': ' +
        originalSeconds;

    const additionalLink = titleData.additional_link
        ? titleData.additional_link
        : '';
    const split = additionalLink.split('\n');
    const releaseDate =
        titleData &&
        titleData.release_date !== undefined
            ? "'" +
            titleData.release_date.month +
            '/' +
            titleData.release_date.date +
            '/' +
            titleData.release_date.year +
            "'"
            : '';
    // const OriginalReleaseDate =
    //     titleData &&
    //     titleData.original_release_date !== undefined
    //         ? "'" +
    //         titleData.original_release_date.month +
    //         '/' +
    //         titleData.original_release_date.date +
    //         '/' +
    //         titleData.original_release_date.year +
    //         "'"
    //         : '';
    const featureFilmMetaInfo = [
        [releaseDate !== '' ? moment(releaseDate).format('DD-MMM-YYYY') : ''],
        [titleData.release_year],
        [titleData.duration_before_censor ? originalDuration : ''],
        [titleData.number_of_reels ? titleData.number_of_reels : ''],
        [titleData.imdb_URL ? titleData.imdb_URL : ''],
        [titleData.wiki_link ? titleData.wiki_link : ''],
        split.length > 0
            ? split.map((item, index) => {
                return (
                    <>
                        {item}
                        <br />
                    </>
                );
            })
            : '',
        [titleData.name],
        [titleData.banners
            ? titleData.banners
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.genres
            ? titleData.genres
                .map((item, i) => item)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.music_labels
            ? titleData.music_labels
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.appreciation
            ? titleData.appreciation.split(',').map((item) => {
                return (
                    <>
                        {item}
                        <br />
                    </>
                );
            })
            : ''],
        [<span
            key={titleData}
            style={{
                color: titleData.release_status === true ? '#0FC02C' : ' #800000',
            }}>
            {' '}
            {titleData.release_status === true ? 'Released' : 'Not Released'}{' '}
        </span>],
        [titleData.story],
        [titleData.highlights],
        [titleData.production_type],
    ];
    const peopleMetaData = [
        variables[props.lang].directors,
        variables[props.lang].producers,
        variables[props.lang].lead_cast,
        variables[props.lang].other_cast,
        variables[props.lang].story_writer,
        variables[props.lang].screenplay,
        variables[props.lang].lyrics,
        variables[props.lang].dialogues,
        variables[props.lang].playback_singers,
        variables[props.lang].music_directors,
        variables[props.lang].background_score,
        variables[props.lang].sound_engineer,
    ];
    const peopleMetaInfo = [
        [titleData.directors
            ? titleData.directors
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.producers
            ? titleData.producers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.star_cast
            ? titleData.star_cast
                .map((item) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.cast
            ? titleData.cast
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.story_writers
            ? titleData.story_writers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.screenplay
            ? titleData.screenplay
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.lyrics
            ? titleData.lyrics
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.dialogues
            ? titleData.dialogues
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.playback_singers
            ? titleData.playback_singers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.music_directors
            ? titleData.music_directors
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.background_score
            ? titleData.background_score
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.sound_engineers
            ? titleData.sound_engineers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
    ];
    const supportingCrew = [
        variables[props.lang].editor,
        variables[props.lang].cinematography,
        variables[props.lang].dance_choreography,
        variables[props.lang].art_director,
        variables[props.lang].costumer,
        variables[props.lang].publicity,
        variables[props.lang].action_choreography,
        variables[props.lang].production_designer,
    ];
    const supportingCrewInfo = [
        [titleData.editors
            ? titleData.editors
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.cinematography
            ? titleData.cinematography
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.choreography
            ? titleData.choreography
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.art_directors
            ? titleData.art_directors
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.costumers
            ? titleData.costumers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.publicity
            ? titleData.publicity
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.fights
            ? titleData.fights
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
        [titleData.production_designers
            ? titleData.production_designers
                .map((item, i) => item.name)
                .toString()
                .replace(/,/g, ', ')
            : ''],
    ];

    return (
        <div className="info_section">
            <Accordion className="accordion">
                <AccordionSummary
                    aria-controls="panel1a-content"
                    className="accordion_summary"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header">
                    <h2>{variables[props.lang]['mmm_meta_data']}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {mmmMetaInfo && mmmMetaInfo.map((item, index) => {
                        return (
                            <div key={index} className="section1">
                                <span>{mmmMetaData[index]}</span>
                                <p>{item}</p>
                                {item &&
                                    <CopyToClipboard
                                        text={item}>
                                        <div className="copy_icon">
                                            <Icon className="copy" icon="copy" />
                                            {variables[props.lang].copy}
                                        </div>
                                    </CopyToClipboard>}
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
                <AccordionSummary
                    aria-controls="panel1a-content"
                    className="accordion_summary"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header">
                    <h2>{variables[props.lang]['feature_film_meta_data']}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {featureFilmMetaInfo && featureFilmMetaInfo.map((item, index) => {
                        return (
                            <div key={index} className="section1">
                                <span>{featureFilmMetaData[index]}</span>
                                <p>{item}</p>
                                {item &&
                                    <CopyToClipboard
                                        text={item}>
                                        <div className="copy_icon">
                                            <Icon className="copy" icon="copy" />
                                            {variables[props.lang].copy}
                                        </div>
                                    </CopyToClipboard>}
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
                <AccordionSummary
                    aria-controls="panel1a-content"
                    className="accordion_summary"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header">
                    <h2>{variables[props.lang]['people_meta_data']}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {peopleMetaInfo && peopleMetaInfo.map((item, index) => {
                        return (
                            <div key={index} className="section1">
                                <span>{peopleMetaData[index]}</span>
                                <p>{item}</p>
                                {item &&
                                    <CopyToClipboard
                                        text={item}>
                                        <div className="copy_icon">
                                            <Icon className="copy" icon="copy" />
                                            {variables[props.lang].copy}
                                        </div>
                                    </CopyToClipboard>}
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
                <AccordionSummary
                    aria-controls="panel1a-content"
                    className="accordion_summary"
                    expandIcon={<ExpandMoreIcon />}
                    id="panel1a-header">
                    <h2>{variables[props.lang]['supporting_crew']}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {supportingCrewInfo && supportingCrewInfo.map((item, index) => {
                        return (
                            <div key={index} className="section1">
                                <span>{supportingCrew[index]}</span>
                                <p>{item}</p>
                                {item &&
                                    <CopyToClipboard
                                        text={item}>
                                        <div className="copy_icon">
                                            <Icon className="copy" icon="copy" />
                                            {variables[props.lang].copy}
                                        </div>
                                    </CopyToClipboard>}
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

AssetsInfo.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        assetDetails: state.allTabs.quickview.quickViewDetails.value,
    };
};

export default connect(stateToProps)(AssetsInfo);
